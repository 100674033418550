/**
 * @prettier
 * @flow
 */

import { SegmentGroup } from 'semantic-ui-react';
import { Segment } from 'liana-ui/components/';
import type { Props as SegmentProps } from 'liana-ui/components/segment/Segment';

// prettier-ignore
type Props = {
	/**
		A segment group can have multiple segments.
		PROPS[SegmentProps=/components/containers/segments/segment/]
	*/
	segments?: Array<SegmentProps>,
	/** Segment groups can also be passed as children. */
	children?: React.Node,
	/** A segment group can be formatted to raise above the page. */
	raised?: boolean,
	/** A segment group can take up only as much space as is necessary for content. */
	compact?: boolean | 'very',
	/** A segment group can be different size. */
	size?: 'small' | 'large'
};

const DEFAULTS = {
	compact: false
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/segment/#groups */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	return (
		<SegmentGroup raised={props.raised} compact={props.compact} size={props.size}>
			{props.segments
				? props.segments.map((segment, index) => <Segment key={index} {...segment} />)
				: props.children
				? props.children
				: null}
		</SegmentGroup>
	);
});

// Documentation generation support
Component.displayName = 'Segment.Group';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
