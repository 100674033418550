/**
 * @prettier
 * @flow
 */

import { FormattedMessage } from 'react-intl';
import { Button, Modal, EmptyState } from 'liana-ui/components/';
import type { LimitLabelTranslations } from 'liana-ui/components/LimitLabel';

// prettier-ignore
type Props = {
	/** Controls whether or not the Modal is displayed. */
	open?: boolean,
	/** Image. */
	image?: string,
	/** Description text. */
	description?: FormattedMessage | string,
	/** Upgrade page url */
	upgradeLink?: string,
	/** Custom translations. */
	translations: LimitLabelTranslations,
	/** Called on modal close. */
	onClose?: (
		event: SyntheticEvent<>
	) => void
};

const DEFAULTS = {
	open: false,
	image: `${process.env.baseUrl}img/empty-states/upsell-default.png`,
	description: <FormattedMessage id='component.limit-label.messageContent' />,
	translations: {
		modalHeader: 'component.limit-label.modalHeader',
		messageHeader: 'component.limit-label.messageHeader',
		close: 'component.modal.close',
		upgrade: 'component.limit-modal.upgrade'
	}
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/modal/ */
const Component: React.AbstractComponent = React.memo((props: Props) => {
	let translations = Object.assign({}, DEFAULTS.translations, props.translations);

	return (
		<Modal
			open={props.open}
			size='tiny'
			header={<FormattedMessage id={translations.modalHeader} />}
			onClose={props.onClose}
			content={
				<EmptyState
					image={props.image}
					header={<FormattedMessage id={translations.messageHeader} />}
					content={props.description}
				/>
			}
			actions={[
				<Button
					key='cancel'
					text={<FormattedMessage id={translations.close} />}
					type='cancel'
					onClick={props.onClose}
				/>,
				<Button
					key='upgrade'
					text={<FormattedMessage id={translations.upgrade} />}
					color='primary'
					icon='fa-arrow-up-right-from-square'
					link={props.upgradeLink}
				/>
			]}
		/>
	);
});

// Documentation generation support
Component.displayName = 'LimitModal';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
