/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { AccordionContent } from 'semantic-ui-react';
import AccordionAccordion from './AccordionAccordion';
import { Transition } from 'liana-ui/components/';
import type { Props as AccordionPanelProps } from 'semantic-ui-react';

// prettier-ignore
type Props = {
	/** Primary content. */
	children?: React.Node,
	/** An element type to render as (string or function). */
	as: string | (() => void),
	/** Index of the currently active panel. When exclusive is false, defaultActiveIndex should be an array of Strings. */
	defaultActiveIndex?: string | Array<string>,
	/** Whether or not the content is visible. */
	active?: boolean,
	/** Only allow one panel open at a time. */
	exclusive?: boolean,
	/** Required when rendered as Form.field */
	required?: boolean,
	/** Animation settings.  */
	animation?: any,
	/** Animation CSS easing function.  */
	easing?: string,
	/** Animation duration.  */
	duration?: number,
	/** Parent index.  */
	parentIndex?: number,
	/** Nested level.  */
	level?: number,
	/** Set maximum levels of accordion. */
	maxLevels: number,
	/** Array of props for AccordionPanel. Example: [{key: 'panel1', title: 'Panel title', content: 'Panel content', panels: Array<AccordionPanelType> }] */
	panels?: Array<AccordionPanelProps>,
	/** Called on link click only. */
	onLinkClick?: (
		event: SyntheticEvent<>
	) => void,
	// Do not print out to docs. For testing purposes only
	onAnimationEnd?: (
		newHeight: number
	) => void
};

const DEFAULTS = {
	active: false,
	exclusive: false,
	easing: 'ease-in-out',
	level: 1
};

const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	const handleAnimationEnd = (newHeight: number) => {
		if (typeof props.onAnimationEnd === 'function') {
			props.onAnimationEnd(newHeight);
		}
	};

	// Assign classes
	const classes = classNames({
		required: props.required,
		'last-level': props.maxLevels && props.level === props.maxLevels - 1
	});

	return (!props.maxLevels || props.level < props.maxLevels) && (props.children || props.panels) ? (
		<Transition animation='height' visible={props.active} onComplete={handleAnimationEnd}>
			<AccordionContent as={props.as} active={props.active} className={classes}>
				{props.children}
				{props.panels ? (
					<AccordionAccordion
						defaultActiveIndex={props.defaultActiveIndex}
						exclusive={props.exclusive}
						panels={props.panels}
						parentIndex={props.parentIndex}
						level={props.level}
						maxLevels={props.maxLevels}
						onLinkClick={props.onLinkClick}
						onAnimationEnd={props.onAnimationEnd}
					/>
				) : null}
			</AccordionContent>
		</Transition>
	) : null;
});

Component.defaultProps = DEFAULTS;
Component.displayName = 'Accordion.Content';

export default Component;
