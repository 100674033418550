/**
 * @prettier
 * @flow
 */

import React, { useRef, useEffect } from 'react';
import Slider from 'rc-slider';
import raf from 'rc-util/lib/raf';
import Tooltip from 'rc-tooltip';

import type { Props } from '../NumberSlider';

type HandleProps = {
	value: number,
	visible: boolean,
	unit?: string,
	children: React.Node
};

const HandleTooltip = (props: HandleProps) => {
	const tipFormatter = () => (props.unit && props.value ? `${props.value} ${props.unit}` : props.value);
	const tooltipRef = useRef();
	const rafRef = useRef();

	const cancelKeepAlign = () => {
		raf.cancel(!rafRef.current);
	};

	const keepAlign = () => {
		rafRef.current = raf(() => {
			tooltipRef.current.forcePopupAlign();
		});
	};

	useEffect(() => {
		if (props.visible) {
			keepAlign();
		} else {
			cancelKeepAlign();
		}
		return cancelKeepAlign;
	}, [props.value, props.visible]);

	return (
		<Tooltip
			placement='top'
			overlay={tipFormatter}
			overlayInnerStyle={{ minHeight: 'auto' }}
			ref={tooltipRef}
			visible={props.visible}
		>
			{props.children}
		</Tooltip>
	);
};

const TooltipSlider = (props: Props) => {
	const tipHandleRender = (node, handleProps) => (
		<HandleTooltip value={handleProps.value} visible={handleProps.dragging} unit={props.unit}>
			{node}
		</HandleTooltip>
	);

	return <Slider {...props} handleRender={tipHandleRender} />;
};

export default TooltipSlider;
