/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import Validate from 'liana-ui/definitions/component/Validate';
import { AccordionTitle } from 'semantic-ui-react';
import { Label, Icon } from 'liana-ui/components/';
import type { Props as LabelProps } from 'liana-ui/components/label/Label';
import type { Props as IconProps } from 'liana-ui/components/icon/Icon';

// prettier-ignore
type Props = {
	/** Primary content. */
	children?: React.Node,
	/** An element type to render as */
	as: React.Node,
	/** Link for NavLink */
	to: string,
	/** If link should only open toggle and not redirect */
	disableLink: boolean,
	/** Whether or not the title is in the open state. */
	active?: boolean,
	/** Whether or not the title is upsell feature. */
	upsell?: boolean,
	/** AccordionTitle index inside Accordion. */
	index: string | number,
	/** Shorthand for Icon. */
	icon?: string | IconProps,
	/** Array of Label props objects. Eq. { text: 'Text', icon: 'folder' } */
	labels?: Array<string> | Array<LabelProps>,
	/** Accordion title can vary in size. */
	size?: 'large' | 'big',
	/* Accordion title can have additional classes. */
	className?: string,
	/** Called on any click. */
	onClick?: (
		event: SyntheticEvent<>,
		data: any
	) => void,
	/** Called on link click only. */
	onLinkClick?: (
		event: SyntheticEvent<>
	) => void
};

const DEFAULTS = {
	active: false,
	upsell: false,
	icon: 'fa-chevron-right fa-solid'
};

const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	// Get link type
	let linkType = Validate.linkType(props.to);

	const classes = classNames(
		props.className,
		{
			'last-link': !props.disableLink
		},
		props.size
	);

	let content = props.labels ? (
		<>
			<span className='text'>
				{props.children} <Label.Group labels={props.labels} />
			</span>
		</>
	) : (
		<span className='text'>{props.children}</span>
	);

	if (props.upsell) {
		content = (
			<>
				{content}{' '}
				<span className='upsell-icon'>
					<Icon squared fitted name='fa-gem' color='purple' size='small' />
				</span>
			</>
		);
	}

	const handleClick = (event: ?SyntheticEvent<>, data: any) => {
		if (props.disableLink) {
			event.preventDefault();
		}
		if (typeof props.onClick === 'function') {
			props.onClick(event, data);
		}
		if (typeof props.onLinkClick === 'function' && !props.disableLink && props.to) {
			props.onLinkClick(event, data);
		}
	};

	return (
		<AccordionTitle
			as={linkType === 'external' ? 'a' : props.as}
			to={linkType === 'internal' ? props.to : undefined}
			href={linkType === 'external' ? props.to : undefined}
			target={linkType === 'external' ? '_blank' : undefined}
			rel={linkType === 'external' ? 'noopener noreferrer' : undefined}
			activeClassName={props.to ? 'current' : undefined}
			active={props.active}
			index={props.index}
			content={content}
			icon={props.icon}
			className={classes}
			onClick={handleClick}
		/>
	);
});

Component.defaultProps = DEFAULTS;
Component.displayName = 'Accordion.Title';

export default Component;
