/**
 * @prettier
 * @flow
 */

import CountUp from 'countup.js'; // https://github.com/inorganik/countUp.js
import { useRef, useEffect } from 'react';
import { useIntl } from 'react-intl';

// prettier-ignore
type Props = {
	/** Initial value. */
	start?: number | FormattedNumber,
	/** Target value. */
	end: number | FormattedNumber,
	/** Delay between animation starting. */
	delay?: Number,
	/** Animation duration in seconds. */
	duration?: Number,
	/** Is the Counter visible on animation start. */
	visible?: boolean,
	/** Callback when count up animation is ready */
	onComplete?: () => void
};

const DEFAULTS = {
	start: 0,
	delay: 250,
	duration: 1,
	visible: true
};

/** COMPONENT BASED ON: https://github.com/inorganik/countUp.js */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	const intl = useIntl();
	const ref = useRef();
	let timeout = null;
	let instance = null;
	let sample = intl.formatNumber(1234.45); // Hack: Get language decimal separator since ECMA-402 doesn't have an API for this
	let isFormatted = (val: any) => React.isValidElement(val) && val.type.displayName === 'FormattedNumber';

	const formatValue = (val: any) => {
		let formatted = isFormatted(val)
			? Number(val.props.value) // val of FormattedNumber
			: Number(val);
		return formatted;
	};

	const formatNumber = (val: number) => {
		/* istanbul ignore else */
		if (props.end.props && props.end.props.style === 'percent' && val >= 1) {
			val = val / 100;
		}
		return intl.formatNumber(val, props.end.props);
	};

	const handleComplete = () => {
		// Trigger onComplete callback funtion
		if (typeof props.onComplete === 'function') {
			props.onComplete();
		}
	};

	// CountUp defaults
	let COUNTUP_OPTIONS = {
		delay: props.delay,
		useEasing: true,
		useGrouping: true,
		separator: sample.charAt(1),
		decimal: sample.charAt(5)
	};

	useEffect(() => {
		/* istanbul ignore else */
		if (ref.current && props.visible === true) {
			if (props.duration > 0) {
				let countStart = formatValue(props.start);
				let countEnd = formatValue(props.end);
				if (isFormatted(props.end)) {
					COUNTUP_OPTIONS.formattingFn = formatNumber;
				}
				instance = new CountUp(
					ref.current, // Instance
					countStart, // Start number
					countEnd, // End number
					(String(countEnd).split('.')[1] || []).length, // Decimals
					props.duration, // Animation duration
					COUNTUP_OPTIONS // CountUp options
				);
				if (props.delay > 0) {
					// Delay before starting animation
					timeout = setTimeout(() => instance.start(handleComplete), props.delay);
				} else {
					instance.start(handleComplete);
				}
			} else {
				handleComplete();
			}
		}
	}, [props.visible, props.end]);

	useEffect(() => {
		return () => clearTimeout(timeout); // Component unmount
	}, []);

	return (
		<span ref={ref}>
			{props.duration > 0 ? props.start : isFormatted(props.end) ? props.end : intl.formatNumber(props.end)}
		</span>
	);
});

Component.displayName = 'Count';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
