/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import type { Tooltip } from 'liana-ui/definitions/component/Types';
import Button from '../button/Button';
import Icon from '../icon/Icon';

import 'jquery-ui/ui/widgets/sortable';
import 'liana-ui/lib/jquery-tageditor/jquery.caret.min';
import 'liana-ui/lib/jquery-tageditor/jquery.tag-editor';

type Props = {
	/** A tag input must have name. */
	name: string,
	/** Current value for input. Use for controlled components only. Multiple values can be separated with comma. */
	value?: string,
	/** Initial value for input. Use for uncontrolled components only. Multiple values can be separated with comma. */
	defaultValue?: string,
	/** A tag input input can have a placeholder text. */
	placeholder?: string,
	/** A tag input can be clearable */
	isResettable?: boolean,
	/** A tag input can have additional classes. */
	classes?: string,
	/** A number input can have additional data attributes. */
	dataAttributes?: { [string]: string },
	/**
		A number input can have a tooltip.
		VALUES[tooltip={{'data-content': string, 'data-variation': string, 'data-delay': number}}]
	*/
	tooltip?: Tooltip,
	/** Function called when tag editor is focused. */
	onFocus?: () => mixed,
	/* Legacy component has wrong naming! */
	change?: (
		field,
		editor,
		tags
	) => {
		field: string,
		editor: HTMLElement,
		tags: Array<string>
	},
	/** Function called when input is changed. */
	onChange?: (
		field,
		editor,
		tags
	) => {
		field: string,
		editor: HTMLElement,
		tags: Array<string>
	}
};

/** COMPONENT BASED ON: https://github.com/Pixabay/jQuery-tagEditor */
export default class TextareaTags extends React.PureComponent<Props> {
	_textarea: { current: React.ElementRef<'textarea'> | null };
	_tagEditor: { current: React.ElementRef<'div'> | null };

	constructor(props: Props) {
		super(props);
		this.state = {
			internalValue: props.defaultValue
		};
		this._tagEditor = React.createRef();
		this._textarea = React.createRef();
	}

	componentDidMount() {
		let $this = this;
		$(this._textarea.current).tagEditor({
			placeholder: this.props.placeholder,
			maxLength: 150,
			forceLowercase: false,
			removeDuplicates: true,
			animateDelete: 0,
			onChange: (field: string, editor: HTMLElement, tags: Array<string>) => {
				this.setState({ internalValue: tags });
				$this._onTagChange(field, editor, tags);
			}
		});
		$('ul', this._tagEditor.current).on('click', () => {
			if (typeof this.props.onFocus === 'function') {
				this.props.onFocus();
			}
			$(this._tagEditor.current).trigger('click');
		});
	}

	componentDidUpdate() {
		if (this.props.value === '') {
			this.clearTags();
		}
	}

	_onTagChange = (field: string, editor: HTMLElement, tags: Array<string>) => {
		let $field = $(this._tagEditor.current).closest('.field');
		$field.removeClass('error');
		$field.find('.red.prompt').transition('slide', {
			duration: 150,
			onComplete: /* istanbul ignore next */ () => $field.find('.red.prompt').remove()
		});
		/* istanbul ignore else */
		if (typeof this.props.change === 'function') {
			this.props.change(field, editor, tags); // TODO: Remove legacy vestige
		}
		/* istanbul ignore else */
		if (typeof this.props.onChange === 'function') {
			this.props.onChange(field, editor, tags);
		}
		$(this._textarea.current).trigger('change');
	};

	/** Clear all tags */
	clearTags() {
		let tags = $(this._textarea.current).tagEditor('getTags')[0].tags;
		for (let i = 0; i < tags.length; i++) {
			$(this._textarea.current).tagEditor('removeTag', tags[i], true);
		}
	}

	get ref() {
		return this._textarea.current;
	}

	get value() {
		return this.props.value === undefined ? this.state.internalValue : this.props.value;
	}

	render() {
		const finalClasses = this.props.tooltip?.className ? this.props.tooltip.className : this.props.classes;
		const hasValue = Array.isArray(this.value) ? !!this.value.length : !!this.value;

		const classes = classNames('tag-editor-wrapper', finalClasses, {
			'popup-open': this.props.tooltip,
			'reset-button-enabled': this.props.isResettable && !!hasValue
		});

		return (
			<div
				{...this.props.tooltip}
				className={classes}
				style={this.props.isResettable ? { position: 'relative' } : {}}
				ref={this._tagEditor}
			>
				<textarea
					ref={this._textarea}
					name={this.props.name}
					value={this.value}
					defaultValue={this.props.defaultValue}
					placeholder={this.props.placeholder}
					{...this.props.dataAttributes}
				/>
				{this.props.isResettable && !!hasValue && (
					<div className='tag-editor-reset-button-container'>
						<Button classes='circular icon extramini reset-button' onClick={this.clearTags.bind(this)}>
							<Icon classes='fa-remove fa-solid' />
						</Button>
					</div>
				)}
			</div>
		);
	}
}
