/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Validate from 'liana-ui/definitions/component/Validate';
import { Item } from 'semantic-ui-react';
import {
	Image,
	Button,
	ContextMenu,
	Checkbox,
	Label,
	List,
	Grid,
	Responsive,
	NavLink,
	Transition,
	Visibility
} from 'liana-ui/components/';
import type { IntlComponent } from 'react-intl';
import type { Props as ImageProps } from 'liana-ui/components/image/Image';
import type { Props as ButtonProps } from 'liana-ui/components/button/Button';
import type { Props as ButtonGroupProps } from 'liana-ui/components/button/ButtonGroup';
import type { Props as ContextMenuProps } from 'liana-ui/components/menu/ContextMenu';
import type { Props as LabelProps } from 'liana-ui/components/label/Label';
import type { Props as LimitLabelProps } from 'liana-ui/components/label/LimitLabel';
import type { Props as ListProps } from 'liana-ui/components/list/List';

// prettier-ignore
type Props = {
	/** 
	 	An item can have a header. 
	 	PROPS[IntlComponent=/language/localisation]
	*/
	header: string | IntlComponent | React.Node,
	/** A selectable or ideantifiable item must have an id */
	id?: number | string,
	/**
	 	An item can have and image. 
	 	PROPS[ImageProps=/components/texts/image]
	*/
	image?: string | ImageProps,
	/** Only the card header can be a link. Opens absolute links in new browser tab and internal links via router. */
	headerLink?: string,
	/** The whole card can be a link. Opens absolute links in new browser tab and internal links via router. */
	itemLink?: string,
	/** 
	 	An item can have a description. 
	 	PROPS[IntlComponent=/language/localisation]
	*/
	description?: string | IntlComponent | React.Node,
	/** 
	 	An item can sub info text or content. 
	 	PROPS[ListProps=/components/containers/list]
	*/
	subinfo?: string | ListProps | React.Node | Array<React.Node>,
	/**
		A header can have labels and/or limit label.
		PROPS[LabelProps=/components/labels/labels/label/, LimitLabelProps=/components/labels/limit-label/]
	*/
	labels?:
		LabelProps
		| Array<LabelProps>
		| LimitLabelProps,
	/** 
	 	An item can have action buttons or context menus. Max 5 recommended.
		PROPS[ButtonProps=/components/buttons/button/, ContextMenuProps=/components/menus/context-menu] 
	*/
	actions?: Array<ButtonProps | ContextMenuProps>,
	/** 
		An item can have single button. 
		PROPS[ButtonProps=/components/buttons/button/]
	*/
	button?: ButtonProps,
	/** 
		An item can have a button group. 
		PROPS[ButtonGroupProps=/components/buttons/button-group/]
	*/
	buttonGroup?: ButtonGroupProps,
	/** A Item can have different layouts. */
	layout?: 'small' | 'big',
	/** 
	  	An item can limit header to maximum amount of lines with ellipsis. 
	 	VALUES[1 - 10]
	*/
	limitHeader?: number,
	/** 
	    An item can limit description to maximum amount of lines with ellipsis. 
		VALUES[1 - 10]			
	*/
	limitDescription?: number,
	/** An item can be in a selected state. */
	selected?: boolean,
	/** An item can be in a viewed state. */
	viewed?: boolean,
	/* Used to add a single item to a group if the component is not used via ItemGroup component. */
	singleItem?: boolean,
	/** Function called on item select. */
	onSelect?: (
		event: SyntheticEvent<>,
		data: {
			selected: boolean
		}
	) => void,
	/** Function called on card click. */
	onClick?: (
		event: SyntheticEvent<>
	) => void,
	/** Element's top edge has passed bottom of screen. */
	onTopVisible?: (id: number | string) => void,
	/** Callback on when delete animation ends. */
	onAfterDeleteItems?: () => void
};

const DEFAULTS = {
	viewed: false,
	singleItem: true
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/views/item/ */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	const navigate = useNavigate();

	// Get link type
	let headerLinkType = Validate.linkType(props.headerLink);
	let itemLinkType = Validate.linkType(props.itemLink);

	const handleContainerClick = (event: SyntheticEvent<HTMLButtonElement>) => {
		// Trigger onClick callback funtion
		if (typeof props.onClick === 'function') {
			props.onClick(event);
		}

		// Trigger internal link
		if (itemLinkType && (itemLinkType === 'internal' || itemLinkType === 'anchor')) {
			event.preventDefault();
			if (itemLinkType === 'internal') {
				navigate(props.itemLink);
			}
			if (itemLinkType === 'anchor') {
				Safely.scroll(props.itemLink, () => {
					navigate(`${window.location.pathname}${props.itemLink}`);
				});
			}
		}
	};

	const getActions = () => {
		let actions = [];
		if (Array.isArray(props.actions)) {
			for (let i = 0; i < props.actions.length; i++) {
				if (props.actions[i].button) {
					if (
						typeof props.actions[i].button?.popup === 'string' ||
						React.isValidElement(props.actions[i]).button?.popup
					) {
						props.actions[i].button.popup = {
							text: props.actions[i].button.popup,
							delay: 500
						};
					}
					actions.push(
						<Grid.Column collapsing key={`button-column-${i}`}>
							<Button {...props.actions[i].button} basic fitted size='tiny' noWrap />
						</Grid.Column>
					);
				} else if (typeof props.actions[i].contextMenu) {
					actions.push(
						<Grid.Column collapsing key={`context-menu-column-${i}`}>
							<ContextMenu {...props.actions[i].contextMenu} fitted basic size='tiny' direction='left' />
						</Grid.Column>
					);
				}
			}
		}
		return (
			<div className='item-actions'>
				<Grid columns='equal' compact='very' textAlign='center' verticalAlign='middle' singleRow>
					{actions}
				</Grid>
			</div>
		);
	};

	const getExtra = () => {
		let extra =
			props.actions || props.labels || props.button || props.buttonGroup ? (
				<Item.Extra extra>
					<Grid compact='very' verticalAlign='middle'>
						<Grid.Column fluid verticalAlign='middle'>
							{props.labels ? (
								<Label.Group
									as='span'
									size='tiny'
									labels={Array.isArray(props.labels) ? props.labels : [props.labels]}
								/>
							) : null}
						</Grid.Column>
						<Responsive minDevice='computer'>
							<Grid.Column collapsing textAlign='right'>
								{props.button ? <Button {...props.button} size='mini' fitted /> : null}
								{props.buttonGroup ? <Button.Group {...props.buttonGroup} size='mini' fitted /> : null}
							</Grid.Column>
						</Responsive>
					</Grid>
				</Item.Extra>
			) : null;

		return extra;
	};

	// Assign classes
	let classes = classNames({
		'layout-big': !props.layout || props.layout === 'big',
		'layout-small': props.layout === 'small',
		'has-image': props.image,
		selected: props.selected,
		viewed: props.viewed
	});

	// Assign image classes
	let imageClasses = classNames('ui image image-wrapper', {
		'image-ratio': props?.image?.ratio,
		tiny: !props.layout || props.layout === 'big',
		mini: props.layout === 'small'
	});

	// Count fluid placeholder ratio
	let styles = {};
	if (props?.image?.ratio && typeof props.image.ratio === 'string' && props.image.ratio.indexOf('/') > -1) {
		let ratio = props.image.ratio;
		styles = {
			paddingTop: (ratio.split('/')[1] / ratio.split('/')[0]) * 100 + '%'
		};
	}

	// Delete transition
	let visible = props.deleted ? !props.deleted : true,
		transitionOnMount = props.deleted ? false : props.added ? true : null,
		animation = props.deleted ? 'fly left' : props.added ? 'glow' : null,
		duration = props.deleted ? 500 : props.added ? 1000 : 0,
		onHide = props.deleted ? props.onAfterDeleteItems : null,
		style = props.deleted ? { background: 'yellow' } : undefined;

	// Define Item
	let item = (
		<Transition
			visible={visible}
			transitionOnMount={transitionOnMount}
			unmountOnHide={true}
			animation={animation}
			duration={duration}
			onHide={onHide}
			reactKey={`animation-${props.id}`}
		>
			<Item
				style={style}
				as={typeof props.onClick === 'function' ? 'a' : undefined}
				className={classes}
				href={props.itemLink}
				target={itemLinkType === 'external' ? '_blank' : undefined}
				rel={itemLinkType === 'external' ? 'noopener noreferrer' : undefined}
				onClick={typeof props.onClick === 'function' ? handleContainerClick : undefined}
			>
				<Grid compact stackable>
					{typeof props.onSelect === 'function' ? (
						<Grid.Column collapsing>
							<div className='state-container'>
								{typeof props.onSelect === 'function' ? (
									<span className='select-checkbox'>
										<Checkbox
											checked={props.selected}
											name='item'
											value={props.id}
											onChange={props.onSelect}
										/>
									</span>
								) : null}
							</div>
						</Grid.Column>
					) : null}

					{(!props.layout || props.layout === 'big') && props.image && (
						<Grid.Column width={2} collapsing>
							<div className={imageClasses} style={styles}>
								{typeof props.image === 'string' ? (
									<Image size='tiny' src={props.image} />
								) : (
									<Image size='tiny' {...props.image} />
								)}
							</div>
						</Grid.Column>
					)}

					<Grid.Column fluid>
						<Item.Content>
							<Grid>
								<Grid.Column fluid>
									<Item.Header
										as='h3'
										className={`item-header text-hyphenate${
											props.limitHeader ? ` text-lines-${props.limitHeader}` : ''
										}`}
									>
										{props.headerLink ? (
											<NavLink
												to={props.headerLink}
												target={headerLinkType === 'external' ? '_blank' : undefined}
												rel={headerLinkType === 'external' ? 'noopener noreferrer' : undefined}
											>
												{props.header}
											</NavLink>
										) : (
											props.header
										)}
									</Item.Header>
								</Grid.Column>

								{props.new ? (
									<Grid.Column collapsing key='column-new'>
										<span className='new-label'>
											<Label
												text={<FormattedMessage id='component.label.new' />}
												size='tiny'
												notification
											/>
										</span>
									</Grid.Column>
								) : null}
								{props.layout === 'small' && props.image ? (
									<Grid.Column collapsing key='column-image'>
										<div className={imageClasses} style={styles}>
											{typeof props.image === 'string' ? (
												<Image
													key={`image-${props.id}`}
													rounded
													src={props.image}
													size='mini'
												/>
											) : (
												<Image key={`image-${props.id}`} {...props.image} size='mini' />
											)}
										</div>
									</Grid.Column>
								) : null}

								{props.actions && props.actions.length > 0 ? (
									<Responsive minDevice='computer'>
										<Grid.Column collapsing>{getActions()}</Grid.Column>
									</Responsive>
								) : null}
							</Grid>

							{props.subinfo ? (
								<Item.Meta>
									{Array.isArray(props.subinfo) ? (
										<List horizontal divided size='small' items={props.subinfo} />
									) : (
										props.subinfo
									)}
								</Item.Meta>
							) : null}

							<Item.Description>
								<p className={props.limitDescription ? `text-lines-${props.limitDescription}` : ''}>
									{props.description}
								</p>
							</Item.Description>

							{getExtra()}

							<Responsive maxDevice='tablet'>
								{props.actions && props.actions.length > 0 ? (
									<Item.Extra extra>{getActions()}</Item.Extra>
								) : null}
								{props.button || props.buttonGroup ? (
									<Item.Extra extra>
										{props.button ? <Button {...props.button} size='mini' fitted fluid /> : null}
										{props.buttonGroup ? (
											<Button.Group {...props.buttonGroup} size='mini' fitted fluid />
										) : null}
									</Item.Extra>
								) : null}
							</Responsive>
						</Item.Content>
					</Grid.Column>
				</Grid>
			</Item>
		</Transition>
	);

	if (typeof props.onTopVisible === 'function') {
		item = <Visibility onTopVisible={() => props.onTopVisible(props.id)}>{item}</Visibility>;
	}

	// Add to group if not used via ItemGroup component.
	item = props.singleItem ? <Item.Group divided>{item}</Item.Group> : item;

	return item;
});

// Documentation generation support
Component.displayName = 'Item';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
