/**
 * @prettier
 * @flow
 */

import { useState, useEffect } from 'react';
import { Dropdown, Input } from 'liana-ui/components';

type Props = {
	/** A prefill input must have an input name. */
	name: string,
	/**
		A prefill input can have a placeholder text.
		PROPS[IntlComponent=/localization/]
	*/
	/** Text input placeholder */
	placeholder?: string | IntlComponent,
	/** Initial value for input. Use for uncontrolled components only. */
	defaultValue?: string,
	/** Current value for input. Use for controlled components only. */
	value?: string,
	/** A prefill input can have prefix options in a dropdown. */
	prefix: Array<string>,
	/** A prefill input can have postfix options in a dropdown. */
	postfix: Array<string>,
	/** A prefill input can be locked to indicate that the field is in use but can not be edited. */
	locked?: boolean,
	/** A prefill input can be  disabled. */
	disabled?: boolean,
	/** A prefill input can be different size. */
	size?: 'small' | 'large',
	/**
		Popup text or, react-intl coomponent or object of properties for Popup component.
		PROPS[IntlComponent=/language/localisation/, PopupProps=/components/modals/popup/]
	*/
	popup?: string | IntlComponent | PopupProps,
	/** Function called on input change. */
	onChange?: (
		event: SyntheticEvent<>,
		data: {
			name: string,
			value: string
		}
	) => void
};

type Parts = {
	prefix: string,
	infix: string,
	postfix: string
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/divider */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	const parsePrefix = (val: string, replace: boolean = false) => {
		if (typeof val === 'string' && val.length > 0 && Array.isArray(props.prefix) && props.prefix.length > 0) {
			for (let i of props.prefix) {
				if (val.indexOf(i) === 0) {
					return replace === true ? val.substring(i.length) : i;
				}
			}
		}
		return replace === true ? val : '';
	};

	const parsePostfix = (val: string, replace: boolean = false) => {
		let idx;
		if (typeof val === 'string' && val.length > 0 && Array.isArray(props.postfix) && props.postfix.length > 0) {
			for (let j of props.postfix) {
				idx = val.lastIndexOf(j);
				if (idx > -1 && idx === val.length - j.length) {
					return replace === true ? val.substring(0, val.length - j.length) : j;
				}
			}
		}
		return replace === true ? val : '';
	};

	const parseInfix = (val: string, asData: boolean = false) => {
		let idx = -1,
			prefix = parsePrefix(val),
			postfix = parsePostfix(val),
			data = { infix: val };
		if (prefix) {
			data.prefix = prefix;
			if (val.indexOf(prefix) === 0) {
				data.infix = data.infix.substring(prefix.length);
			}
		}
		if (postfix) {
			data.postfix = postfix;
			idx = val.lastIndexOf(postfix);
			if (idx > -1 && idx === val.length - postfix.length) {
				data.infix = data.infix.substring(0, data.infix.length - postfix.length);
			}
		}
		return asData ? data : data.infix;
	};

	let value = props.value || props.defaultValue || '';

	let [parts, setParts] = useState({
		infix: parseInfix(value),
		prefix: parsePrefix(value) || props.prefix[0] || '',
		postfix: parsePostfix(value) || props.postfix[0] || ''
	});

	const handleValue = (event: SyntheticEvent<>, val: string | Parts, part: 'prefix' | 'infix' | 'postfix') => {
		let ret = '',
			newParts = typeof val === 'object' ? val : { [part]: val };

		newParts = { ...parts, ...newParts };
		setParts(newParts);
		ret = `${newParts.prefix}${newParts.infix}${newParts.postfix}`;

		if (part && typeof props.onChange === 'function') {
			props.onChange(event, { name: props.name, value: ret });
		}
		return ret;
	};

	const generateOptions = (data: Array<string>) => {
		let opts = [];
		if (Array.isArray(data) && data.length > 0) {
			for (let i of data) {
				opts.push({ text: i, value: i });
			}
		}
		return opts;
	};

	const generatePrefill = (type: 'prefix' | 'postfix') => {
		let prefill = undefined;
		const fixOpts = {
			fluid: false,
			disabled: props.disabled || props.locked,
			locked: props.locked,
			size: props.size
		};

		if (type === 'prefix' && Array.isArray(props.prefix) && props.prefix.length > 0) {
			prefill =
				props.prefix.length === 1 ? (
					props.prefix[0]
				) : (
					<Dropdown
						name={props.name + '-prefix'}
						value={parts.prefix}
						options={prefixOptions}
						onChange={handlePrefix}
						{...fixOpts}
					/>
				);
		} else if (type === 'postfix' && Array.isArray(props.postfix) && props.postfix.length > 0) {
			prefill =
				props.postfix.length === 1 ? (
					<div className='ui small label right'>
						<span>
							<span className='ui text'>{props.postfix[0]}</span>
						</span>
					</div>
				) : (
					<Dropdown
						name={props.name + '-postfix'}
						className='label right'
						value={parts.postfix}
						options={postfixOptions}
						onChange={handlePostfix}
						{...fixOpts}
					/>
				);
		}

		return prefill;
	};

	const prefixOptions = generateOptions(props.prefix),
		postfixOptions = generateOptions(props.postfix),
		handleInfix = (event, data) => handleValue(event, parseInfix(data.value, true), 'infix'),
		handlePrefix = (event, data) => handleValue(event, data.value, 'prefix'),
		handlePostfix = (event, data) => handleValue(event, data.value, 'postfix');

	// Handle new values supplied via props
	useEffect(() => {
		const current = parts.infix ? `${parts.prefix}${parts.infix}${parts.postfix}` : '';
		if (typeof props.value === 'string' && props.value !== current) {
			handleValue(null, parseInfix(props.value, true), 'infix');
		}
	}, [props.value]);

	// SUIR actions & labels
	// https://github.com/Semantic-Org/Semantic-UI-React/issues/2148
	return (
		<>
			<Input
				placeholder={props.placeholder}
				value={parts.infix}
				onChange={handleInfix}
				disabled={props.disabled}
				locked={props.locked ? 'input' : undefined}
				popup={props.popup}
				size={props.size}
				label={generatePrefill('prefix')}
				action={generatePrefill('postfix')}
			/>
			<input
				className='hidden'
				name={props.name}
				value={parts.infix ? `${parts.prefix}${parts.infix}${parts.postfix}` : ''}
				disabled={props.disabled}
			/>
		</>
	);
});

// Documentation generation support
Component.displayName = 'PrefillInput';
Component.defaultProps = {
	prefix: [],
	postfix: []
};

export type { Props };
export default Component;
