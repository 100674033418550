/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import React, { useState, forwardRef } from 'react';
import { handleRef } from 'liana-ui/definitions/component/Ref';
import { Input } from 'semantic-ui-react';
import { Flag, Text, Label, Button, Icon, Popup } from 'liana-ui/components/';
import type { IntlComponent } from 'react-intl';
import type { Props as ButtonProps } from 'liana-ui/components/button/Button';
import type { Props as PopupProps } from 'liana-ui/components/popup/Popup';

// prettier-ignore
type Props = {
	/** An input must have an input name. */
	name: string,
	/** An input can have diffrent HTML input type. */
	type: 'text' | 'email' | 'password' | 'number' | 'tel' | 'url' | 'hidden',
	/** Initial value for input. Use for uncontrolled components only. */
	defaultValue?: string,
	/** Current value for input. Use for controlled components only. */
	value?: string,
	/**
		An input can have a placeholder text.
		PROPS[IntlComponent=/localization/]
	*/
	placeholder?: string | IntlComponent,
	/** An input can have a minimum value for number type inputs. */
	min?: number,
	/** An input can have a maximum value for number type inputs. */
	max?: number,
	/** An input can have an icon. */
	icon?: string,
	/** An input can have a flag. */
	flag?: string,
	/** An icon or flag can appear inside an input on the left or right. */
	iconPosition?: 'left' | 'right',
	/** An input can have a label. */
	label?: string,
	/** An label can appear inside an input on the left or right. */
	labelPosition?: 'left' | 'right',
	/**
		An input can have a action element. You provide Button props or any other element.
		PROPS[ButtonProps=/components/buttons/button/]
	*/
	action?: ButtonProps | React.ReactElement,
	/** An input can limit possible characters to maximum amount. */
	maxLength?: number,
	/** An input can take on the size of its container. */
	fluid?: boolean,
	/** An input can be locked to indicate that the field is in use but can not be edited. Use `input` to lock only input field but not action button. */
	locked?: boolean | 'input',
	/** An input can be  disabled. */
	disabled?: boolean,
	/** An input can be be read only. */
	readOnly?: boolean,
	/** An input can be different size. */
	size?: 'small' | 'large',
	/**
		Popup text or, react-intl coomponent or object of properties for Popup component.
		PROPS[IntlComponent=/language/localisation/, PopupProps=/components/modals/popup/]
	*/
	popup?: string | IntlComponent | PopupProps,
	/** Function called on input focus. */
	onFocus?: (
		event: SyntheticEvent<>,
		data: {
			name: string,
			value: string
		}
	) => void,
	/** Function called on input change. */
	onChange?: (
		event: SyntheticEvent<>,
		data: {
			name: string,
			value: string
		}
	) => void
};

// Component default property values
const DEFAULTS = {
	type: 'text',
	iconPosition: 'left',
	labelPosition: 'left',
	fluid: false,
	locked: false,
	disabled: false,
	readOnly: false
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/input */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>(
	forwardRef((props: Props, ref: any) => {
		// Count amount of charachters
		const getLength = (val: string) => {
			if (val) {
				return val.length;
			} else {
				return 0;
			}
		};

		// Internal states
		let [internalValue, setInternalValue] = useState(props.defaultValue);
		let value = props.value === undefined ? internalValue : props.value;

		// Limit value to maxLength
		if (getLength(value) > props.maxLength) {
			value = value.substring(0, props.maxLength);
		}

		// Called on input focus.
		const handleFocus = (event: ?SyntheticEvent<>) => {
			if (typeof props.onFocus === 'function') {
				props.onFocus(event, handleCallbackData({ value: event.target.value }));
			}
		};

		// Called on input change.
		const handleChange = (event: ?SyntheticEvent<>, data: any) => {
			// Set current value internally
			setInternalValue(data.value);

			// Trigger onChange callback with formatted data
			if (typeof props.onChange === 'function') {
				props.onChange(event, handleCallbackData(data));
			}

			// Trigger Form onChange on value change
			if (typeof event === 'object' && event.target && typeof event.target.dispatchEvent === 'function') {
				event.target.dispatchEvent(new Event('change', { bubbles: true }));
			}
		};

		// Handle data returned by callbacks.
		const handleCallbackData = (data: any) => {
			return {
				name: props.name,
				value: data.value
			};
		};

		// Assign classes
		let classes = classNames({
			locked: props.locked === true,
			hidden: props.type === 'hidden',
			limited: props.maxLength,
			'input-locked': props.locked === 'input'
		});

		// Field icon or flag
		let icon = undefined;
		if (props.icon) {
			icon = <Icon name={props.icon} />;
		} else if (props.flag) {
			icon = (
				<i className='icon'>
					<Flag key={props.flag} name={props.flag} />
				</i>
			);
		}

		// Field label
		let label = props.label;
		if ((typeof props.label === 'string' && props.label) || props.maxLength) {
			label = (
				<Label
					key='label'
					text=<Text color={getLength(value) >= props.maxLength ? 'red' : ''}>{props.label || getLength(value) + '/' + props.maxLength.toString()}</Text>
				/>
			);
		}

		// Field button
		let action = undefined;
		if (props.action) {
			action = React.isValidElement(props.action) ? (
				props.action
			) : (
				<Button
					text={props.action.text}
					icon={props.action.icon}
					iconPosition={props.action.iconPosition}
					disabled={props.disabled}
					onClick={props.action.onClick ? props.action.onClick : () => {}}
				/>
			);
		}

		// Input field
		let input = (
			<Input
				ref={handleRef(ref, 'inputRef')}
				name={props.name}
				className={classes}
				data-default={props.defaultValue}
				value={value}
				placeholder={props.placeholder}
				min={props.min}
				max={props.max}
				type={props.type}
				icon={icon}
				iconPosition={props.flag || props.icon ? props.iconPosition : undefined}
				label={label}
				labelPosition={
					props.label || props.maxLength ? (props.maxLength ? 'right' : props.labelPosition) : undefined
				}
				action={action}
				maxLength={props.maxLength}
				fluid={props.fluid}
				disabled={props.disabled}
				readOnly={props.readOnly || props.locked}
				size={props.size}
				autoComplete='off'
				onFocus={handleFocus}
				onChange={handleChange}
			/>
		);

		return Popup.attach(props.popup, input);
	})
);

// Documentation generation support
Component.displayName = 'Input';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
