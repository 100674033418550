// IMPORTANT!
// THIS FILE IS CONSIDERED LEGACY! DO NOT MODIFY THIS FILE OR IMPORT IT!

import 'fomantic-ui/definitions/modules/popup';

let timeout = null;
let focus = false;
let pointerOnly = window.matchMedia('(hover: hover) and (pointer: fine)').matches && !window.matchMedia('(any-hover: on-demand), (any-hover: none), (any-pointer: coarse), (any-pointer: none)').matches;

let popupOpen = {
	init: function() {
		$(document).on({
			mouseenter: function() {
				if (pointerOnly) {
					let $target = $(this),
						hidedelay = $target.data('hoverable') ? 1000 : 0,
						settings = {
							on: $target.data('on') || 'hover',
							position: $target.data('position') || 'top center',
							hoverable: $target.data('hoverable') || 'false',
							distanceAway: $target.data('distance') || 0,
							variation: $target.data('variation') || 'tiny',
							delay: {
								show: $target.data('delay') || 0,
								hide: hidedelay
							},
							className: {
								popup: 'ui popup ' + $target.data('classes') ? $target.data('classes') : ''
							},
							onShow: function() {
								if (focus === true) {
									clearTimeout(timeout);
									return false;
								}
								return true;
							}
						};
					if (settings.on !== 'click') {
						timeout = setTimeout(function() {
							if ($target.is(':hover')) {
								if (!$target.hasClass('visible')) {
									$target.popup(settings).popup('show');
								}
							}
						}, settings.delay.show);
					}
				}
			},
			mouseleave: function() {
				if (pointerOnly) {
					focus = false;
					clearTimeout(timeout);
				}
			},
			click: function(e) {
				focus = true;
				let $target = $(this),
					hidedelay = $target.data('hoverable') ? 1000 : 0,
					settings = {
						exclusive: true,
						on: $target.data('on') || 'hover',
						addTouchEvents: false,
						position: $target.data('position') || 'top center',
						hoverable: $target.data('hoverable') || 'false',
						variation: $target.data('variation') || 'tiny',
						delay: {
							show: $target.data('delay') || 0,
							hide: hidedelay
						}
					};
				if (settings.on === 'click') {
					$target.popup(settings).popup('show');
				} else {
					$target.popup('hide');
				}
			}
		}, '.popup-open');
	}
};

export default popupOpen;
