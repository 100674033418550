/**
 * @prettier
 * @flow
 */

import { type IntlShape } from 'react-intl';
import { StepTitle } from 'semantic-ui-react';

// prettier-ignore
type Props = {
	/** Title as text. */
	text: IntlShape | string
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/step/#groups-ordered */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	return <StepTitle>{props.text}</StepTitle>;
});

Component.displayName = 'StepTitle';

export default Component;
