/**
 * @prettier
 * @flow
 */

import { GridRow } from 'semantic-ui-react';
import { Responsive } from 'liana-ui/components/';

// prettier-ignore
type Props = {
	/** Content as children. */
	children: React.Node,
	/** Represents column count per line in Row. Possible values: 1 - 12 */
	columns?: string | number,
	/** A row can have its columns centered. */
	centered?: boolean,
	/** A grid can specify its text alignment. */
	textAlign?: 'left' | 'center' | 'right',
	/** A grid can specify its vertical alignment to have all its columns vertically aligned. */
	verticalAlign?: 'bottom' | 'middle' | 'top',
	/** Smallest device that component will be displayed with. */
	minDevice?: 'mobile' | 'tablet' | 'computer' | 'largescreen' | 'widescreen',
	/** Largest device that component will be displayed with. */
	maxDevice?: 'mobile' | 'tablet' | 'computer' | 'largescreen' | 'widescreen'
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/collections/grid/ */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	// Function to generate LianaUI GridColumn
	const createRow = (props: Props) => {
		return (
			<GridRow
				columns={props.columns}
				centered={props.centered}
				textAlign={props.textAlign}
				verticalAlign={props.verticalAlign}
			>
				{props.children}
			</GridRow>
		);
	};

	// Display reponsively
	let row =
		props.minDevice || props.maxDevice ? <Responsive {...props}>{createRow(props)}</Responsive> : createRow(props);

	return row;
});

// Documentation generation support
Component.displayName = 'Grid.Row';

export default Component;
