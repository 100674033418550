/** @prettier */

import { shouldPolyfill as numberFormatNeeded } from '@formatjs/intl-numberformat/should-polyfill';
import { shouldPolyfill as pluralRulesNeeded } from '@formatjs/intl-pluralrules/should-polyfill';
import { shouldPolyfill as relativeTimeformatNeeded } from '@formatjs/intl-relativetimeformat/should-polyfill';

/**
 * https://github.com/formatjs/react-intl/blob/master/docs/Upgrade-Guide.md#migrate-to-using-native-intl-apis
 * https://www.npmjs.com/package/@formatjs/intl-pluralrules
 * https://www.npmjs.com/package/@formatjs/intl-relativetimeformat
 */

const fetchPluralRules = async (language = 'en') => {
	if (pluralRulesNeeded(language)) {
		await import(/* webpackChunkName: "intl-polyfill" */ `@formatjs/intl-pluralrules/locale-data/${language}.js`);
	}
};

const fetchNumberFormat = async (language = 'en') => {
	if (numberFormatNeeded(language)) {
		await import(/* webpackChunkName: "intl-polyfill" */ `@formatjs/intl-numberformat/locale-data/${language}.js`);
	}
};

const fetchRelativeTimeFormat = async (language = 'en') => {
	if (relativeTimeformatNeeded(language)) {
		await import(
			/* webpackChunkName: "intl-polyfill" */ `@formatjs/intl-relativetimeformat/locale-data/${language}.js`
		);
	}
};

const fetchDateTimeFormatTimezone = async (language = 'en') => {
	await import(
		/* webpackChunkName: "intl-polyfill" */ `date-time-format-timezone/build/src/data/locales/locale-${language}.js`
	);
};

const fetchIntl = async () => {
	global.Intl = await import('intl');
	await import('date-time-format-timezone/build/src/date-time-format-timezone-all-zones-no-locale');
	if (pluralRulesNeeded('en')) {
		await import(/* webpackChunkName: "intl-polyfill" */ '@formatjs/intl-pluralrules/polyfill');
	}
	if (numberFormatNeeded('en')) {
		await import(/* webpackChunkName: "intl-polyfill" */ '@formatjs/intl-numberformat/polyfill');
	}
	if (relativeTimeformatNeeded('en')) {
		await import(/* webpackChunkName: "intl-polyfill" */ '@formatjs/intl-relativetimeformat/polyfill');
	}
};

const fetchAll = (language = 'en') => {
	fetchNumberFormat(language); // NumberFormat
	fetchPluralRules(language); // PluralRules
	fetchRelativeTimeFormat(language); // RelativeTimeFormat
	fetchDateTimeFormatTimezone(language); // DateTimeFormatTimezone
};

export {
	fetchPluralRules,
	fetchNumberFormat,
	fetchRelativeTimeFormat,
	fetchDateTimeFormatTimezone,
	fetchIntl,
	fetchAll
};

export default fetchAll;
