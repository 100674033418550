/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import type { Tooltip } from 'liana-ui/definitions/component/Types';

type Props = {
	/** ID HTML / CSS attribute */
	id?: string,
	/** HTML attribute for name */
	name?: string,
	/** Value attribute for button */
	value?: string,
	/** Content to place inside .ui.button element */
	children?: React.Node,
	/** Classes for .ui.button element */
	classes?: string,
	/** If buttons should be disabled */
	disabled?: boolean,
	/** If buttons should be a rectangle */
	rectangle?: boolean,
	/** Type of button tag (reset, submit) */
	elem?: string,
	/** Type of HTML tag (a, span, div, button) */
	type?: string,
	/** Tooltip (data-content, data-html, data-position, data-delay, data-hoverable, data-on) */
	tooltip?: Tooltip,
	/** Callback when clicking button */
	onClick?: (event: ?SyntheticEvent<>) => mixed
};

/** COMPONENT BASED ON: https://fomantic-ui.com/elements/button.html */
export default class Button extends React.PureComponent<Props> {
	_button: { current: React.ElementRef<'button'> | null };
	constructor(props: Props) {
		super(props);
		this._button = React.createRef();
	}
	static defaultProps = {
		elem: 'button',
		type: 'button',
		onClick: () => {}
	};
	_isDisabled = () => {
		let isDisabled = String(this.props.classes).indexOf('disabled') > -1;
		if (!isDisabled) {
			/* Remove button :focus style on click from menu buttons. Focus style should only display on keyboard focus */
			if (this._button.current !== null && !this._button.current.querySelector('.menu')) {
				this._button.current.blur();
			}
		}
		return isDisabled;
	};
	_handleClick = (event: SyntheticEvent<HTMLButtonElement>) => {
		if (!this._isDisabled() && typeof this.props.onClick === 'function') {
			this.props.onClick(event);
		}
	};
	get ref() {
		return this._button ? this._button.current : null;
	}
	render() {
		let classes = classNames('ui button', this.props.classes, {
			circular: !this.props.rectangle,
			'popup-open': this.props.tooltip
		});

		let tooltip = {'data-variation': 'tiny', ...this.props.tooltip};

		return (
			// $FlowFixMe Cannot referene type 'this' from a value position?
			<this.props.elem
				id={this.props.id}
				name={this.props.elem === 'button' ? this.props.name : null}
				value={this.props.value}
				type={this.props.elem === 'button' ? this.props.type : null}
				disabled={classes.includes('disabled')}
				className={classes}
				{...tooltip}
				onClick={this._handleClick}
				ref={this._button}
			>
				{this.props.children}
			</this.props.elem>
		);
	}
}
