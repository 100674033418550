/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { Grid } from 'semantic-ui-react';
import GridRow from './GridRow';
import GridColumn from './GridColumn';

// prettier-ignore
type Props = {
	/** Grid content as children. */
	children: React.Node,
	/** A grid column can have the id attribute */
	id?: string,
	/**
		Represents column count per row in Grid.
		VALUES['equal' | 1 - 12]
	*/
	columns?: string | number,
	/** A grid can have its columns stack on-top of each other after reaching mobile (boolean) or tablet ('tablet') breakpoints. */
	stackable?: boolean | 'tablet',
	/** A grid can stretch its contents to take up the entire grid height. */
	stretched?: boolean,
	/** A grid can be forced to take full available space in container. */
	fluid?: boolean,
	/** A grid can have dividers between its columns. Vertical requires Grid.Row for each row. */
	divided?: boolean | 'vertically',
	/** A grid can double its column width on tablet and mobile sizes. */
	doubling?: boolean,
	/** A grid can preserve its vertical and horizontal gutters on first and last columns. */
	padded?: boolean | 'horizontally' | 'vertically',
	/** A grid can increase its gutters to allow for more negative space. */
	relaxed?: boolean | 'very',
	/** A grid can have its columns centered. */
	centered?: boolean,
	/** A grid can appear compact with no or very little padding */
	compact?: boolean | 'very',
	/** A grid can always keep all columns on a single row. */
	singleRow?: boolean,
	/** A grid can specify that its contents should remain on a single line, and not wrap. */
	singleLine?: boolean,
	/** A grid can break very long strings in tight spaces into multiple lines. */
	breakWord?: boolean,
	/** A grid can specify that its columns should reverse order at different device sizes. */
	reversed?: 'mobile' | 'tablet' | 'computer' | 'largescreen' | 'widescreen',
	/** A grid can specify its text alignment. */
	textAlign?: 'left' | 'center' | 'right',
	/** A grid can specify its vertical alignment to have all its columns vertically centered. */
	verticalAlign?: 'bottom' | 'middle' | 'top'
};

const DEFAULTS = {
	stackable: false,
	stretched: false,
	divided: false,
	doubling: false,
	padded: false,
	relaxed: false,
	compact: false,
	singleRow: false,
	singleLine: false,
	breakWord: false
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/collections/grid/ */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	// Assign classes
	const classes = classNames({
		compact: props.compact,
		fluid: props.fluid,
		very: props.compact === 'very',
		'single-row': props.singleRow,
		'break-word': props.breakWord,
		'stackable-tablet': props.stackable === 'tablet'
	});

	let grid = (
		<Grid
			id={props.id}
			className={classes}
			columns={props.columns}
			stackable={props.stackable}
			stretched={props.stretched}
			divided={props.divided}
			doubling={props.doubling}
			padded={props.padded}
			relaxed={props.relaxed}
			centered={props.centered}
			reversed={props.reversed}
			textAlign={props.textAlign}
			verticalAlign={props.verticalAlign}
		>
			{props.children}
		</Grid>
	);

	if (props.singleLine) {
		grid = <div className='break-word-wrap'>{grid}</div>;
	}

	return grid;
});

// Documentation generation support
Component.displayName = 'Grid';
Component.defaultProps = DEFAULTS;

// Attach Subcomponents
Component.Row = GridRow;
Component.Column = GridColumn;

export type { Props };
export default Component;
