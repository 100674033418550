/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { Icon } from 'liana-ui/components';

// prettier-ignore
type Props = {
	/** Main content */
	title: string,
	/** Additional details */
	description?: string,
	/** Result icon */
	icon: string,
	/** Render as link */
	link?: string,
	/** Whether the result should be printed as a showMoreLink */
	showMoreLink?: boolean,
	/** Called on click. */
	onClick?: (
		event: SyntheticEvent<>,
		data: any
	) => void
};

const DEFAULTS = {
	onClick: () => {}
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/search/ */
const Component = (props: Props) => {
	// Assign classes
	const classes = classNames('content', {
		'show-more': props.showMoreLink
	});

	return (
		<div className={classes} onClick={(e) => props.onClick(e, { ...props })}>
			{props.icon && <Icon name={props.icon} color='black' />}
			{props.title && <div className='title'>{props.title}</div>}
			{props.description && <div className='description'>{props.description}</div>}
		</div>
	);
};

// Documentation generation support
Component.displayName = 'SearchResult';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
