/**
 * @prettier
 * @flow
 */

import { useIntl } from 'react-intl';
import { Dropdown } from 'liana-ui/components/';
import type { IntlComponent } from 'react-intl';
import type { Props as PopupProps } from 'liana-ui/components/popup/Popup';

// prettier-ignore
type Props = {
	/** A language dropdown must have an input name */
	name: string,
	/**
		A language dropdown must have standars Liana®Cloud languages as options.
		DATA[json/dropdown/language-dropdown.json]
	*/
	languages: Array<string>,
	/** Initial value or value array if multiple. Use for uncontrolled components only. */
	defaultValue?: string | Array<string>,
	/** Current value or value array if multiple. Use for controlled components only. */
	value?: string | Array<string>,
	/**
		An dropdown can have a placeholder text.
		PROPS[IntlComponent=/localization/]
	*/
	placeholder?: string | IntlComponent,
	/** A language dropdown can allow multiple selections. */
	multiple?: boolean,
	/** A country dropdown can be searchable. */
	search?: boolean,
	/** A country dropdown can be clearable. */
	clearable?: boolean,
	/** A language dropdown can take the full width of its parent. */
	fluid?: boolean,
	/** A language dropdown can be locked to indicate that the field is in use but can not be edited. */
	locked?: boolean,
	/** A language dropdown can be  disabled. */
	disabled?: boolean,
	/** A language dropdown can be different size. */
	size?: 'small' | 'large',
	/** A language dropdown can display all language options in their native language. */
	noTranslation?: boolean,
	/**
		Popup text or, react-intl coomponent or object of properties for Popup component.
		PROPS[IntlComponent=/language/localisation/, PopupProps=/components/modals/popup/]
	*/
	popup?: string | IntlComponent | PopupProps,
	/** Called on input change. */
	onChange?: (
		event: SyntheticEvent<>,
		data: {
			name: string,
			value: string,
			options: Array<string>
		}
	) => void
};

const DEFAULTS = {
	multiple: false,
	noTranslation: false,
	search: true,
	clearable: false,
	locked: false,
	disabled: false,
	fluid: true
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/dropdown/ */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	const intl = useIntl();

	const getOptions = () => {
		let options = [];
		if (props.languages) {
			for (let i = 0; i < props.languages.length; i++) {
				let native = intl.formatMessage({
						id: `language-translations.${props.languages[i]}`
					}),
					translated = intl.formatMessage({
						id: `language.${props.languages[i]}`
					});
				options.push({
					text:
						props.noTranslation && native !== translated
							? `${intl.formatMessage({
									id: `language-translations.${props.languages[i]}`
							  })} (${intl.formatMessage({
									id: `language.${props.languages[i]}`
							  })})`
							: props.noTranslation
							? intl.formatMessage({
									id: `language-translations.${props.languages[i]}`
							  })
							: intl.formatMessage({
									id: `language.${props.languages[i]}`
							  }),
					label: { content: props.languages[i], className: 'lang-label' },
					value: props.languages[i]
				});
			}
		}
		return options;
	};

	let dropdown = (
		<Dropdown
			name={props.name}
			defaultValue={props.defaultValue}
			value={props.value}
			placeholder={props.placeholder}
			options={getOptions()}
			fluid={props.fluid}
			multiple={props.multiple}
			search={props.search}
			clearable={props.clearable}
			locked={props.locked}
			disabled={props.disabled}
			size={props.size}
			popup={props.popup}
			onChange={props.onChange}
		/>
	);

	return dropdown;
});

// Documentation generation support
Component.displayName = 'LanguageDropdown';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
