/**
 * @prettier
 * @flow
 */

import { Modal } from 'liana-ui/components/';
import { type Props as LabelProps } from 'liana-ui/components/Label';

// prettier-ignore
type Props = {
	/** A modal can be open. */
	open?: boolean,
	/** A modal can have a header text. */
	header: string,
	/** A modal must have content as children. */
	content: React.Node,
	/** Clear label props */
	clearLabelProps?: LabelProps,
	/** Modified filters in modal. */
	modifiedFilters?: number,
	/** A modal can be different size. */
	size?: 'mini' | 'tiny' | 'small' | 'large' | 'fullscreen',
	/** Called when all filters are cleared at once. */
	onClear?: (
		event: SyntheticEvent<>
	) => void,
	/** Called on modal close. */
	onClose?: (
		event: SyntheticEvent<>
	) => void
};

const DEFAULTS = {
	visible: false,
	activeFilters: 0
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/modal/ */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	let clearLabel = props.clearLabelProps;

	return (
		<Modal
			open={props.open}
			size={props.size}
			header={props.header}
			headerLabels={clearLabel}
			content={props.content}
			actions={props.actions}
			onClose={props.onClose}
		/>
	);
});

// Documentation generation support
Component.displayName = 'FilterModal';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
