/**
 * @prettier
 * @flow
 */

import { Segment, Statistic, Table } from 'liana-ui/components/';
import type { IntlComponent } from 'react-intl';
import type { Props as ListItemProps } from 'liana-ui/components/';

// prettier-ignore
type Props = {
	/** Main statistic number */
	value?: number,
	/** Label for main statistic number. */
	label?: string | IntlComponent,
	/** Notation of main statistic number. */
	notation?: 'compact' | 'percentage',
	/** Array of prop objects for ListItem. */
	items: Array<ListItemProps>,
	/** Animation duration in seconds. */
	duration?: number
};

const DEFAULTS = {
	items: []
};

const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	return (
		<Segment removeMargins='all'>
			<div className='chart-info'>
				{props.value !== undefined ? (
					<Statistic
						fluid
						value={props.value}
						label={props.label}
						notation={props.notation}
						duration={props.duration}
					/>
				) : null}
				<Table
					size='small'
					padded
					basic
					fitted
					definition
					bodyRows={props.items.map((item) => ({
						cells: [
							{
								content: item.text
							},
							{
								textAlign: 'right',
								verticalAlign: 'top',
								collapsing: true,
								content: <Statistic.Trend {...item} />
							}
						]
					}))}
				/>
			</div>
		</Segment>
	);
});

// Documentation generation support
Component.displayName = 'ChartInfo';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
