/**
 * @prettier
 * @flow
 */

let startY, startX, scrollLeft, scrollTop, isDown;
export default class TableUtils {
	constructor() {}

	static mouseIsDown = (e, container) => {
		isDown = true;
		startY = e.pageY - container.offsetTop;
		startX = e.pageX - container.offsetLeft;
		scrollLeft = container.scrollLeft;
		scrollTop = container.scrollTop;
	};

	static mouseUp = (e) => {
		isDown = false;
	};

	static mouseLeave = (e) => {
		isDown = false;
	};

	static mouseMove = (e, container) => {
		if (isDown) {
			e.preventDefault();
			//Move vertcally
			const y = e.pageY - container.offsetTop;
			const walkY = y - startY;
			container.scrollTop = scrollTop - walkY;

			//Move Horizontally
			const x = e.pageX - container.offsetLeft;
			const walkX = x - startX;
			container.scrollLeft = scrollLeft - walkX;
		}
	};

	static enableHorizontalDrag = (container) => {
		container.addEventListener('mousedown', (e) => this.mouseIsDown(e, container));
		container.addEventListener('mouseup', (e) => this.mouseUp(e));
		container.addEventListener('mouseleave', (e) => this.mouseLeave(e));
		container.addEventListener('mousemove', (e) => this.mouseMove(e, container));
	};

	static disableHorizontalDrag = (container) => {
		container.removeEventListener('mousedown', (e) => this.mouseIsDown(e, container));
		container.removeEventListener('mouseup', (e) => this.mouseUp(e));
		container.removeEventListener('mouseleave', (e) => this.mouseLeave(e));
		container.removeEventListener('mousemove', (e) => this.mouseMove(e, container));
	};
}
