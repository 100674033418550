/**
 * @prettier
 * @flow
 */

import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { Label } from 'semantic-ui-react';
import { BrowserUtils } from 'liana-ui/definitions/';
import { Icon, Image, Flag, Popup, Responsive, Transition } from 'liana-ui/components/';
import LabelDetail from './src/LabelDetail';
import LabelGroup from './LabelGroup';
import LimitLabel from './LimitLabel';
import type { IntlComponent } from 'react-intl';
import type { Props as PopupProps } from 'liana-ui/components/popup/Popup';
import type { Props as IconProps } from 'liana-ui/components/icon/Icon';
import type { Props as ImageProps } from 'liana-ui/components/image/Image';

// prettier-ignore
type Props = {
	/**
		A label must have text as tring or react-intl component.
		PROPS[IntlComponent=/localization/]
	*/
	text: string | IntlComponent,
	/** An element type to render as. */
	as: 'span' | 'div' | 'a',
	/** A label can have name. */
	name?: string,
	/**
		A label can have detail text.
		PROPS[IntlComponent=/language/localisation/, PopupProps=/components/modals/popup/, IconProps=/components/labels/icons/icon/]
	*/
	detail?:
		| string
		| IntlComponent
		| {
				content: string | IntlComponent,
				icon: string | IconType,
				popup: string | IntlComponent | PopupProps,
				onClick: (event) => {
					event: SyntheticEvent
				}
		  },
	/** A label can be circular. */
	circular?: boolean,
	/** A label can be an empty square or circular dot. */
	empty?: boolean,
	/**
		A label can have an icon.
		PROPS[IconProps=/components/labels/icons/icon/]
	*/
	icon?: string | IconProps,
	/**
		A label can have an image.
		PROPS[ImageProps=/components/texts/image/]
	*/
	image?: string | ImageProps,
	/** A label can have a flag */
	flag?: string,
	/** A label can have a small favicon. Requires image property. */
	favicon?: boolean,
	/** A label can have fixed width with two letter language label. */
	langCode?: boolean,
	/** A label can have a unique color for notifications. */
	notification?: boolean,
	/** A label can be formatted to display a positive or negative rating.  */
	rating?: 'positive' | 'negative',
	/** A label can be formatted to display a success, warning or error message.  */
	message?: 'info' | 'success' | 'warning' | 'error',
	/** A label can point to content next to it. */
	pointing?: boolean | 'above' | 'below' | 'left' | 'right',
	/** A label can blink to require more attension. */
	blinking?: boolean,
	/**
		A label can have different color or hex color.
		VALUES['red', 'orange', 'yellow', 'green', 'blue', '#FF0000', '#...']
	*/
	color?: string,
	/** A label can reduce its complexity by looking more like basic text. */
	textual?: boolean,
	/** A label can float to left or right. */
	floated?: 'left' | 'right',
	/** A label can have no margins. */
	fitted?: boolean,
	/** A label can always keep all content on a single line. */
	singleLine?: boolean,
	/** A label appear to be a basic link. */
	link?: boolean,
	/** A label can be disabled */
	disabled: boolean,
	/** A label can be visible */
	visible: boolean,
	/** A label can have different sizes. */
	size?: 'mini' | 'tiny' | 'small',
	/** Smallest device that component will be displayed with. */
	minDevice?: 'mobile' | 'tablet' | 'computer' | 'largescreen' | 'widescreen',
	/** Largest device that component will be displayed with. */
	maxDevice?: 'mobile' | 'tablet' | 'computer' | 'largescreen' | 'widescreen',
	/** Hide content on touch devices */
	hideTouch?: boolean,
	/**
		Popup text or, react-intl coomponent or object of properties for Popup component.
		PROPS[IntlComponent=/language/localisation/, PopupProps=/components/modals/popup/]
	*/
	popup?: string | IntlComponent | PopupProps,
	/** Stop click events from bubbling to its parent */
	stopPropagation?: boolean,
	/** Function called on label click. */
	onClick?: (
		event: SyntheticEvent<>,
		data: {
			name: string
		}
	) => void
};

const DEFAULTS = {
	as: 'div',
	visible: true,
	favicon: false,
	langCode: false,
	empty: false,
	circular: false,
	notification: false,
	rating: false,
	fitted: false,
	singleLine: false,
	textual: false,
	message: false,
	blinking: false,
	disabled: false,
	size: 'small'
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/label */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	const intl = useIntl();

	// Function to generate LianaUI Button
	const createLabel = (props: Props) => {
		// Called on checkbox click.
		const handleClick = (event: ?SyntheticEvent<>, data: any) => {
			if (props.stopPropagation && typeof event.stopPropagation === 'function') {
				event.stopPropagation();
				event.preventDefault();
			}
			if (typeof props.onClick === 'function') {
				props.onClick(event, handleCallbackData(data));
			}
		};

		// Handle data returned by callbacks.
		const handleCallbackData = () => {
			return {
				name: props.name
			};
		};

		// Assign classes
		let classes = classNames(
			{
				image: props.image,
				'favicon-label': props.favicon,
				'lang-label': props.langCode,
				'link-label': props.link,
				fitted: props.fitted,
				nowrap: props.singleLine,
				info: props.message === 'info',
				success: props.message === 'success' || props.rating === 'positive',
				warning: props.message === 'warning',
				error: props.message === 'error' || props.rating === 'negative',
				outlined: BrowserUtils.isLowContrast(props.color, 242) || props.color === 'white',
				textual: props.textual,
				disabled: props.disabled,
				blinking: props.blinking,
				primary: props.color === 'primary'
			},
			props.floated ? `float-${props.floated}` : false
		);

		// Assign color
		let color, style;
		if (typeof props.color === 'string') {
			if (props.color[0] === '#') {
				style = {
					background: props.color,
					color: BrowserUtils.isLowContrast(props.color, 180) ? '#000' : '#fff'
				};
				delete props.color;
			} else {
				color = props.color && props.color !== 'primary' ? props.color : undefined;
			}
		}

		// Notification color
		if (props.notification) {
			color = 'purple';
		}

		// Label icons
		switch (true) {
			case props.rating === 'positive':
				// Positive rating message
				props.icon = Object.assign({
					name: typeof props.icon === 'string' ? props.icon : 'fa-smile',
					color: 'green'
				});
				break;
			case props.rating === 'negative':
				// Negative rating message
				props.icon = Object.assign({
					name: typeof props.icon === 'string' ? props.icon : 'fa-frown',
					color: 'red'
				});
				break;
			case props.message === 'info':
				// Success message
				props.icon = Object.assign({
					name: typeof props.icon === 'string' ? props.icon : 'fa-info-circle',
					color: 'purple'
				});
				break;
			case props.message === 'success':
				// Success message
				props.icon = Object.assign({
					name: typeof props.icon === 'string' ? props.icon : 'fa-check',
					color: 'green'
				});
				break;
			case props.message === 'warning':
				// Warning message
				props.icon = Object.assign({
					name: typeof props.icon === 'string' ? props.icon : 'fa-circle-exclamation',
					color: 'yellow'
				});
				break;
			case props.message === 'error':
				// Error message
				props.icon = Object.assign({
					name: typeof props.icon === 'string' ? props.icon : 'fa-triangle-exclamation',
					color: 'red'
				});
				break;
			default:
				// Assign Icon props
				if (typeof props.icon === 'string') {
					props.icon = Object.assign({
						name: props.icon
					});
				}
		}

		let content = (
			<>
				{props.icon ? <Icon {...props.icon} /> : null}
				{props.flag ? <Flag name={props.flag} /> : null}
				{props.favicon && !props.image ? <Icon name='globe' size={props.size} /> : null}
				{props.image ? (
					typeof props.image === 'string' ? (
						<Image src={props.image} size={props.favicon ? 'favicon' : null} />
					) : (
						<Image {...props.image} size={props.favicon ? 'favicon' : null} />
					)
				) : null}
				{props.text && typeof props.text === 'string' ? intl.formatMessage({ id: props.text }) : props.text}
				{props.detail ? (
					typeof props.detail === 'string' || React.isValidElement(props.detail) ? (
						<LabelDetail content={props.detail} />
					) : (
						<LabelDetail {...props.detail} />
					)
				) : null}
			</>
		);

		let label = (
			<Label
				as={props.onClick || props.link ? 'a' : props.as}
				name={props.name}
				className={classes}
				color={color}
				style={style}
				circular={props.circular || props.notification}
				empty={props.empty}
				pointing={props.pointing}
				size={props.size}
				onClick={handleClick}
			>
				{content}
			</Label>
		);

		// Attach popup
		label = Popup.attach(props.popup, label);

		// Define transition
		label = (
			<Transition visible={props.visible} animation='scale' duration={250}>
				{label}
			</Transition>
		);

		return label;
	};

	// Display reponsively
	let component =
		props.minDevice || props.maxDevice || props.hideTouch ? (
			<Responsive {...props}>{createLabel(props)}</Responsive>
		) : (
			createLabel(props)
		);

	return component;
});

// Documentation generation support
Component.displayName = 'Label';
Component.defaultProps = DEFAULTS;

// Attach Subcomponents
Component.Group = LabelGroup;
Component.Limit = LimitLabel;

export type { Props };
export default Component;
