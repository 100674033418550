/**
 * @prettier
 * @flow
 */

import { Segment, Card, Grid } from 'liana-ui/components/';
import type { Props as CardProps } from 'liana-ui/components/card/Card';

// prettier-ignore
type Props = {
	/** A card group must have card items. */
	items: Array<CardProps>,
	/** Mapped over any data provided for items property. Should return Card object for items. */
	renderItem?: (
		data,
		index
	) => {
		header: string,
		description: string,
		link: string,
		image: string,
		subinfo: string | React.Node | Array<React.Node>,
		...
	},
	/** A card group can have different layouts. */
	layout?: 'small' | 'big',
		/** 
	  	A card can limit header to maximum amount of lines with ellipsis. 
	 	VALUES[1 - 10]
	*/
	limitHeader?: number,
	/** 
	    A card can limit description to maximum amount of lines with ellipsis. 
		VALUES[1 - 10]			
	*/
	limitDescription?: number,
	/**
		Represents column count per row in card Grid.
		VALUES['equal' | 1 - 12]
	*/
	columns?: string | number,
	/** A card group can have its columns stack on-top of each other after reaching mobile (boolean) or tablet ('tablet') breakpoints. */
	stackable?: boolean | 'tablet',
	/**
		A column can specify a width for a mobile device.
		VALUES[1 - 12]
	*/
	mobile?: number,
	/**
		A column can specify a width for a tablet device.
		VALUES[1 - 12]
	*/
	tablet?: number,
	/**
		A column can specify a width for a computer.
		VALUES[1 - 12]
	*/
	computer?: number,
	/**
		A column can specify a width for a large screen device.
		VALUES[1 - 12]
	*/
	largescreen?: number,
	/**
		A column can specify a width for a wide screen device.
		VALUES[1 - 12]
	*/
	widescreen?: number,
	/** Function called on item select. */
	onSelect?: (
		event: SyntheticEvent<>,
		data: {
			selected: boolean
		}
	) => void,
};

const DEFAULTS = {
	columns: 4,
	layout: 'big',
	stackable: true
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/views/card/#types-groups */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	// Factory function for transforming data
	let items = [];
	if (props.items && props.items.length > 0) {
		if (typeof props.renderItem === 'function') {
			for (let i = 0; i < props.items.length; i++) {
				items[i] = props.renderItem(props.items[i], i);
			}
		} else {
			items = props.items;
		}
	}

	// Define CardGroup
	let cardGroup = (
		<>
			<Segment basic removePaddings='all'>
				<Grid
					stretched
					doubling={
						!props.mobile && !props.tablet && !props.computer && !props.largescreen && !props.widescreen
					}
					stackable={
						!props.mobile && !props.tablet && !props.computer && !props.largescreen && !props.widescreen
					}
					columns={props.columns}
				>
					{items.map((item: CardProps, index: number) => {
						return (
							<Grid.Column
								mobile={props.mobile}
								tablet={props.tablet}
								computer={props.computer}
								largescreen={props.largescreen}
								widescreen={props.widescreen}
								key={`cardcolumn-${index}`}
							>
								<Card
									fluid
									id={item.id}
									layout={props.layout}
									limitHeader={props.limitHeader}
									limitDescription={props.limitDescription}
									onSelect={props.onSelect}
									key={`card-${index}`}
									{...item}
								/>
							</Grid.Column>
						);
					})}
				</Grid>
			</Segment>
		</>
	);

	return cardGroup;
});

// Documentation generation support
Component.displayName = 'CardGroup';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
