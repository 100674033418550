/**
 * @prettier
 * @flow
 */

import { useEffect, useRef } from 'react';

// prettier-ignore
type Props = {
	/** Content to place inside .grid-stack-item element */
	children: React.Node,
	/** ID of dashboard item */
	id: number | string,
	/** Y position of item in grid */
	x: number | string,
	/** Y position of item in grid */
	y: number | string,
	/** Width of item in grid */
	width: number | string,
	/** Height of item in grid */
	height: number | string,
	/** Widgets max width */
	maxWidth: number | string,
	/** Widgets max height */
	maxHeight: number | string,
	/** Widgets min width */
	minWidth: number | string,
	/** Widgets min height */
	minHeight: number | string
};

/** COMPONENT BASED ON: http://gridstackjs.com/ */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	const ref = useRef();

	useEffect(() => {
		// GridStack instance
		const grid = document.querySelector('.grid-stack') && document.querySelector('.grid-stack').gridstack;
		if (grid) grid.makeWidget(ref.current);
	}, []);

	return (
		<div
			ref={ref}
			className='grid-stack-item widget'
			data-gs-id={props.id}
			data-gs-x={props.x}
			data-gs-y={props.y}
			data-gs-width={props.width}
			data-gs-height={props.height}
			data-gs-auto-position={!props.x && !props.y}
			data-gs-max-width={props.maxWidth}
			data-gs-max-height={props.maxHeight}
			data-gs-min-width={props.minWidth}
			data-gs-min-height={props.minHeight}
		>
			{props.children}
		</div>
	);
});

Component.displayName = 'DashboardItem';
Component.defaultProps = {
	width: 1,
	height: 1
};

export default Component;
