/**
 * @prettier
 * @flow
 */

import { Dropdown } from 'liana-ui/components/';
import type { IntlComponent } from 'react-intl';
import type { Props as PopupProps } from 'liana-ui/components/popup/Popup';
import { Text } from 'liana-ui/components/';

// prettier-ignore
type Props = {
	/** A timezone dropdown must have an input name */
	name: string,
	/**
		A timezone dropdown must have standars Liana®Cloud languages as options.
		DATA[json/timezones/timezones.json]
	*/
	timezones: Array<{
		name: string,
		offset: number,
		text: string
	}>,
	/** Initial value or value array if multiple. Use for uncontrolled components only. */
	defaultValue?: string | Array<string>,
	/** Current value or value array if multiple. Use for controlled components only. */
	value?: string | Array<string>,
	/**
		An timezone dropdown can have a placeholder text.
		PROPS[IntlComponent=/localization/]
	*/
	placeholder?: string | IntlComponent,
	/** A timezone dropdown can allow multiple selections. */
	multiple?: boolean,
	/** A timezone dropdown can be searchable. */
	search?: boolean,
	/** A tiimezone dropdown can be clearable. */
	clearable?: boolean,
	/** A timezone dropdown can take the full width of its parent. */
	fluid?: boolean,
	/** A timezone dropdown can be locked to indicate that the field is in use but can not be edited. */
	locked?: boolean,
	/** A timezone dropdown can be  disabled. */
	disabled?: boolean,
	/** A timezone dropdown can be different size. */
	size?: 'small' | 'large',
	/**
		Popup text or, react-intl coomponent or object of properties for Popup component.
		PROPS[IntlComponent=/language/localisation/, PopupProps=/components/modals/popup/]
	*/
	popup?: string | IntlComponent | PopupProps,
	/** Function called on input change. */
	onChange?: (
		event: SyntheticEvent<>,
		data: {
			name: string,
			value: string,
			options: Array<string>
		}
	) => void
};

const DEFAULTS = {
	multiple: false,
	search: true,
	clearable: false,
	locked: false,
	disabled: false,
	fluid: true
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/dropdown/ */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	const getOptions = () => {
		let options = [];
		if (props.timezones) {
			props.timezones.map((option) => {
				let text = option.text;
				options.push({
					text: `${text.substr(0, text.indexOf(' '))} ${text.substr(text.indexOf(' ') + 1)}`,
					value: option.name
				});
			});
		}
		return options;
	};

	let dropdown = (
		<Dropdown
			name={props.name}
			defaultValue={props.defaultValue}
			value={props.value}
			placeholder={props.placeholder}
			options={getOptions()}
			fluid={props.fluid}
			multiple={props.multiple}
			search={props.search}
			clearable={props.clearable}
			locked={props.locked}
			disabled={props.disabled}
			size={props.size}
			popup={props.popup}
			onChange={props.onChange}
		/>
	);

	return dropdown;
});

// Documentation generation support
Component.displayName = 'TimezoneDropdown';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
