export default {
	/*
		Get current time of user in users timeone with provided offset in seconds:
		----------------------------------------------------------------------
		Usage examples if current time is 2018-01-01 00:00:00:
		Time.get(this.context.user.get('timezone_offset'), 'now'); = 2018-01-01 00:00:00
		Time.get(this.context.user.get('timezone_offset'), 'now', 'date'); = 2018-01-01
		Time.get(this.context.user.get('timezone_offset'), 'now', 'time'); = 00:00:00
		Time.get(this.context.user.get('timezone_offset'), 'now', 'isoUTC'); = 2018-01-01T00:00:00Z
		Time.get(this.context.user.get('timezone_offset'), '-1d'); = 2017-12-31 00:00:00
		Time.get(this.context.user.get('timezone_offset'), '+1d'); = 2010-01-02 00:00:00
		Time.get(this.context.user.get('timezone_offset'), '-1w'); = 2018-12-25 00:00:00
		Time.get(this.context.user.get('timezone_offset'), '-1m'); = 2018-11-30 00:00:00
		Time.get(this.context.user.get('timezone_offset'), '-1y'); = 2017-01-01 00:00:00
		Time.get(this.context.user.get('timezone_offset'), '-1d', 'date'); = 2017-12-31
		Time.get(this.context.user.get('timezone_offset'), '-1d', 'time'); = 00:00:00
		Time.get(this.context.user.get('timezone_offset'), '-1d', 'isoUTC'); = 2017-12-31T00:00:00Z
	*/
	get: (offset, range, format) => {

		// if no offset is provided from user contect as parameter fallback to operating system offset
		if (isNaN(offset) || offset === null) {
			offset = new Date();
			offset = -offset.getTimezoneOffset() * 60;
		}

		let date = new Date();
		offset = offset / 3600;
		date.setHours(date.getHours() + Number(offset));

		let sign = range.charAt(0),
			amount = Number(range.substr(1).slice(0, -1)),
			unit = range.slice(-1),
			allowedSigns = ['-', '+'],
			allowedUnits = ['h', 'd', 'w', 'm', 'y'];

		// If range is 0 it means now
		if (range === 0) {range = 'now';}

		if ((range === 'now') || (allowedSigns.indexOf(sign) > -1 && Number.isInteger(amount) && allowedUnits.indexOf(unit) > -1)) {

			if (range !== 'now') {
				let hour = Number(date.getHours()),
					day = Number(date.getDate()),
					year = Number(date.getFullYear());

				switch (unit) {
					case 'h':
						date.setHours(sign === '-' ? (hour - amount) : (hour + amount));
						break;
					case 'd':
						date.setDate(sign === '-' ? (day - amount) : (day + amount));
						break;
					case 'w':
						date.setDate(sign === '-' ? (day - (amount * 7)) : (day + (amount * 7)));
						break;
					case 'm':
						date.setDate(sign === '-' ? (day - (amount * 30)) : (day + (amount * 30)));
						break;
					case 'y':
						date.setFullYear(sign === '-' ? (year - amount) : (year + amount));
						break;
				}
			}

			switch (format) {
				case 'date':
					date = String(date.toISOString()).replace('T', ' ').replace('Z', '').split('.')[0];
					date = date.split(' ')[0];
					break;
				case 'time':
					date = String(date.toISOString()).replace('T', ' ').replace('Z', '').split('.')[0];
					date = date.split(' ')[1];
					break;
				case 'isoUTC':
					date = String(date.toISOString()).split('.')[0] + 'Z';
					break;
				default:
					date = String(date.toISOString()).replace('T', ' ').replace('Z', '').split('.')[0];
			}
		} else {
			date = 'Invalid range';
		}

		return date;
	}
};
