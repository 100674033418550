/**
 * @prettier
 * @flow
 */

import { injectIntl, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import Icon from 'liana-ui/legacy/components/icon/Icon';

type Props = {
	intl: Intl,
	isEnabled: boolean,
	onOpenUpload: () => mixed,
	onRemoveImage: () => mixed
};

class ImageEditorTopActions extends React.PureComponent<Props> {
	constructor(props: Props) {
		super(props);
	}
	static defaultProps = {
		isEnabled: false,
		onOpenUpload: () => {},
		onRemoveImage: () => {}
	};

	render() {
		let classes = classNames({
			'disabled off text-gray': this.props.isEnabled ? false : true,
			'text-decoration-none': true
		});
		return (
			<div className='top-actions'>
				<div className='ui horizontal divided list float-right float-left-on-mobile'>
					<div
						className='item popup-open'
						data-content={this.props.intl.formatMessage({
							id: 'component.image-editor.general.browseFile'
						})}
					>
						<a className={classes} onClick={this.props.onOpenUpload}>
							<Icon classes='folder open' />
							<FormattedMessage id='component.image-editor.general.browseFile' />
						</a>
					</div>
					<div
						className='item popup-open'
						data-content={this.props.intl.formatMessage({
							id: 'component.image-editor.general.removeImage'
						})}
					>
						<a className={classes} onClick={this.props.onRemoveImage}>
							<Icon classes='trash alternate' />
							<FormattedMessage id='component.image-editor.general.removeImage' />
						</a>
					</div>
				</div>
			</div>
		);
	}
}

let Wrapper = injectIntl(ImageEditorTopActions, { forwardRef: true });
Wrapper.displayName = 'ImageEditorTopActions';
export default Wrapper;
