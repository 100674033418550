/**
 * @prettier
 */

import { useEffect, useContext, useState, useRef, useCallback } from 'react';
import TrailContext from 'liana-ui/components/trail/src/TrailContext';
import PageHeaderContext from 'liana-ui/components/header/src/PageHeaderContext';

// https://stackoverflow.com/questions/46240647/react-how-to-force-a-function-component-to-render
const useForceUpdate = () => {
	let [value, setValue] = useState(0); // Integer state
	let rng = () => (value = Math.floor(Math.random() * Math.floor(10000))); // Random value
	return () => setValue(rng); // Update the state to force render
};

// https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
const usePrevious = (value) => {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
};

// https://reactjs.org/docs/hooks-custom.html
// Note: This can be done entirely via React context
const useTrail = (sections, tools, params = [], clear = false) => {
	let trailCtx = useContext(TrailContext);
	sections = Array.isArray(sections) ? sections : null;
	tools = Array.isArray(tools) ? tools : null;
	useEffect(() => {
		if (sections !== null || tools !== null) {
			trailCtx.setTrail(sections, tools);
		}
		if (clear === true) {
			return trailCtx.clear;
		}
	}, params);
};

// https://reactjs.org/docs/hooks-custom.html
// Note: This can be done entirely via React context
const usePageHeader = (props, params = [], clear = false) => {
	let PageHeaderCtx = useContext(PageHeaderContext);
	useEffect(() => {
		PageHeaderCtx.setPageHeader({ ...props });
		return () => {
			if (clear === true) {
				PageHeaderCtx.clearPageHeader();
			}
		};
	}, params);
};

export { usePrevious, useForceUpdate, useTrail, usePageHeader };
