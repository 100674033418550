/**
 * @prettier
 * @flow
 */

import { useEffect, useState } from 'react';
import { GridStack } from 'liana-ui/lib/gridstack';
import { DashboardItem } from 'liana-ui/components';

// prettier-ignore
type Props = {
	/** Widgets to render */
	widgets: Array<DashboardItem>,
	/** Turns on animation */
	animate?: boolean,
	/** Dashboards min width */
	minWidth?: number | string,
	/** Modals to be injected with dashboard functions */
	modals?: Array<Modal> | React.Node,
	/** Display dashboard in public mode. Disables for example all editing. */
	publicMode?: boolean,
	/** Widget on add callback */
	onAdd: () => mixed,
	/** Dashboard on change callback */
	onChange: () => mixed,
	/** Widget on delete callback */
	onDelete: () => mixed
};

/** COMPONENT BASED ON: https://github.com/gridstack/gridstack.js/tree/develop/doc */
const Component: React.AbstractComponent = React.memo<>((props: Props) => {
	const [gs, setGridStack] = useState(null);

	const getScreenSizes = () => ({
		isMobile: document.querySelector('html').classList.contains('mobile'),
		isTablet: document.querySelector('html').classList.contains('tablet'),
		isComputer: document.querySelector('html').classList.contains('computer'),
		isLargescreen: document.querySelector('html').classList.contains('largescreen'),
		isWidescreen: document.querySelector('html').classList.contains('widescreen')
	});

	const getColumns = () => {
		const { isMobile, isTablet, isComputer } = getScreenSizes();
		switch (true) {
			case isMobile:
				return 1;
			case isTablet || isComputer:
				return 2;
			default:
				return 3;
		}
	};

	const getHeight = () => {
		const { isWidescreen, isLargescreen, isComputer, isTablet, isMobile } = getScreenSizes();
		switch (true) {
			case isWidescreen:
				return 450;
			case isLargescreen:
				return 425;
			case isComputer:
				return 400;
			case isTablet:
				return 440;
			case isMobile:
				return 440;
			default:
				return 480;
		}
	};

	useEffect(() => {
		const pointerOnly = document.querySelector('html').classList.contains('pointer-device');
		const initHeight = getHeight();
		const initCols = getColumns();
		const gridstack = GridStack.init({
			animate: props.animate,
			cellHeight: initHeight,
			minWidth: 667,
			margin: 13,
			disableDrag: !pointerOnly || props.publicMode,
			disableResize: !pointerOnly || props.publicMode
		});

		gridstack.column(initCols);
		setGridStack(gridstack);

		const serializeItems = (items: Array<any>) =>
			Array.isArray(items)
				? items.map((item) => ({
						id: item.id,
						x: item.x,
						y: item.y,
						width: item.width,
						height: item.height
				  }))
				: false;

		gridstack.on('change', (_, items) => {
			// We dont want onChange to run on form changes
			if (typeof props.onChange === 'function' && items) {
				props.onChange(
					serializeItems(items),
					gridstack
						.getGridItems()
						.map((item) => ({
							id: item.dataset.gsId,
							x: parseInt(item.dataset.gsX, 10),
							y: parseInt(item.dataset.gsY, 10),
							width: parseInt(item.dataset.gsWidth, 10),
							height: parseInt(item.dataset.gsHeight, 10)
						}))
						.sort((a, b) => a.y - b.y || a.x - b.x)
				);
			}
		});

		gridstack.on('added', (_, items) => {
			if (typeof props.onAdd === 'function') {
				props.onAdd(serializeItems(items));
			}
		});

		gridstack.on('removed', (_, items) => {
			if (typeof props.onDelete === 'function') {
				props.onDelete(serializeItems(items));
			}
		});

		const handleWindowResize = () => {
			const cols = getColumns();
			if (!gridstack.el.classList.contains(`grid-stack-${cols}`)) {
				gridstack.column(cols);
			}
		};

		window.addEventListener('resize', handleWindowResize);

		return () => {
			gridstack.off('removed'); // Prevent deleting all items in unmount
			gridstack.off('change');
			window.removeEventListener('resize', handleWindowResize);
			gridstack.destroy(false);
			setGridStack(null);
		};
	}, []);

	return (
		<div className='dashboard grid-stack-wrapper'>
			<div className='grid-stack'>
				{gs &&
					props.widgets &&
					props.widgets.map((widget) => (
						<DashboardItem key={`widget-${widget.props.id}`} {...widget.props}>
							{widget}
						</DashboardItem>
					))}
			</div>
		</div>
	);
});

Component.defaultProps = {
	animate: true,
	minWidth: 667
};

Component.displayName = 'Dashboard';

export default Component;
