/**
 * @prettier
 * @flow
 */

type Colors = {
	colors: Array<string>
};

export default class ColorUtils {
	_colors: Colors;
	constructor(theme: string | object) {
		if (typeof theme === 'string') {
			this._colors = require('../colors/' + theme + '.json');
		} else if (typeof theme === 'object') {
			this._colors = theme;
		}
	}
	static hexToRGB = (hex: string, alpha: number) => {
		let r = parseInt(hex.slice(1, 3), 16),
			g = parseInt(hex.slice(3, 5), 16),
			b = parseInt(hex.slice(5, 7), 16);
		if (alpha) {
			return `rgba(${r}, ${g}, ${b}, ${alpha})`;
		} else {
			return `rgba(${r}, ${g}, ${b})`;
		}
	};
	_lightenColor = (color: string, percent: number) => {
		/* eslint-disable */
		var num = parseInt(color.slice(1), 16),
			amt = Math.round(2.55 * percent),
			R = (num >> 16) + amt,
			G = ((num >> 8) & 0x00ff) + amt,
			B = (num & 0x0000ff) + amt;
		/* eslint-enable */
		return (
			'#' +
			(
				0x1000000 +
				(R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
				(G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
				(B < 255 ? (B < 1 ? 0 : B) : 255)
			)
				.toString(16)
				.slice(1)
		);
	};
	get colors() {
		return this._colors;
	}
}
