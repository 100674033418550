/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { Divider } from 'semantic-ui-react';
import { Icon } from 'liana-ui/components/';
import type { Props as PopupProps } from 'liana-ui/components/popup/Popup';

// prettier-ignore
type Props = {
	/** Element to render as */
	as?: React.Node | string,
	/** Divider text */
	text?: string | IntlShape,
	/** Align divider can be stronger by being darker. */
	strong?: 'left',
	/** Align divider text. */
	textAlign?: 'left',
	/** Add an Icon by name. */
	icon?: string,
	/** Divider can segment content vertically. */
	vertical?: boolean,
	/** Divider can have a help popup. */
	help?: string | FormattedMessage | PopupProps,
	/** Remove margins around the divider. */
	removeMargins?: 'all' | 'top' | 'bottom'
};

const DEFAULTS = {
	textAlign: 'center',
	vertical: false
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/divider */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	// Assign classes
	let classes = classNames({
		strong: props.strong,
		'no-text': !props.text,
		'left aligned': props.textAlign === 'left',
		'remove-margins': props.removeMargins === 'all',
		'remove-top-margin': props.removeMargins === 'top',
		'remove-bottom-margin': props.removeMargins === 'bottom'
	});

	let divider = (
		<Divider
			as={props.as}
			className={classes}
			vertical={props.vertical}
			horizontal={Boolean(!props.vertical && props.text)}
		>
			{props.icon ? <Icon name={props.icon} /> : null}
			<span className='text'>{props.text}</span>
			{props.help ? <Icon name='fa-question-circle cursor-help' color='primary' popup={props.help} /> : null}
		</Divider>
	);
	return divider;
});

// Documentation generation support
Component.displayName = 'Divider';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
