/* istanbul ignore file */
/**
 * @prettier
 */
import { useState } from 'react';
const PageHeaderContext = React.createContext(); // DEFAULT

type Props = {
	children: React.Node
};

const PageHeaderContextProvider = (props: Props) => {

	let [properties, setProperties] = useState();

	const setPageHeader = (props) => setProperties({ 
		breadcrumb: props?.breadcrumb,
		header: props?.header,
		actionHeader: props?.actionHeader 
	});

	const updatePageHeader = (next = {}) =>
		setProperties((prev = {}) => {
			let updatedHeader = Object.assign({}, prev);

			for (let x of ['breadcrumb', 'header', 'actionHeader']) {
				if (next[x]) {
					updatedHeader[x] = next[x];
				}
			}

			return updatedHeader;
		});

	const clearPageHeader = () => setProperties({});

	return (
		<PageHeaderContext.Provider
			value={{ 
				breadcrumb: properties?.breadcrumb,
				header: properties?.header,
				actionHeader: properties?.actionHeader,   
				setPageHeader, 
				updatePageHeader, 
				clearPageHeader 
			}}
		>
			{props.children}
		</PageHeaderContext.Provider>
	);
};

const PageHeaderContextConsumer = PageHeaderContext.Consumer;

export { PageHeaderContextProvider, PageHeaderContextConsumer };

export default PageHeaderContext;
