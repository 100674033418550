/**
 * @prettier
 * @flow
 */

import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Popup } from 'semantic-ui-react';
import { Button, Menu } from 'liana-ui/components/';
import type { IntlComponent } from 'react-intl';
import type { Props as IconProps } from 'liana-ui/components/icon/Icon';
import type { Props as CheckboxProps } from 'liana-ui/components/checkbox/Checkbox';

// prettier-ignore
type Props = {
	/**
		A context menu must have options.
		PROPS[IntlComponent=/language/localisation/, CheckboxProps=/components/forms/checkbox/, IconProps=/components/labels/icons/icon/]
	*/
	options: Array<{
		text: string | IntlComponent,
		value: string,
		icon: string | IconProps | Array<string>,
		icons: Array<string> | Array<IconProps>,
		divider: boolean,
		checkbox: CheckboxProps,
		link: string,
		disabled: boolean,
		onClick: (
			event: SyntheticEvent<>,
			data: any
		) => void
	}>,
	/**
		A context menu button can have different icon.
		PROPS[IconProps=/components/labels/icons/icon/]
	*/
	icon?: string | IconProps,
	/**
		A context menu button can have different icon group.
		PROPS[IconGroupProps=/components/labels/icons/icon-group/]
	*/
	iconGroup?: Array<IconProps>,
		/**
		A context menu button can have text.
		PROPS[IntlComponent=/localization/]
	*/
	text?: string | IntlComponent,
	/** A context menu button can have primary color. */
	color?: 'primary',
	/**  A context menu button can be aligned to the left or right of its container. */
	floated?: 'left' | 'right',
	/** A context menu button can have no empty space around it. */
	fitted?: boolean,
	/** A context menu button can be pronounced by having no borders. */
	basic?: boolean,
	/** A context menu button can show it is currently unnecessary to be interacted with. */
	off?: boolean,
	/** A context menu button can show it is currently unable to be interacted with. */
	disabled?: boolean,
	/** A content menu can stay open on menu item clicks to allow selecting multiple items. */
	keepOpen?: boolean,
	/** A content menu can be forced to open to the left or to the right. */
	direction?: 'left' | 'right',
	/** A button can have different sizes. */
	size?: 'small' | 'tiny' | 'mini',
	/** Smallest device that component will be displayed with. */
	minDevice?: 'mobile' | 'tablet' | 'computer' | 'largescreen' | 'widescreen',
	/** Largest device that component will be displayed with. */
	maxDevice?: 'mobile' | 'tablet' | 'computer' | 'largescreen' | 'widescreen',
	/** Hide content on touch devices */
	hideTouch?: boolean,
	/**
		Popup text or, react-intl coomponent or object of properties for Popup component.
		PROPS[IntlComponent=/language/localisation/, PopupProps=/components/modals/popup/]
	*/
	popup?: string | IntlComponent | PopupProps,
	/** Function called on menu open. */
	onOpen?: (
		event: SyntheticEvent<>
	) => void,
	/** Function called on menu close. */
	onClose?: (
		event: SyntheticEvent<>
	) => void
};

// Component default property values
const DEFAULTS = {
	icon: 'fa-ellipsis',
	fitted: false,
	off: false,
	disabled: false,
	direction: 'right',
	keepOpen: false,
	hideTouch: false,
	popup: <FormattedMessage id='component.context-menu.actions' />
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/dropdown/#types-pointing */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	// Variables and refs
	let [open, setOpen] = useState();

	const handleOpen = (event, data) => {
		// Open menu
		setOpen(true);
		// Trigger onClick callback funtion
		if (typeof props.onOpen === 'function') {
			props.onOpen(event, data);
		}
	};

	const handleClose = (event, data, keepOpen = false) => {
		// Wait for dropdown tp close
		if (!keepOpen) {
			setOpen(false);
		}

		// Trigger onClick callback funtion
		if (typeof props.onClose === 'function') {
			props.onClose(event, data);
		}
	};

	const formatOptions = (options: any) => {
		let formattedOptions = [];
		if (options && options.length > 0) {
			options.forEach((option, i) => {
				formattedOptions.push({
					...options[i],
					onClick: (event, data) => {
						handleClose(event, data, props.keepOpen);
						if (typeof options[i].onClick === 'function') {
							options[i].onClick();
						}
					}
				});
			});
		}
		return formattedOptions;
	};

	let button = (
		<Button
			className='context-menu-detached'
			icon={!props.iconGroup ? props.icon : undefined}
			iconGroup={props.iconGroup}
			focused={open}
			text={props.text}
			circular
			color={props.color}
			basic={props.basic}
			disabled={props.disabled}
			fitted={props.fitted}
			floated={props.floated}
			off={props.off}
			size={props.size}
			minDevice={props.minDevice}
			maxDevice={props.maxDevice}
			hideTouch={props.hideTouch}
			popup={!open || props.off ? props.popup : undefined}
		/>
	);

	let content = <Menu options={formatOptions(props.options)} compressed rounded={false} />;

	return (
		<Popup
			className='remove-paddings'
			trigger={button}
			content={content}
			on='click'
			position={`bottom ${props.direction === 'left' ? 'right' : 'left'}`}
			open={open}
			onClose={handleClose}
			onOpen={handleOpen}
		/>
	);
});

// Documentation generation support
Component.displayName = 'ContextMenuDetached';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
