/**
 * @prettier
 * @flow
 */
import { ModalActions } from 'semantic-ui-react';

// prettier-ignore
type Props = {
	/**  Primary content as children. */
	children: React.Node
};

const Component: React.AbstractComponent = React.memo((props: Props) => <ModalActions>{props.children}</ModalActions>);

// Documentation generation support
Component.displayName = 'ModalActions';

export type { Props };
export default Component;
