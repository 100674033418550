/**
 * @prettier
 * @flow
 */

import { Grid } from 'liana-ui/components/';
import SegmentMenuItem from './src/SegmentMenuItem';

// prettier-ignore
type Props = {
	/**
		A segment menu can have clickable segments.
		DATA[json/segment-menu/segment-menu.json]
		PROPS[LabelProps=/components/labels/labels/label/, IconProps=/components/labels/icons/icon, IconGroupProps=/components/labels/icons/icon-group]
	*/
	segments: Array<{
		link: string,
		header: string,
		icon: string | IconProps,
		iconGroup: IconGroupProps,
		labels:
			LabelProps
			| Array<LabelProps>,
		content: React.Node,
		onClick: (
			event: SyntheticEvent<>
		) => void,
		upsell: boolean
	}>,
		/**
		Represents column count per row in Grid.
		VALUES['equal', 1 - 12]
	*/
	columns?: string | number,
	/** A segment menu can preserve its vertical and horizontal gutters on first and last columns. */
	padded?: boolean | 'horizontally' | 'vertically',
	/** A segment menu can increase its gutters to allow for more negative space. */
	relaxed?: boolean | 'very',
	/**
		A column can specify a width for a mobile device.
		VALUES[1 - 12]
	*/
	mobile?: number,
	/**
		A column can specify a width for a tablet device.
		VALUES[1 - 12]
	*/
	tablet?: number,
	/**
		A column can specify a width for a computer.
		VALUES[1 - 12]
	*/
	computer?: number,
	/**
		A column can specify a width for a large screen device.
		VALUES[1 - 12]
	*/
	largescreen?: number,
	/**
		A column can specify a width for a wide screen device.
		VALUES[1 - 12]
	*/
	widescreen?: number
};

const DEFAULTS = {
	padded: false,
	relaxed: false,
	mobile: 12,
	tablet: 6,
	computer: 4,
	largescreen: 3,
	widescreen: 3
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/collections/grid/ */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	let menu = (
		<div className='segmentmenu-wrapper'>
			<Grid
				stretched
				columns={props.columns}
				padded={props.padded}
				relaxed={props.relaxed}
				stackable={props.columns !== undefined}
			>
				{props.segments && Array.isArray(props.segments)
					? props.segments.map((segment, index) => (
							<Grid.Column
								key={`column${index}`}
								mobile={!props.columns ? props.mobile : undefined}
								tablet={!props.columns ? props.tablet : undefined}
								computer={!props.columns ? props.computer : undefined}
								largescreen={!props.columns ? props.largescreen : undefined}
								widescreen={!props.columns ? props.widescreen : undefined}
							>
								<SegmentMenuItem
									link={segment.link}
									header={segment.header}
									icon={segment.icon}
									iconGroup={segment.iconGroup}
									labels={segment.labels}
									content={segment.content}
									onClick={segment.onClick}
									upsell={segment.upsell}
								/>
							</Grid.Column>
					  ))
					: null}
			</Grid>
		</div>
	);

	return menu;
});

// Documentation generation support
Component.displayName = 'SegmentMenu';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
