/**
 * @prettier
 */

import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import loadable from '@loadable/component';
import App from './components/app/App';
import { AppContextProvider } from './context/AppContext';
import { MeContextProvider } from './context/MeContext';
import { PageHeaderContextProvider } from 'liana-ui/components/header/src/PageHeaderContext';
import { MediaContextProvider } from 'liana-ui/components/responsive/src/MediaContext';
import { Auth0ProviderWithNavigate } from './components/auth0/Auth0ProviderWithNavigate';

const HomePage = loadable(() => import('./pages/home/Home'));
const AccountSectionPage = loadable(() => import('./pages/account/Index'));
const ApplicationMenuPage = loadable(() => import('./pages/account/ApplicationMenu'));
const OrganizationSection = loadable(() => import('./pages/organization/Index'));
const UserIndexPage = loadable(() => import('./pages/organization/user/Index'));

const SolutionsPage = loadable(() => import('./pages/solutions/Solutions'));
const SolutionSection = loadable(() => import('./pages/solutions/solution/Index'));
const SupportPage = loadable(() => import('./pages/support/Support'));
const SalesPage = loadable(() => import('./pages/sales/Sales'));
const TermsOfServicePage = loadable(() => import('./pages/terms-of-service/TermsOfService'));
const PrivacyPolicyPage = loadable(() => import('./pages/privacy/PrivacyPolicy'));
const NotFoundPage = loadable(() => import('./pages/not-found/NotFound'));
const SearchPage = loadable(() => import('./pages/search/Search'));
const LogoutPage = loadable(() => import('./pages/logout/Logout'));

// Test does every component work after uncommenting, backend endpoints have been changed
const ROUTES = (
	<Router>
		<Auth0ProviderWithNavigate>
			<AppContextProvider>
				<PageHeaderContextProvider>
					<MeContextProvider>
						<MediaContextProvider>
							<App>
								<Routes basename={process.env.basePath || '/'}>
									<Route index element={<Navigate to='home' replace={true} />} />
									<Route path='home' element={<HomePage />} />
									<Route path='account/*' element={<AccountSectionPage />} />
									<Route path='application-menu' element={<ApplicationMenuPage />} />
									<Route path='organization'>
										<Route path='*' index element={<OrganizationSection />} />
									</Route>
									<Route path='solutions'>
										<Route index element={<SolutionsPage />} />
										<Route path=':solutionId' element={<SolutionSection />} />
										<Route path=':solutionId/*' element={<SolutionSection />} />
									</Route>
									<Route path='support' element={<SupportPage />} />
									<Route path='sales' element={<SalesPage />} />
									<Route path='terms_of_service' element={<TermsOfServicePage />} />
									<Route path='privacy_policy' element={<PrivacyPolicyPage />} />
									<Route path='search' element={<SearchPage />} />
									<Route path='logout' element={<LogoutPage />} />
									<Route path='*' element={<NotFoundPage mainColumn />} />
								</Routes>
							</App>
						</MediaContextProvider>
					</MeContextProvider>
				</PageHeaderContextProvider>
			</AppContextProvider>
		</Auth0ProviderWithNavigate>
	</Router>
);
ReactDOM.render(ROUTES, document.getElementById('app-content'));
