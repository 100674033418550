/**
 * @prettier
 * @flow
 */

import { Flag } from 'semantic-ui-react';
import { Popup } from 'liana-ui/components/';
import type { IntlComponent } from 'react-intl';
import type { Props as PopupProps } from 'liana-ui/components/popup/Popup';

// prettier-ignore
type Props = {
	/** A flag must have a two digit country code or the full name.
		DATA[https://react.semantic-ui.com/elements/flag/]
	*/
	name: string,
	/**
		Popup text or, react-intl coomponent or object of properties for Popup component.
		PROPS[IntlComponent=/language/localisation/, PopupProps=/components/modals/popup/]
	*/
	popup?: string | IntlComponent | PopupProps
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/flag */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	let flag = <Flag name={props.name.toLowerCase()} />;

	// Attach popup
	return Popup.attach(props.popup, flag);
});

// Documentation generation support
Component.displayName = 'Flag';

export type { Props };
export default Component;
