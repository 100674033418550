/**
 * @prettier
 * @flow
 */

import { Step } from 'semantic-ui-react';
import StepTitle from './StepTitle';
import StepContent from './StepContent';
import StepDescription from './StepDescription';

// prettier-ignore
type Props = {
	/** A step can have an ID. */
	id: string,
	/** A step can have a title as text. */
	title: string,
	/** A step can have description as text. */
	description: string,
	/** A step can be highlighted as active. */
	active?: boolean,
	/** A step can show that a user has completed it. */
	completed?: boolean,
	/** Function called on click. When passed, the component will render as an `a` tag by default instead of a `div`.  */
	onClick: (
		event: SyntheticEvent<>,
		data: {
			id: string,
			active: boolean,
			completed: boolean
		}
	) => void
};

const DEFAULTS = {
	active: false,
	completed: false
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/step/#groups-ordered */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	// Handle data returned by callbacks.
	const handleCallbackData = (data: any) => ({
		id: data.id,
		active: data.active,
		completed: data.completed
	});

	return (
		<Step
			as='div'
			id={props.id}
			active={props.active}
			completed={props.completed}
			className={props.onClick ? 'has-link' : undefined}
			onClick={(event, data) => props.onClick(event, handleCallbackData(data))}
		>
			<StepContent>
				{props.title ? <StepTitle text={props.title} /> : null}
				{props.description ? <StepDescription text={props.description} /> : null}
			</StepContent>
		</Step>
	);
});

// Documentation generation support
Component.displayName = 'Step';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
