/**
 * @prettier
 * @flow
 */

import { useRef } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import Validate from 'liana-ui/definitions/component/Validate';
import { Button } from 'semantic-ui-react';
import { Icon, IconGroup, Label, Popup, Responsive, Transition, ButtonGroup } from 'liana-ui/components/';
import type { IntlComponent } from 'react-intl';
import type { Props as IconProps } from 'liana-ui/components/icon/Icon';
import type { Props as PopupProps } from 'liana-ui/components/popup/Popup';

// prettier-ignore
type Props = {
	/** A button can have a name. */
	name?: string,
	/** A button can have a different type. */
	type?: 'button' | 'submit' | 'cancel',
	/**
		A button can have text.
		PROPS[IntlComponent=/localization/]
	*/
	text?: string | IntlComponent,
	/** A button can have a notification number. */
	notification?: number,
	/** A button can be a link. Opens absolute links in new browser tab and internal links via router. */
	link?: string,
	/** A button can have an icon. PROPS[IconProps=/components/labels/icons/icon/] */
	icon?: string | IconProps,
	/** A button can have multiple icons combined. PROPS[IconProps=/components/labels/icons/icon/] */
	iconGroup?: Array<string> | Array<IconProps>,
	/** A button can have icon appear on the left or right of the text. */
	iconPosition?: 'left' | 'right',
	/** Color of the button. */
	color?: 'primary' | 'red' | 'orange' | 'yellow' | 'green' | 'blue' | 'grey',
	/** A button can be circular. */
	circular?: boolean,
	/** A button can be pronounced by having no borders. */
	basic?: boolean,
	/** A button can show it is currently active user selection */
	active?: boolean,
	/** A button can show it is currently focused user selection */
	focused?: boolean,
	/** A button can blink require more attension. */
	blinking?: boolean,
	/** A button can show it is currently unnecessary to be interacted with. */
	off?: boolean,
	/** A button can show it is currently unable to be interacted with. */
	disabled?: boolean,
	/** A button can be hidden. */
	hidden?: boolean,
	/** A button can show a loading indicator. */
	loading?: boolean,
	/** A button can take the width of its container. */
	fluid?: boolean,
	/** A button can keep all content on a sigle row withour ever breaking it to multiple lines. */
	noWrap?: boolean,
	/** A button can have no empty space around it. */
	fitted?: boolean,
	/**  A button can be aligned to the left or right of its container. */
	floated?: 'left' | 'right',
	/** A button can have different sizes. */
	size?: 'mini' | 'tiny' | 'small' | 'large',
	/** Smallest device that component will be displayed with. */
	minDevice?: 'mobile' | 'tablet' | 'computer' | 'largescreen' | 'widescreen',
	/** Largest device that component will be displayed with. */
	maxDevice?: 'mobile' | 'tablet' | 'computer' | 'largescreen' | 'widescreen',
	/** Hide content on touch devices */
	hideTouch?: boolean,
	/**
		A button can have a aria-label for accessability.
		PROPS[IntlComponent=/localization/]
	*/
	ariaLabel?: string | IntlComponent,
	/** Whether or not button should blur on click */
	blurOnClick?: boolean,
	/**
		Popup text or, react-intl coomponent or object of properties for Popup component.
		PROPS[IntlComponent=/language/localisation/, PopupProps=/components/modals/popup/]
	*/
	popup?: string | IntlComponent | PopupProps,
	/* A button can have additional classes. Use for very special features only! */
	className?: string,
	/** Function called on button click. */
	onClick?: (
		event: SyntheticEvent<>,
		data: {
			name: string,
			off: boolean
		}
	) => void
};

const DEFAULTS = {
	type: 'button',
	iconPosition: 'left',
	circular: true,
	active: false,
	focused: false,
	blinking: false,
	off: false,
	disabled: false,
	hidden: false,
	loading: false,
	fitted: false,
	fluid: false,
	hideTouch: false,
	blurOnClick: false
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/button/ */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	const navigate = useNavigate();

	// Variables and refs
	const buttonRef = useRef(null);

	// Get link type
	let linkType = Validate.linkType(props.link);

	const handleClick = (event: ?SyntheticEvent<>, data: any) => {
		// Blur button on click
		if (props.blurOnClick || (props?.popup?.content && props?.popup?.on === 'hover')) {
			if (buttonRef && buttonRef.current && buttonRef.current.ref && buttonRef.current.ref.current) {
				buttonRef.current.ref.current.blur();
			}
		}

		// Trigger onClick callback funtion
		if (typeof props.onClick === 'function') {
			props.onClick(event, handleCallbackData(data));
		}

		// Trigger internal link
		if (linkType && (linkType === 'internal' || linkType === 'anchor')) {
			event.preventDefault();
			if (linkType === 'internal') {
				navigate(props.link);
			}
			if (linkType === 'anchor') {
				Safely.scroll(props.link, () => {
					navigate(`${window.location.pathname}${props.link}`);
				});
			}
		}
	};

	// Handle data returned by callbacks.
	const handleCallbackData = (data: any) => ({
		name: data.name,
		off: props.off
	});

	// Get hidden text for better accessability.
	const getAriaLabel = () => {
		let text = props.ariaLabel
			? props.ariaLabel
			: typeof props.popup === 'string' || React.isValidElement(props.popup)
			? props.popup
			: typeof props.popup?.text === 'string' || React.isValidElement(props.popup?.text)
			? props.popup.text
			: null;
		return typeof text === 'string' ? text : 'Button';
	};

	// Function to generate LianaUI Button
	const createButton = (props: Props) => {
		// Assign classes
		const classes = classNames(
			{
				notification: props.notification !== undefined,
				blinking: props.blinking,
				off: props.off,
				focused: props.focused,
				fitted: props.fitted,
				nowrap: props.noWrap,
				invisible: props.hidden,
				cancel: props.type === 'cancel',
				icon: (props.icon || props.iconGroup) && !props.text,
				icons: props.iconGroup && !props.text,
				primary: props.color === 'primary',
				extramini: props.size === 'extramini'
			},
			props.className
		);

		// Assign Icon props
		if (typeof props.icon === 'string') {
			props.icon = Object.assign({
				name: props.icon
			});
		}

		// Define Button
		let button = (
			<Button
				ref={buttonRef}
				name={props.name}
				aria-label={getAriaLabel()}
				href={props.link}
				target={linkType === 'external' ? '_blank' : undefined}
				rel={linkType === 'external' ? 'noopener noreferrer' : undefined}
				type={props.link ? null : props.type === 'cancel' ? 'button' : props.type}
				className={classes}
				basic={props.basic}
				circular={props.circular}
				color={props.color && props.color !== 'primary' ? props.color : undefined}
				disabled={props.disabled || props.loading}
				active={props.active}
				loading={props.loading}
				fluid={props.fluid}
				floated={props.floated}
				size={props.size !== 'extramini' ? props.size : undefined}
				onClick={handleClick}
			>
				<Transition visible={props.notification > 0} animation='scale'>
					<span className='notification-label'>
						<Label notification size='mini' text={props.notification} />
					</span>
				</Transition>
				{props.icon && props.iconPosition === 'left' ? <Icon {...props.icon} /> : null}
				{props.iconGroup && props.iconGroup.length > 0 && props.iconPosition === 'left' ? (
					<IconGroup
						icons={
							typeof props.iconGroup[0] === 'string'
								? [
										{
											name: props.iconGroup[0],
											inverted: props.color && !props.disabled && !props.off
										},
										{
											name: props.iconGroup[1]
										}
								  ]
								: props.iconGroup
						}
					/>
				) : null}
				{props.text}
				{props.icon && props.iconPosition === 'right' ? <Icon {...props.icon} iconPosition='right' /> : null}
				{props.iconGroup && props.iconGroup.length > 0 && props.iconPosition === 'right' ? (
					<IconGroup
						iconPosition='right'
						size={!props.text && 'large'}
						icons={
							typeof props.iconGroup[0] === 'string'
								? [
										{
											name: props.iconGroup[0],
											inverted: props.color && !props.disabled && !props.off
										},
										{
											name: props.iconGroup[1]
										}
								  ]
								: props.iconGroup
						}
					/>
				) : null}
			</Button>
		);

		// Attach popup
		button = Popup.attach(props.popup, button);

		// If has content popup add another popup to show also text tooltip popup
		// FIXME: This is double assignment code-smell; it should not "be adding another popup"
		if (props.popup && props.popup.text && props.popup.content) {
			button = (
				<Popup
					text={props.popup.text}
					position={props.popup.position}
					size={props.popup.size}
					trigger={<span className='inline-block'>{button}</span>}
				/>
			);
		}

		return button;
	};

	// Display reponsively
	let component =
		props.minDevice || props.maxDevice || props.hideTouch ? (
			<Responsive {...props}>{createButton(props)}</Responsive>
		) : (
			createButton(props)
		);

	return component;
});

// Documentation generation support
Component.displayName = 'Button';
Component.defaultProps = DEFAULTS;

// Attach Subcomponents
Component.Group = ButtonGroup;

export type { Props };
export default Component;
