/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import type { Tooltip } from 'liana-ui/definitions/component/Types';

type Props = {
	/** Content to place inside .ui.label element */
	children?: React.Node,
	/** Classes for .ui.label element */
	classes?: string,
	/** Type of the label (icon, favicon, image, notification, state) */
	type?: 'icon' | 'favicon' | 'image' | 'notification' | 'state',
	/** HTML data attrributes for .ui.label element */
	dataAttributes?: { [string]: string },
	/** Tooltip (data-content, data-position, data-delay) */
	tooltip?: Tooltip,
	/** onClick handler */
	onClick?: () => mixed
};

const ICON = {
	none: '',
	icon: 'icon-label',
	notification: 'circular purple notification-label',
	state: 'circular empty state-label',
	image: 'image',
	favicon: 'image favicon-label'
};

/** COMPONENT BASED ON: https://fomantic-ui.com/elements/label.html */
export default class Label extends React.PureComponent<Props> {
	_label: { current: React.ElementRef<'span'> | null };
	constructor(props: Props) {
		super(props);
		this._label = React.createRef();
	}
	get ref() {
		return this._label.current;
	}
	render() {
		let type = this.props.type || 'none';
		let sizes = ['mini', 'tiny', 'small', 'large', 'big'];
		let classes = classNames('ui label', ICON.hasOwnProperty(type) ? ICON[type] : '', this.props.classes, {
			small: !new RegExp(sizes.join('|')).test(this.props.classes), // Default tiny label
			'popup-open': this.props.tooltip
		});
		return (
			<span
				ref={this._label}
				className={classes}
				onClick={this.props.onClick}
				{...this.props.dataAttributes}
				{...this.props.tooltip}
			>
				{this.props.children}
			</span>
		);
	}
}
