/**
 * @prettier
 * @flow
 */

import { useIntl } from 'react-intl';
import classNames from 'classnames';
import Breadcrumb from 'liana-ui/components/breadcrumb/Breadcrumb';
import TrailTools from './src/TrailTools';
import TrailContext from './src/TrailContext';

// prettier-ignore
type Props = {
	/** A trail must have sections for page navigation. */
	sections: Array<{
		page: string,
		tab: string,
		link: string
	}>,
	/** A trail van have extra actions placed on the right. */
	tools?: Array<React.Node>,
	/** A trail can display application name in browser page title. */
	appName?: string,
	/** A trail can have an icon before it. DATA[https://react.semantic-ui.com/elements/icon/] */
	icon?: string,
	/** A trail can be different size. */
	size?: 'small',
	/** A trail can automatically update browser page title based on trail. */
	updateTitle?: boolean
};

const DEFAULTS = {
	updateTitle: true
};

/* Note: Do NOT use React.Memo for this component */
/** COMPONENT BASED ON: https://react.semantic-ui.com/collections/breadcrumb/ */
const Component = (props: Props) => {
	const intl = useIntl();
	let key = null,
		title = [];

	if (Array.isArray(props.sections) && props.sections.length > 0) {
		for (let i = 0; i < props.sections.length; i++) {
			key = props.sections[i].page; // Convenience
			title.push(
				React.isValidElement(key) && key.props?.id
					? intl.formatMessage({ id: key.props.id }, key.props.values) // Translate a <FormatMessage> component
					: key // Basic text
			);
		}

		if (props.updateTitle) {
			title.reverse();
			if (props.appName) {
				title.push(props.appName);
			}
			document.title = title.join(' - ');
		}
	}

	return (props.sections && props.sections.length > 0) || props.tools ? (
		<div className={classNames('trail', props.size)}>
			<Breadcrumb sections={props.sections} size={props.size} icon={props.icon} />
			<TrailTools tools={props.tools} />
		</div>
	) : null;
};

// Documentation generation support
Component.displayName = 'Trail';
Component.defaultProps = DEFAULTS;
Component.Context = TrailContext;

export type { Props };
export default Component;
