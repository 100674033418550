/**
 * @prettier
 * @flow
 */
import { ListDescription } from 'semantic-ui-react';

// prettier-ignore
type Props = {
	/** Detail as children. */
	children: React.Node
};

const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => (
	<ListDescription>{props.children}</ListDescription>
));

Component.displayName = 'ListDescription';

export type { Props };
export default Component;
