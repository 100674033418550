/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { Fragment, useState } from 'react';
import { Accordion } from 'semantic-ui-react';
import AccordionTitle from './src/AccordionTitle';
import AccordionContent from './src/AccordionContent';
import AccordionAccordion from './src/AccordionAccordion';
import { Checkbox, Divider, Header, NavLink } from 'liana-ui/components';

import type { IntlComponent } from 'react-intl';
import type { Props as LabelProps } from 'liana-ui/components/label/Label';

// prettier-ignore
type Props = {
	children?: React.Node,
	/**
		An accordien one or more have toggleable panels.
		PROPS[IntlComponent=/language/localisation/, LabelProps=/components/labels/labels/label/]
	*/
	panels: Array<{
		key: string,
		title: string | IntlComponent,
		actions: ReactNode,
		content: string | ReactNode,
		link: string,
		header: string,
		labels: LabelProps | Array<LabelProps>,
		panels: Array<{
			title: string,
			content: string | ReactNode,
			...
		}>
	}>,
	/** Indexes of the currently active panels. */
	defaultActiveIndex?: string | Array<string>,
	/** An aacordien can allow only one panel to be open at a one time. */
	exclusive?: boolean,
	/** An accordion arrow icons can work up/down instead or right/down. */
	upDown?: boolean,
	/** An accordion can show panels as segments. */
	styled?: boolean,
	/** An accordion can have toggles. */
	toggle?: boolean,
	/** An accordion can have maximum levels of nested accordions. */
	maxLevels?: number,
	/** Animation duration.  */
	duration?: number,
	/** An accordion can be different size. */
	size?: 'large' | 'big',
	/** Function called on any click. */
	onClick?: (
		event: SyntheticEvent<>,
		data: {
			active: boolean,
			index: number,
			activeIndexes: string | Array<string>
		}
	) => void,
	/** Function called on link click only. */
	onLinkClick?: (
		event: SyntheticEvent<>,
		data: any
	) => void,
	/** Fuction called when animation ends and panel is fully open. */
	onAnimationEnd?: (
		newHeight: number
	) => void
};

const DEFAULTS = {
	exclusive: false,
	upDown: false,
	styled: false,
	toggle: false
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/accordion/ */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	const [activeIndexes, setActiveIndexes] = useState(() => {
		let defaultActiveIndex = props.defaultActiveIndex
			? Array.isArray(props.defaultActiveIndex)
				? props.defaultActiveIndex.map(String)
				: props.defaultActiveIndex.toString()
			: undefined;
		let defaultActiveIndexesState = Array.isArray(defaultActiveIndex)
			? defaultActiveIndex
				? defaultActiveIndex
				: []
			: defaultActiveIndex !== undefined
			? [defaultActiveIndex]
			: [];

		return defaultActiveIndexesState;
	});

	const handleClick = (event: ?SyntheticEvent<>, data: any) => {
		let active;
		if (props.exclusive) {
			active = data.active ? [] : [data.index];
		} else {
			active = data.active
				? activeIndexes.filter((activeIndex) => activeIndex !== data.index)
				: [...activeIndexes, data.index];
		}

		setActiveIndexes(active);
		if (typeof props.onClick === 'function') {
			props.onClick(event, handleCallbackData(data, active));
		}
	};

	const handleLinkClick = (event: ?SyntheticEvent<>, data: any) => {
		if (typeof props.onLinkClick === 'function') {
			props.onLinkClick(event, data);
		}
	};

	const handleAnimationEnd = (newHeight: number) => {
		if (typeof props.onAnimationEnd === 'function') {
			props.onAnimationEnd(newHeight);
		}
	};

	// Handle data returned by callbacks.
	const handleCallbackData = (data: any, active: number | Array<number>) => ({
		active: data.active,
		index: data.index,
		activeIndexes: active
	});

	// Assign classes
	const classes = classNames({
		updown: props.upDown
	});

	const getIcon = (panel: object) => {
		return props.toggle
			? false
			: panel.icon
			? panel.icon
			: props.upDown
			? { name: 'fa-chevron-right fa-solid' }
			: { name: 'fa-chevron-right fa-solid' };
	};

	let accordion = (
		<Accordion fluid styled={props.styled} className={classes}>
			{props.children
				? props.children
				: props.panels.map((panel, index) => {
						index = (index + 1).toString();
						return panel.header ? (
							<Header key={index} as='h6' text={panel.header} />
						) : panel.divider ? (
							<Divider />
						) : (
							<Fragment key={index}>
								{props.styled && index > 1 ? <Divider removeMargins='all' /> : null}
								{panel.actions ? <span className='float-right'>{panel.actions}</span> : null}
								<AccordionTitle
									index={index}
									active={activeIndexes.includes(index)}
									as={panel.link ? NavLink : undefined}
									to={panel.link ? panel.link : undefined}
									disableLink={panel.panels && panel.panels.length > 0}
									icon={getIcon(panel)}
									labels={panel.labels}
									upsell={panel.upsell}
									size={props.size}
									onClick={handleClick}
									onLinkClick={handleLinkClick}
								>
									{props.toggle ? (
										<Checkbox
											toggle
											label={panel.title}
											name={panel.key}
											checked={activeIndexes.includes(index)}
										/>
									) : (
										<>{panel.title}</>
									)}
								</AccordionTitle>
								<AccordionContent
									parentIndex={index}
									defaultActiveIndex={activeIndexes}
									active={activeIndexes.includes(index)}
									exclusive={props.exclusive}
									panels={panel.panels}
									duration={props.duration}
									maxLevels={props.maxLevels}
									onLinkClick={handleLinkClick}
									onAnimationEnd={handleAnimationEnd}
								>
									{panel.content}
								</AccordionContent>
							</Fragment>
						);
				  })}
		</Accordion>
	);

	return accordion;
});

// Documentation generation support
Component.displayName = 'Accordion';
Component.defaultProps = DEFAULTS;

// Attach Subcomponents
Component.Title = AccordionTitle;
Component.Content = AccordionContent;
Component.Accordion = AccordionAccordion;

export default Component;
