/**
 * @prettier
 * @flow
 */

import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Statistic } from 'semantic-ui-react';
import StatisticTrend from './StatisticTrend';
import StatisticGroup from './StatisticGroup';
import { Visibility, Count } from 'liana-ui/components';
import { FormattedNumber } from 'react-intl';
import StatisticValue from './src/StatisticValue';
import StatisticLabel from './src/StatisticLabel';

// prettier-ignore
type Props = {
	/** A statistic must have main value. Only numbers will be animated. */
	value: number | React.Node,
	/** A statistic can have a label to describe main value. */
	label?: string | React.Node,
	/** A statistic main value can be a link. */
	link?: string,
	/** A static can have different notations and formatting. */
	notation?: 'price' | 'percentage' | 'compact',
	/**
		A price notation statistic can have a currency symbol.
		VALUES['EUR', 'USD', ...]
	*/
	currency?: string,
	/** A statistic can take the width of its container. */
	fluid?: boolean,
	/** A statistic can be different size. */
	size?: 'tiny' | 'small' | 'large',
	/** A statistic can animate only when it becomes visible on screen. */
	lazyLoad?: boolean,
	/** Animation duration in seconds. */
	duration?: number,
	/** Function called when count animation is complete. */
	onComplete?: () => void
};

const DEFAULTS = {
	fluid: false,
	lazyLoad: true
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/views/statistic */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	let [visible, setVisible] = useState(!props.lazyLoad);
	let number = props.value;

	/* istanbul ignore next */
	const makeVisible = () => setVisible(true);

	// Handle various formatting cases
	switch (props.notation) {
		case 'price':
			number = <FormattedNumber value={number} style='currency' currency={props.currency} />;
			break;
		case 'compact':
			number = <FormattedNumber value={number} notation='compact' compactDisplay='short' />;
			break;
		case 'percentage': {
			let opts = { value: number, style: 'percent' };
			number = Number.isInteger(number) ? (
				<FormattedNumber {...opts} />
			) : (
				<FormattedNumber {...opts} minimumFractionDigits={2} maximumFractionDigits={2} />
			);
			break;
		}
	}

	let statistic = (
		<Statistic className={props.fluid ? 'fluid' : undefined} size={props.size}>
			<StatisticValue
				as={props.link ? Link : undefined}
				to={props.link ? props.link : undefined}
				content={
					typeof props.value == 'number' ? (
						<Count onComplete={props.onComplete} end={number} duration={props.duration} visible={visible} />
					) : (
						props.value
					)
				}
			/>
			<StatisticLabel content={props.label} />
		</Statistic>
	);

	if (props.lazyLoad) {
		statistic = (
			<Visibility as={React.Fragment} onTopVisible={makeVisible}>
				{statistic}
			</Visibility>
		);
	}

	return statistic;
});

// Documentation generation support
Component.displayName = 'Statistic';
Component.defaultProps = DEFAULTS;

// Attach Subcomponents
Component.Trend = StatisticTrend;
Component.Group = StatisticGroup;

export type { Props };
export default Component;
