/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { Flag, Icon, Image } from 'liana-ui/components/';

// prettier-ignore
type Props = {
	/** Label text. */
	text?: string | FormattedMessage,
	/** Label description. */
	description?: string | FormattedMessage,
	/** Optional ID for Label. */
	htmlFor?: string,
	/** Image by url or object of props for Image. */
	image?: string,
	/** Icon by name or object of props for Icon. */
	icon?: string,
	/** Name for Flag. Optional Flag to display on the left of the Input. */
	flag?: string,
	/* Label can have additional classes. */
	className?: string
};

/** Checkbox label - Not designed to be used outside the Checkbox -component. */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	let getContent = () => {
		switch (true) {
			case props.flag !== undefined:
				return <Flag name={props.flag} />;
			case props.icon !== undefined:
				return <Icon name={props.icon} />;
			case props.image !== undefined:
				return <Image src={props.image} size='favicon' inline />;
		}
		return null;
	};

	// Assign classes
	const classes = classNames(
		{
			'has-description': props.description
		},
		props.className
	);

	return (
		<label htmlFor={props.htmlFor} className={classes}>
			{getContent()}
			<span className='text'>{props.text}</span>
			{props.description ? <span className='description'>{props.description}</span> : null}
		</label>
	);
});

// Documentation generation support
Component.displayName = 'CheckboxLabel';

export default Component;
