/**
 * @prettier
 * @flow
 */

import { Media } from './src/MediaContext';

// prettier-ignore
type Props = {
	/** Main content */
	children: React.Node,
	/** Smallest device that component will be displayed with. */
	minDevice?: 'mobile' | 'tablet' | 'computer' | 'largescreen' | 'widescreen',
	/** Largest device that component will be displayed with. */
	maxDevice?: 'mobile' | 'tablet' | 'computer' | 'largescreen' | 'widescreen',
	/** Hide content on touch devices */
	hideTouch?: boolean
};

const DEFAULTS = {
	maxDevice: false,
	minDevice: false,
	hideTouch: false
};

/** COMPONENT BASED ON: https://github.com/artsy/fresnel */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	let pointerDevice = document.querySelector('html').classList.contains('pointer-device');

	let getMin = () => {
		let minDevice = 'widescreen';
		switch (true) {
			case props.minDevice === 'mobile':
				minDevice = 'min';
				break;
			case props.minDevice === 'tablet':
				minDevice = 'mobile';
				break;
			case props.minDevice === 'computer':
				minDevice = 'tablet';
				break;
			case props.minDevice === 'largescreen':
				minDevice = 'computer';
				break;
			case props.minDevice === 'widescreen':
				minDevice = 'largescreen';
				break;
		}
		return minDevice;
	};

	let between = [];
	if (props.maxDevice && props.minDevice) {
		between = [getMin(), props.maxDevice];
	} else if (props.maxDevice && !props.minDevice) {
		between = ['min', props.maxDevice];
	} else if (!props.maxDevice && props.minDevice) {
		between = [getMin(), 'widescreen'];
	}

	return (
		<Media between={between}>
			{(mediaClassNames, renderChildren) => {
				return renderChildren && (!props.hideTouch || (props.hideTouch && pointerDevice))
					? props.children
					: null;
			}}
		</Media>
	);
});

// Documentation generation support
Component.displayName = 'Responsive';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
