import Notification from 'liana-ui/legacy/components/notification/Notification';

let notification = {
	showSuccess: (text) => {
		return new Notification({
			text: text,
			icon: 'checkmark',
			timeout: 4000
		});
	},
	showError: (text) => {
		return new Notification({
			text: text,
			icon: 'warning sign',
			timeout: 4000,
			type: 'error'
		});
	},
	showWarning: (text) => {
		return new Notification({
			text: text,
			icon: 'warning circe',
			timeout: 4000,
			type: 'warning'
		});
	}
};

export default notification;
