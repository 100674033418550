

export default {
	// Check if link if internal, a anchor or an external link
	linkType: (link) => {
		let type = false;
		if (link && typeof link === 'string' && link !== '#') {
			type = 'internal';
			if (link && typeof link === 'string') {
				let externalPattern = /^((http|https|ftp):\/\/)/;
				let anchorPattern = /^#/;
				if (externalPattern.test(link)) {
					type = 'external';
				} else if (anchorPattern.test(link)) {
					type = 'anchor';
				}
			}
		}
		return type;
	}
}
