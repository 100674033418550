/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { useRef, useState, useEffect } from 'react';
import ResizeSensor from 'css-element-queries/src/ResizeSensor';
import { Table } from 'semantic-ui-react';
import TableRow from './src/TableRow';
import TableHeaderCell from './src/TableHeaderCell';
import TableBodyCell from './src/TableBodyCell';
import TableFooterCell from './src/TableFooterCell';
import TableUtils from './src/TableUtils';

// prettier-ignore
type Props = {
	/**
		A table can have it's columns defined so whole columns can be controlled.
		DATA[json/table/columns.json]
	*/
	columns?: Array<{
		name: string,
		width: number,
		collapsing: boolean,
		minDevice: string,
		textAlign: string,
		singleLine: boolean,
		breakWord: boolean,
		noWrap: boolean
	}>,
	/**
		An table can have a single or multiple header rows.
		DATA[json/table/header-rows.json]
		PROPS[IntlComponent=/language/localisation/, PopupProps=/components/modals/popup/]
	*/
	headerRows?: Array<{
		cells: Array<{
			content: any,
			textAlign: string,
			width: string,
			collapsing: boolean,
			colSpan: number,
			rowSpan: number,
			popup: string | IntlComponent | PopupProps
		}>
	}>,
	/**
	 	A table can have body rows.
		DATA[json/table/body-rows.json]
		PROPS[IntlComponent=/language/localisation/, PopupProps=/components/modals/popup/]
	*/
	bodyRows: Array<{
		cells: Array<{
			content: any,
			textAlign: string,
			verticalAlign: string,
			width: string,
			collapsing: boolean,
			success: boolean,
			warning: boolean,
			error: boolean,
			colSpan: number,
			rowSpan: number,
			singleLine: boolean,
			breakWord: boolean,
			noWrap: boolean,
			popup: string | IntlComponent | PopupProps
		}> | any
	}>,
	/** Mapped over any data provided for bodyRows property. Should return body row object for body rows. */
	renderBodyRow?: (
		data
	) => {
		cells: Array<{
			content: any,
			...
		}>
	},
	/**
		An table can have a single or multiple footer rows.
		DATA[json/table/footer-rows.json]
		PROPS[IntlComponent=/language/localisation/, PopupProps=/components/modals/popup/]
	*/
	footerRows?: Array<{
		cells: Array<{
			content: any,
			textAlign: string,
			width: string,
			collapsing: boolean,
			colSpan: number,
			rowSpan: number,
			popup: string | IntlComponent | PopupProps
		}>
	}>,
	/** A table can reduce its complexity to increase readability. */
	basic?: boolean,
	/** A table may be divided into individual cells. */
	celled?: boolean,
	/** A table may sometimes need to be more compact to make more rows visible at a time. */
	compact?: boolean,
	/** A table can be more fitted by removing first and last cell paddings. */
	fitted?: boolean,
	/** A table may sometimes need to be more padded for legibility. */
	padded?: boolean,
	/** A table may be formatted to emphasize a first column that defines a row content. */
	definition?: boolean,
	/** A table can be formatted to display complex structured data. */
	structured?: boolean,
	/** A table can specify that its cell contents should remain on a single line and not wrap. */
	singleLine?: boolean,
	/** A table can remove all it's margins. */
	removeMargins?: boolean,
	/** Overflow scrolling can be disabled. */
	disableScroll?: boolean,
	/** A table can be smaller in size. */
	size?: 'small'
};

const DEFAULTS = {
	basic: false,
	celled: true,
	compact: false,
	fitted: false,
	padded: false,
	definition: false,
	structured: false,
	singleLine: false,
	disableScroll: false
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/collections/table/ */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	// Variables, states and refs
	const scrollWrapperRef = useRef();
	const tableRef = useRef();
	let [horizontalScrollActive, setHorizontalScrollActive] = useState(true);

	// Set horizontal sticky and scrolling
	useEffect(() => {
		if (scrollWrapperRef.current && tableRef.current) {
			setHorizontalSticky();

			new ResizeSensor(scrollWrapperRef.current, function () {
				setHorizontalSticky();
			});

			new ResizeSensor(tableRef.current, function () {
				setHorizontalSticky();
			});
		}
	}, []);

	// Clear sensors on unmount
	useEffect(
		() => () => {
			ResizeSensor.detach(scrollWrapperRef.current);
			ResizeSensor.detach(tableRef.current);
		},
		[]
	);

	const setHorizontalSticky = () => {
		const scrollWrapperWidth = scrollWrapperRef.current.getBoundingClientRect().width,
			tableWidth = tableRef.current.getBoundingClientRect().width;
		if (tableWidth > scrollWrapperWidth) {
			setHorizontalScrollActive(true);
			TableUtils.enableHorizontalDrag(scrollWrapperRef.current);
		} else {
			setHorizontalScrollActive(false);
			TableUtils.disableHorizontalDrag(scrollWrapperRef.current);
		}
	};

	// Factory function for transforming data
	let bodyRows = [];
	if (props.bodyRows && props.bodyRows.length > 0) {
		if (typeof props.renderBodyRow === 'function') {
			for (let i = 0; i < props.bodyRows.length; i++) {
				bodyRows[i] = props.renderBodyRow(props.bodyRows[i], i);
			}
		} else {
			bodyRows = props.bodyRows;
		}
	}

	const renderHeaderRows = () => {
		// Check if exists
		if (!props.headerRows) {
			return null;
		}

		let rows = [];
		props.headerRows.map((row, rowIndex) => {
			let cells = [];
			row.cells.map((cell, cellIndex) => {
				if (Array.isArray(props.columns) && props.columns.length > 0) {
					cell = { ...props.columns[cellIndex], ...cell };
				}

				cells.push(
					<TableHeaderCell
						key={`tableheadercell-${rowIndex}-${cellIndex}`}
						content={cell.content}
						colSpan={cell.colSpan}
						collapsing={cell.collapsing}
						width={cell.width}
						textAlign={cell.textAlign}
						verticalAlign={cell.verticalAlign}
						minDevice={cell.minDevice}
						popup={cell.popup}
					/>
				);
			});

			rows.push(<Table.Row key={`tableheaderrow-${rowIndex}`}>{cells}</Table.Row>);
		});

		return <Table.Header>{rows}</Table.Header>;
	};

	const renderBodyRows = (bodyRows) => {
		// Check if exists
		if (!bodyRows || !Array.isArray(bodyRows)) {
			return null;
		}

		let rows = [];
		bodyRows.map((row) => {
			let cells = [];
			if (Array.isArray(row.cells)) {
				row.cells.map((cell, cellIndex) => {
					if (Array.isArray(props.columns) && props.columns.length > 0) {
						cell = { ...props.columns[cellIndex], ...cell };
					}
					cells.push(
						<TableBodyCell
							key={`tablebodycell-${row.id}-${cellIndex}`}
							content={cell.content}
							colSpan={cell.colSpan}
							rowSpan={cell.rowSpan}
							collapsing={cell.collapsing}
							success={cell.success}
							warning={cell.warning}
							error={cell.error}
							width={cell.width}
							textAlign={cell.textAlign}
							verticalAlign={cell.verticalAlign}
							singleLine={!cell.header ? cell.singleLine : false}
							breakWord={!cell.header ? cell.breakWord : false}
							noWrap={!cell.header ? cell.noWrap : false}
							minDevice={cell.minDevice}
							popup={cell.popup}
						/>
					);
				});

				rows.push(<TableRow key={`tablebodyrow-${row.id}`}>{cells}</TableRow>);
			}
		});

		return <Table.Body>{rows}</Table.Body>;
	};

	const renderFooterRows = () => {
		// Check if exists
		if (!props.footerRows) {
			return null;
		}

		let rows = [];
		props.footerRows.map((row, rowIndex) => {
			let cells = [];
			row.cells.map((cell, cellIndex) => {
				if (Array.isArray(props.columns) && props.columns.length > 0) {
					cell = { ...props.columns[cellIndex], ...cell };
				}

				cells.push(
					<TableFooterCell
						key={`tablefootercell-${rowIndex}-${cellIndex}`}
						content={cell.content}
						colSpan={cell.colSpan}
						collapsing={cell.collapsing}
						width={cell.width}
						textAlign={cell.textAlign}
						verticalAlign={cell.verticalAlign}
						minDevice={cell.minDevice}
						popup={cell.popup}
					/>
				);
			});

			rows.push(<Table.Row key={`tablefooterrow-${rowIndex}`}>{cells}</Table.Row>);
		});

		return <Table.Footer>{rows}</Table.Footer>;
	};

	const countColumns = () => {
		let columns = props.columns?.length,
			columnNames = {
				1: 'one',
				2: 'two',
				3: 'three',
				4: 'four',
				5: 'five',
				6: 'six',
				7: 'seven',
				8: 'eight',
				9: 'nine',
				10: 'ten',
				11: 'eleven',
				12: 'twelve',
				13: 'thirteen',
				14: 'fourteen',
				15: 'fifteen',
				16: 'sixteen'
			};
		return columnNames[columns];
	};

	// Assign wrapper classes
	const wrapperClasses = classNames('table-wrapper', {
		basic: props.basic,
		structured: props.structured,
		scrollable: horizontalScrollActive && !props.disableScroll,
		'remove-margins': props.removeMargins
	});

	// Assign table classes
	const columns = countColumns();
	const tableClasses = classNames(
		'ui',
		{
			[`${columns} column`]: columns,
			[props.size]: props.size,
			celled: props.celled && !props.definition,
			padded: props.padded,
			structured: props.structured,
			unstackable: props.disableScroll ? false : true,
			definition: props.definition,
			'single line': props.singleLine,
			basic: props.basic,
			compact: props.compact,
			fitted: props.fitted
		},
		'table'
	);

	let table = (
		<div ref={scrollWrapperRef} className={wrapperClasses}>
			<table ref={tableRef} className={tableClasses}>
				{renderHeaderRows()}
				{renderBodyRows(bodyRows)}
				{renderFooterRows()}
			</table>
		</div>
	);

	return table;
});

// Documentation generation support
Component.displayName = 'Table';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
