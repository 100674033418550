/**
 * @prettier
 * @flow
 */

import { Visibility } from 'semantic-ui-react';

// prettier-ignore
type Props = {
	/** An element type to render as (string or function). */
	as: string | (() => void),
	/** Primary content. */
	children: React.Node,
	/** Value that context should be adjusted in pixels. Useful for making content appear below content fixed to the page. */
	offset?: number | Array<number>,
	/** Element's top edge has passed bottom of screen. */
	onTopVisible: () => void
};

const DEFAULTS = {
	offset: [0, 0]
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/behaviors/visibility/ */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	return <Visibility {...props} updateOn='repaint' />;
});

// Documentation generation support
Component.displayName = 'Visibility';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
