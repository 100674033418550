/**
 * @prettier
 * @flow
 */

import { orderBy } from 'lodash';
import { useIntl } from 'react-intl';
import { Dropdown } from 'liana-ui/components/';
import type { IntlComponent } from 'react-intl';
import type { Props as PopupProps } from 'liana-ui/components/popup/Popup';

// prettier-ignore
type Props = {
	/** A country dropdown must have an input name */
	name: string,
	/** A country dropdown can have countries as options. Array of standard Liana®Cloud country objects. DATA[json/countries/countries.json] */
	countries: Array<{
		name: string,
		isoAlpha2: string
	}>,
	/** A country dropdown can have specific countries to place on top of the dropdown. */
	topCountries?: Array<string>,
	/** Initial value or value array if multiple. Use for uncontrolled components only. */
	defaultValue?: string | Array<string>,
	/** Current value or value array if multiple. Use for controlled components only. */
	value?: string | Array<string>,
	/**
		An dropdown can have a placeholder text.
		PROPS[IntlComponent=/localization/]
	*/
	placeholder?: string | IntlComponent,
	/** A country dropdown can allow multiple selections. */
	multiple?: boolean,
	/** A country dropdown can be searchable. */
	search?: boolean,
	/** A country dropdown can be clearable. */
	clearable?: boolean,
	/** A country dropdown can take the full width of its parent. */
	fluid?: boolean,
	/** A country dropdown can be locked to indicate that the field is in use but can not be edited. */
	locked?: boolean,
	/** A country dropdown can be  disabled. */
	disabled?: boolean,
	/** A country dropdown can be different size. */
	size?: 'small' | 'large',
	/**
		Popup text or, react-intl coomponent or object of properties for Popup component.
		PROPS[IntlComponent=/language/localisation/, PopupProps=/components/modals/popup/]
	*/
	popup?: string | IntlComponent | PopupProps,
	/** Function called on input change. */
	onChange?: (
		event: SyntheticEvent<>,
		data: {
			name: string,
			value: string,
			options: Array<string>
		}
	) => void
};

const DEFAULTS = {
	multiple: false,
	search: true,
	clearable: false,
	locked: false,
	disabled: false,
	fluid: true
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/dropdown/ */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	const intl = useIntl();

	// get options
	const getOptions = () => {
		let topCountries = [];
		let options = [];

		if (props.topCountries) {
			for (let i = 0; i < props.topCountries.length; i++) {
				let isocode = props.topCountries[i];
				let name = props.countries.find((element) => element.isoAlpha2 === isocode);
				if (name) {
					topCountries.push(makeObject(getCountryName(isocode, name), isocode));
				}
			}
		}

		for (let i = 0; props.countries.length > i; i++) {
			let isocode = props.countries[i].isoAlpha2;
			let name = props.countries[i].name;
			if (!props.topCountries || (props.topCountries && !props.topCountries.includes(isocode))) {
				options.push(makeObject(getCountryName(isocode, name), isocode));
			}
		}

		options = orderBy(options, ['text'], ['asc']);
		options = topCountries.concat(options);
		return options;
	};

	const getCountryName = (isocode: string, name: string) =>
		intl.formatMessage({ id: `country.${isocode}`, defaultMessage: name });

	const makeObject = (name: string, isokey: string) => ({
		text: name,
		value: isokey,
		flag: isokey.toLowerCase()
	});

	let dropdown = (
		<Dropdown
			name={props.name}
			defaultValue={props.defaultValue}
			value={props.value}
			placeholder={props.placeholder}
			options={getOptions()}
			fluid={props.fluid}
			multiple={props.multiple}
			search={props.search}
			clearable={props.clearable}
			locked={props.locked}
			disabled={props.disabled}
			size={props.size}
			popup={props.popup}
			onChange={props.onChange}
		/>
	);

	return dropdown;
});

// Documentation generation support
Component.displayName = 'CountryDropdown';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
