/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { Responsive, Popup } from 'liana-ui/components/';
import { Table } from 'semantic-ui-react';
import type { IntlComponent } from 'react-intl';
import type { Props as PopupProps } from 'liana-ui/components/popup/Popup';

// prettier-ignore
type Props = {
	/** Cell content. */
	content?: string | React.Node,
	/** Represents colspan of cell. */
	colSpan?: number,
	/** A cell can be collapsing so that it only uses as much space as required. */
	collapsing: boolean,
	/** Represents width of cell. Possible values: 1 - 12 */
	width?: string | number,
	/** A table cell can adjust its text alignment. */
	textAlign?: 'left' | 'center' | 'right',
	/** A table cell can adjust its text alignment. */
	verticalAlign?: 'bottom' | 'middle' | 'top',
	/** A header cell can be sorted in ascending or descending order. */
	sorted?: 'asc' | 'desc',
	/** A table header can be sortable. */
	sortable?: boolean,
	/** Smallest device that cell will be displayed with. */
	minDevice?: 'mobile' | 'tablet' | 'computer' | 'largescreen' | 'widescreen',
	/** Largest device that cell will be displayed with. */
	maxDevice?: 'mobile' | 'tablet' | 'computer' | 'largescreen' | 'widescreen',
	/** Popup text or object of properties for Popup */
	popup?: IntlComponent | string | PopupProps,
	/** Called on column sort. */
	onClick?: (
		event: SyntheticEvent<>
	) => void
};

const DEFAULTS = {
	collapsing: false,
	unsortable: false
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/collections/table/ */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	// Function to generate LianaUI Button
	const createCell = (props: Props) => {
		// Assign classes
		const classes = classNames(
			{
				unsortable: !props.sortable
			},
			props.sticky
		);

		let sorted = undefined;
		if (props.sorted === 'asc') {
			sorted = 'ascending';
		} else if (props.sorted === 'desc') {
			sorted = 'descending';
		}

		// Define cell
		let cell = (
			<Table.HeaderCell
				className={classes}
				collapsing={props.collapsing}
				colSpan={props.colSpan}
				width={props.width}
				textAlign={props.textAlign}
				verticalAlign={props.verticalAlign}
				sorted={sorted}
				onClick={props.onClick}
			>
				{props.content}
			</Table.HeaderCell>
		);

		// Attach popup
		return Popup.attach(props.popup, cell);
	};

	// Display reponsively
	let component =
		props.minDevice || props.maxDevice || props.hideTouch ? (
			<Responsive {...props}>{createCell(props)}</Responsive>
		) : (
			createCell(props)
		);

	return component;
});

// Documentation generation support
Component.displayName = 'TableHeaderCell';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
