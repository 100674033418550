/**
 * @prettier
 * @flow
 */

import { Zendesk, Gainsight } from 'liana-ui/definitions';
import { FormattedMessage } from 'react-intl';
import { Dropdown } from 'semantic-ui-react';
import { Icon, Popup } from 'liana-ui/components/';
import type { Props as PopupType } from 'liana-ui/components/popup/Popup';

// prettier-ignore
type Props = {
	/** If is open */
	open: boolean,
	/** User menu data. User object: DATA[json/user/user.json] */
	user: {
		id: string,
		organization: string,
		name: string,
		email: string,
		organizations: Array<{ name: string, ... }>,
		...
	},
	/** Support menu data. Support object: DATA[json/support/support.json] */
	support?: {
		site: { active: boolean, link: boolean },
		chat: { active: boolean },
		knowledgeBase: { active: boolean },
		feedback: { active: boolean }
	},
	/** Position, with and heigh of dropdown menu */
	style: { [key: string]: string },
	/** Object of properties for Popup */
	popup?: PopupType,
	/** Called on feedback submit. */
	onFeedbackOpen?: () => void,
	/** Called on menu open. */
	onOpen?: (
		event: SyntheticEvent<>,
		data: { id: string }
	) => void,
	/** Called on menu close. */
	onClose?: (
		event: SyntheticEvent<>,
		data: { id: string }
	) => void
};

const DEFAULTS = {
	open: false
};

const ID = 'support';

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/dropdown/ and https://react.semantic-ui.com/collections/menu/ */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	// Render nothing if empty
	if (!props.support) {
		return null;
	}

	const isMobile =
		document.querySelector('html').classList.contains('mobile') ||
		document.querySelector('html').classList.contains('tablet');

	const getSupport = () => {
		let options = [];

		if (props.support.site && props.support.site.active && props.support.site.link) {
			options.push(
				<Dropdown.Item
					as='a'
					href={props.support.site.link}
					target='_blank'
					icon='fa-memo-circle-info'
					text={<FormattedMessage id='component.support-menu.site.title' />}
				/>
			);
		}

		if (props.support.knowledgeBase && props.support.knowledgeBase.active && Gainsight.getKey()) {
			options.push(
				<Dropdown.Item
					icon='fa-question-circle'
					text={<FormattedMessage id='component.support-menu.knowledgeBase.title' />}
					onClick={() => {
						Zendesk.closeChat();
						Gainsight.openBot();
					}}
				/>
			);
		}

		if (props.support.chat && props.support.chat.active && Zendesk.getKey()) {
			options.push(
				<Dropdown.Item
					icon='fa-comments-question'
					text={<FormattedMessage id='component.support-menu.chat.title' />}
					onClick={() => {
						Gainsight.closeBot();
						Zendesk.openChat();
					}}
				/>
			);
		}

		if (props.support.feedback && props.support.feedback.active) {
			options.push(<Dropdown.Divider />);
			options.push(
				<Dropdown.Item
					icon='fa-send'
					text={<FormattedMessage id='component.support-menu.sendFeedback.title' />}
					onClick={props.onFeedbackOpen}
				/>
			);
		}

		return options;
	};

	const handleOpen = (event: ?SyntheticEvent<>, data: any) => {
		if (typeof props.onOpen === 'function') {
			props.onOpen(event, handleCallbackData(data));
		}
	};

	const handleClose = (event: ?SyntheticEvent<>, data: any) => {
		if (typeof props.onClose === 'function') {
			props.onClose(event, handleCallbackData(data));
		}
	};

	// Handle data returned by onClose callback.
	const handleCallbackData = (data: any) => ({
		id: data.id
	});

	let menu = (
		<Dropdown
			id={`${ID}Menu`}
			tabIndex={false}
			closeOnBlur={false}
			open={props.open}
			item
			icon={
				<Icon
					name={isMobile && props.open ? 'fa-close' : 'fa-question'}
					size={isMobile && props.open ? undefined : 'large'}
					circular={isMobile && props.open ? true : false}
				/>
			}
			onOpen={handleOpen}
			onClose={handleClose}
		>
			<Dropdown.Menu id={`${ID}Dropdown`} style={props.style}>
				{props.open ? getSupport() : null}
			</Dropdown.Menu>
		</Dropdown>
	);

	// Attach popup
	return !props.open
		? Popup.attach({ text: <FormattedMessage id='component.support-menu.name' />, ...props.popup }, menu)
		: menu;
});

// Documentation generation support
Component.displayName = 'SupportMenu';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
