/**
 * @prettier
 * @flow
 */

import { useLocation } from 'react-router-dom';

// prettier-ignore
type Props = {
	/** Content as children. */
	children: React.Node,
	/** Mode for content width */
	mode: 'narrow' | 'wide',
	/** Regexp array for full width application pages. */
	widePages: Array<RegExp>
};

const DEFAULTS = {
	mode: 'wide'
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/icon */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	let width = 'wide';
	if (props.mode === 'narrow' && !props.widePages.some((regex) => regex.test(useLocation().pathname))) {
		width = 'narrow';
	}

	return (
		<section className='main-content' id='main-content'>
			<div className={width} id='width-wrapper'>
				{props.children}
			</div>
		</section>
	);
});

// Documentation generation support
Component.displayName = 'PageWrapper';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
