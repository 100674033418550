import UAParser from 'ua-parser-js'; // https://github.com/faisalman/ua-parser-js
import enquire from 'enquire.js';
import BREAKPOINTS from './Constants';

let html = document.querySelector('html');

// Dak mode handler
function darkMode(e) {
    if (e.ctrlKey && e.altKey && e.key === 'd') {
		if(html.classList.contains('dark-mode')) {
			html.classList.remove('dark-mode');
		} else {
			html.classList.add('dark-mode');
		}
    }
}

// Register dark mode handler 
document.addEventListener('keyup', darkMode, false);

function agent() {
	return new UAParser((navigator && navigator.userAgent) ? navigator.userAgent : null);
}

export default {
	ua: () => agent().getResult(),
	name: () => agent().getBrowser().name,
	version: () => agent().getBrowser().version,
	register: (query, handler) => enquire.register(query, handler),
	unregister: (query) => enquire.unregister(query),
	detectPointer: () => {
		let hover = '(hover: hover) and (pointer: fine)',
		anyHover = '(any-hover: on-demand), (any-hover: none), (any-pointer: coarse), (any-pointer: none)';
		if (window && window.matchMedia(hover).matches && !window.matchMedia(anyHover).matches) {
			html.classList.add('pointer-device'); // Devices that have pointer as primary pointing device
		}
	},
	detectBreakpoints: () => {
		let min = 0, max = 0;
		for (let bp in BREAKPOINTS) {
			max = BREAKPOINTS[bp];
			enquire.register(`screen and (min-width: ${min}px) and (max-width: ${max - 1}px)`, {
				match: () => html.classList.add(bp),
				unmatch: () => html.classList.remove(bp)
			});
			min = BREAKPOINTS[bp];
		}
	},
	is: {
		IE: (legacy = false) => {
			let browser = agent().getBrowser(),
				version = -1;
			if (typeof browser.version === 'string') {
				version = browser.version.split('.').shift();
			}
			return (legacy === true)
				? (browser.name === 'IE' && parseInt(version, 10) <= 11)
				: (browser.name === 'IE');
		},
		Edge: () => {
			let browser = agent().getBrowser();
			return (browser.name === 'Edge');
		},
		Chrome: () => {
			let browser = agent().getBrowser();
			return (browser.name.indexOf('Chrome') > -1 || browser.name.indexOf('Chromium') > -1);
		},
		Safari: () => {
			let browser = agent().getBrowser();
			return (browser.name === 'Safari' || browser.name === 'Mobile Safari');
		},
		Mobile: () => {
			let device = agent().getDevice();
			return device.model === 'mobile' || device.model === 'tablet';
		},
		Desktop: () => {
			let device = agent().getDevice();
			return device.model !== 'mobile' && device.model !== 'tablet';
		}
	}
};
