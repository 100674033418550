/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { Placeholder } from 'semantic-ui-react';
import PlaceholderImage from './PlaceholderImage';
import { Visibility } from 'liana-ui/components';

// prettier-ignore
type Props = {
	/** Primary content. */
	children: React.Node,
	/** Additional classes. */
	className?: string,
	/** A placeholder can have an error. */
	error?: boolean,
	/** A placeholder can have their colors inverted. */
	inverted?: boolean,
	/** Element's top edge has passed bottom of screen. */
	onTopVisible: () => void
};

const DEFAULTS = {
	error: false,
	inverted: false
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/placeholder/ */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	// Assign placeholder
	let classes = classNames(
		{
			error: props.error
		},
		props.className
	);

	let placeholder = (
		<Placeholder className={classes}>
			{props.children}
		</Placeholder>
	);

	// Add responsive wrapper
	if (typeof props.onTopVisible === 'function') {
		placeholder = (
			<Visibility as={React.Fragment} onTopVisible={props.onTopVisible}>
				{placeholder}
			</Visibility>
		);
	}

	return placeholder;
});

// Documentation generation support
Component.displayName = 'Placeholder';
Component.defaultProps = DEFAULTS;

// Attach Subcomponents
Component.Image = PlaceholderImage;

export type { Props };
export default Component;
