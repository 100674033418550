/**
 * @prettier
 * @flow
 */

import { Segment, Header, Divider, Text } from 'liana-ui/components/';

// prettier-ignore
type Props = {
	/** Item can be a link to the item. */
	link?: string,
	/** Item should have have a type. */
	type: string,
	/** Item should have a header. */
	header: string | IntlComponent,
	/** Item can have a sub header. */
	subheader?: string | React.Node,
	/** Item can have a sub header. */
	subinfo?: string | React.Node | Array<React.Node>,
	/** Item content. */
	content?: React.Node
};

const DEFAULTS = {};

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/segment/ */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	let item = (
		<Segment raised removePaddings='all' link={props.link}>
			<Segment basic compressed size='small' removeMargins='all' className='content'>
				{props.header && (
					<Header as='h3' text={props.header} subheader={props.subheader} subinfo={props.subinfo} />
				)}
				{props.content}
			</Segment>
			<Divider removeMargins='all' />
			<Segment basic compressed size='small' removeMargins='all' className='type'>
				{props.type}
			</Segment>
		</Segment>
	);

	return item;
});

// Documentation generation support
Component.displayName = 'SuggestedItem';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
