/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { Icon, Checkbox } from 'liana-ui/components/';
import type { Props as CheckboxProps } from 'liana-ui/components/checkbox/Checkbox';
import type { Props as PopupProps } from 'liana-ui/components/popup/Popup';

type Props = {
	/** Form label text. */
	text: string,
	/** Classes for label element */
	classes?: string,
	/** A form field can have labels */
	labels?: React.Node,
	/**
		A header can have a checkbox, radio or toggle.
		PROPS[/components/forms/checkbox]
	*/
	checkbox?: CheckboxProps,
	/** Text or object of properties for help Popup */
	help?: string | FormattedMessage | PopupProps
};

/** COMPONENT BASED ON: https://fomantic-ui.com/collections/form.html#field */
const Component = React.memo<Props>((props: Props) => {
	let label = props.checkbox ? (
		<div className={classNames('field-label', props.classes)}>
			<span className='text'>
				<Checkbox {...props.checkbox} label={props.text} key='checkbox' />
			</span>
			{props.help ? <Icon name='fa-question-circle cursor-help' color='primary' popup={props.help} /> : null}
			{props.labels ? props.labels : null}
		</div>
	) : (
		<label className={props.classes}>
			<span className='text'>{props.text}</span>
			{props.help ? <Icon name='fa-question-circle cursor-help' color='primary' popup={props.help} /> : null}
			{props.labels ? props.labels : null}
		</label>
	);

	return label;
});

// Documentation generation support
Component.displayName = 'FormLabel';

export type { Props };
export default Component;
