/**
 * @prettier
 * @flow
 */
import { ListContent } from 'semantic-ui-react';

// prettier-ignore
type Props = {
	/** Detail as children. */
	children: React.Node,
	/** An list content can be floated left or right. */
	floated?: 'left' | 'right'
};

const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => (
	<ListContent floated={props.floated}>{props.children}</ListContent>
));

Component.displayName = 'ListContent';

export type { Props };
export default Component;
