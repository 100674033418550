/**
 * @prettier
 * @flow
 */

import { injectIntl, FormattedMessage } from 'react-intl';
import Icon from 'liana-ui/legacy/components/icon/Icon';
import Button from 'liana-ui/legacy/components/button/Button';

type Props = {
	intl: Intl,
	/** Extra UI content */
	children?: React.Node,
	/** Title text */
	title: string,
	/** Description text */
	description: string,
	/** Target width */
	width: number,
	/** Target height */
	height: number
};

class ImageEditorTopActions extends React.PureComponent<Props> {
	constructor(props: Props) {
		super(props);
	}

	static defaultProps = {
		height: 0,
		width: 0
	};

	render() {
		let extra = ` ${this.props.width}x${this.props.height}`; // Convenience
		return (
			<div className='no-image-wrapper'>
				<div className='no-image-content'>
					<h4 className='title text-center'>
						{this.props.title ? (
							this.props.title
						) : (
							<FormattedMessage id='component.image-editor.general.dndImage' />
						)}
					</h4>
					<p>
						<Button classes='circular tiny'>
							<Icon classes='folder open' />
							<FormattedMessage id='component.image-editor.general.browseFile' />
						</Button>
					</p>
					<p className='description text-smaller-10 text-center'>
						{this.props.description
							? this.props.description
							: this.props.intl.formatMessage({
									id: 'component.image-editor.general.minimum_image_size'
							  }) + extra}
					</p>
					{this.props.children}
				</div>
			</div>
		);
	}
}

let Wrapper = injectIntl(ImageEditorTopActions, { forwardRef: true });
Wrapper.displayName = 'ImageEditorTopActions';
export default Wrapper;
