/**
 * @prettier
 * @flow
 */

import { FormattedMessage } from 'react-intl';
import { Message, Segment } from 'liana-ui/components';

const Component = React.memo(() => {
	return (
		<Segment basic>
			<Message
				error
				layout='big'
				header={<FormattedMessage id='notifications.access_denied' />}
				content={<FormattedMessage id='notifications.no_customer_warning' />}
			/>
		</Segment>
	);
});

Component.displayName = 'NoCustomerView';
export default Component;
