let key = null;

const init = (config, user) => {
	if (typeof zE !== 'function' && config.ZENDESK_KEY && user) {
		key = config.ZENDESK_KEY;
		const script = document.createElement('script');
		script.async = true;
		script.id = 'ze-snippet';
		script.src = `https://static.zdassets.com/ekr/snippet.js?key=${config.ZENDESK_KEY}`;
		document.body.appendChild(script);
		script.onload = () => {
			window.zESettings = getSettings(config);
			setLanguage(user.language);

			// Hide button when wdget is closed
			if(!config.ZENDESK_BUTTON) {
				zE('webWidget:on', 'close', function() {
					closeChat();
				});
			}
			// Make room for button
			if (config.ZENDESK_BUTTON) {
				document.querySelector('body').setAttribute('data-bar', 'true');
			}
		};
	} else {
		console.error('Please pass config with config.ZENDESK_KEY and user object to Zendesk.init(config, user); function.');
	}
};

const getSettings = (config) => {
	let settings = {
		webWidget: {
			chat: {
				suppress: config.ZENDESK_BUTTON ? false : true
			},
			contactForm: {
				suppress: config.ZENDESK_BUTTON ? false : true
			},
			helpCenter: {
				suppress: true
			},
			talk: {
				suppress: true
			},
			answerBot: {
				suppress: true
			}
		}
	};
	return settings;
};

const openChat = () => {
	if (typeof zE === 'function') {
		updateSettings({
			webWidget: {
				chat: {
					suppress: false
				},
				contactForm: {
					suppress: false
				}
			}
		});
		zE(function() {
			zE.activate();
		});
	} else {
		error();
	}
};

const closeChat = () => {
	if (typeof zE === 'function') {
		updateSettings({
			webWidget: {
				chat: {
					suppress: true
				},
				contactForm: {
					suppress: true
				}
			}
		});
	} else {
		error();
	}
};

const setLanguage = (language) => {
	if (typeof zE === 'function') {
		zE('webWidget', 'setLocale', language);
	} else {
		error();
	}
};

const updateSettings = (settings) => {
	if (typeof zE === 'function') {
		zE('webWidget', 'updateSettings', settings);
	} else {
		error();
	}
};

const getKey = () => {
	return key; 
};

const error = () => {
	console.error('Please init Zendesk by adding this to App.js: Zendesk.init(config, user);');
}

export default {
 	init: (config, user) => init(config, user),
	setLanguage: (language) => setLanguage(language),
	updateSettings: (settings) => updateSettings(settings),
	getKey: () => getKey(),
	openChat: () => openChat(),
	closeChat: () => closeChat()
 };
