/**
 * @prettier
 * @flow
 */

/** TODO: THIS COMPONENT IS INCOMPLETE AND IN PROTOTYPE USAGE! DO NOT USE IT IN PRODUCTION! */

// prettier-ignore
type Props = {
	/** Array of extra components to place in the trail bar */
	tools: Array<React.Node>
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/collections/breadcrumb/ */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	// Generate other custom elements alongside the trail
	const getTools = () => {
		// Place tool components on right side of trail bar in corrrect wrappers
		let elements = [];
		if (Array.isArray(props.tools) && props.tools.length > 0) {
			for (let i = 0; i < props.tools.length; i++) {
				if (React.isValidElement(props.tools[i])) {
					elements.push(<div className='actions-wrapper-item'>{props.tools[i]}</div>);
				}
			}
		}
		return elements;
	};

	return Array.isArray(props.tools) && props.tools.length > 0 ? (
		<div className='actions-wrapper'>{getTools()}</div>
	) : null;
});

// Documentation generation support
Component.displayName = 'TrailTool';
Component.defaultProps = { tools: [] };

export type { Props };
export default Component;
