/**
 * @prettier
 * @flow
 */

import { Grid, Segment, Statistic } from 'liana-ui/components';
import type { Props as StatisticProps } from 'liana-ui/components/statistic/Statistic';

// prettier-ignore
type Props = {
	/**
		A statistic group can have multiple statistics as props object for Stattistic component.
		PROPS[StatisticProps=/components/statistics/statistic/statistic/]
	*/
	items: Array<StatisticProps>,
	/**
		Represents column count per row in Grid.
		VALUES['equal' | 1 - 12]
	*/
	columns?: string | number,
	/** A static group can have different sizes. */
	size?: 'tiny' | 'small' | 'large'
};

const DEFAULTS = {
	columns: 3
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/views/statistic */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	const getColumns = () => {
		let columns = [];

		if (props.items && Array.isArray(props.items)) {
			props.items.map((item) => {
				columns.push(
					<Grid.Column textAlign='center'>
						<Segment raised link={item.link}>
							<Statistic size={props.size} {...item} />
						</Segment>
					</Grid.Column>
				);
			});
		}

		return columns;
	};

	let statistics = (
		<Grid stretched stackable columns={props.columns}>
			{getColumns()}
		</Grid>
	);

	return statistics;
});

// Documentation generation support
Component.displayName = 'Statistic.Group';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
