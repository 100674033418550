/**
 * @prettier
 * @flow
 */

import { useNavigate } from 'react-router-dom';
import Validate from 'liana-ui/definitions/component/Validate';
import { Popup } from 'liana-ui/components/';

// prettier-ignore
type Props = {
	/** Detail as children. */
	children: React.Node,
	/** Props object for Popup */
	popup?: FormattedMessage | string | PopupProps,
	/** Item can be a link. Opens public links (http:// or https://) to new browser tab and internal links via routing */
	link?: string,
	/** Item link can have a target. Default _blank. */
	linkTarget?: string,
	/** List link can be clicked. */
	onClick?: (event: SyntheticEvent<>) => void
};

const DEFAULTS = {
	linkTarget: '_blank'
};

const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	const navigate = useNavigate();
	let linkType = Validate.linkType(props.link); // Check link type

	const handleClick = (event) => {
		// Trigger onClick callback funtion
		if (typeof props.onClick === 'function') {
			props.onClick(event);
		}

		// Trigger internal link
		if (linkType && (linkType === 'internal' || linkType === 'anchor')) {
			event.preventDefault();
			if (linkType === 'internal') {
				navigate(props.link);
			}
			if (linkType === 'anchor') {
				Safely.scroll(props.link, () => {
					navigate(props.link);
				});
			}
		}
	};

	let link = (
		<a
			href={props.link || props.onClick ? (props.link ? props.link : '#') : undefined}
			target={linkType === 'external' ? props.linkTarget : undefined}
			rel={linkType === 'external' ? 'noopener noreferrer' : undefined}
			onClick={handleClick}
		>
			{props.children}
		</a>
	);

	// Attach popup
	return Popup.attach(props.popup, link);
});

// Documentation generation support
Component.displayName = 'ListLink';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
