// https://www.sohamkamani.com/blog/javascript-localstorage-with-ttl-expiry/

/**
 * Store data on the user's browser. Non-cookie storage is read-only and won't transfer data, and has less support for older browsers.
 * Local storage: Does not automatically expire.
 * Session storage: Cleared when tab closes.
 * Cookies: Limited storage capacity; Sent with every request; Expire based on settings & tabs; Can transfer data to server; Supported by all browsers
 */

let hasLocalStorage = function(key) {
	let check = window && window.localStorage;
	if (!check) {
		console.warn("No local storage detected."); // eslint-disable-line
	}
	return (key && check)
		? window.localStorage.getItem(key)
		: check;
};

let hasSessionStorage = function(key) {
	let check = window && window.sessionStorage;
	if (!check) {
		console.warn("No session storage detected."); // eslint-disable-line
	}
	return (key && check)
		? window.sessionStorage.getItem(key)
		: check;
};

let hasExpired = (item) => {
	item = JSON.parse(item);
	if (item && typeof item === 'object' && item.hasOwnProperty('expires') && typeof item.expires === 'number') {
		const now = new Date();
		return now.getTime() > item.expires;
	}
	return false;
}

let setExpiry = (value, expires = 0) => {
	let item = value;
	if (typeof expires === 'number') {
		let now = new Date();
		item = JSON.stringify({
			value: value,
			expires: (expires === 0) ? 0 : now.getTime() + expires
		});;
	}
	return item;
}

let localStorage = {
	setItem: (key, value) => hasLocalStorage() && window.localStorage.setItem(key, value),
	getItem: (key) =>  hasLocalStorage() && window.localStorage.getItem(key),
	removeItem: (key) => hasLocalStorage() && window.localStorage.removeItem(key),
	setExpiringItem: (key, value, expires) => {
		let item = setExpiry(value, expires);
		return hasLocalStorage() && window.localStorage.setItem(key, item);
	},
	getExpiringItem: (key) => {
		let item = hasLocalStorage(key);
		if (hasExpired(item)) {
			window.localStorage.removeItem(key);
			return null;
		}
		return item;
	}
};

let sessionStorage = {
	setItem: (key, value) => hasSessionStorage() && window.sessionStorage.setItem(key, value),
	getItem: (key) =>  hasSessionStorage() && window.sessionStorage.getItem(key),
	removeItem: (key) => hasSessionStorage() && window.sessionStorage.removeItem(key),
	setExpiringItem: (key, value, expires) => {
		let item = setExpiry(value, expires);
		return hasSessionStorage() && window.sessionStorage.setItem(key, item);
	},
	getExpiringItem: (key) => {
		let item = hasSessionStorage(key);
		if (hasExpired(item)) {
			window.sessionStorage.removeItem(key);
			return null;
		}
		return item;
	}
};

export { localStorage, sessionStorage };
