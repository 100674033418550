/**
 * @prettier
 * @flow
 */

import { PopupContent } from 'semantic-ui-react';

// prettier-ignore
type Props = {
	/** Content as children. */
	children: React.Node
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/popup/ */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => (
	<PopupContent content={props.children} />
));

Component.displayName = 'PopupContent';

export type { Props };
export default Component;
