/**
 * @prettier
 * @flow
 */

import { useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { Transition } from 'semantic-ui-react';
import TransitionGroup from './TransitionGroup';

// prettier-ignore
type Props = {
	/** Key */
	reactKey: string,
	/** Primary content. */
	children: React.Node,
	/** Show the component. Triggers the enter or exit animation. */
	visible?: boolean,
	/** Run the enter animation when the component mounts, if it is initially shown. */
	transitionOnMount?: boolean,
	/** Unmount the component (remove it from the DOM) when it is not shown. */
	unmountOnHide?: boolean,
	/** Named animation event to used. Must be defined in CSS. */
	animation?: 'height' | 'fade' | 'fade up' | 'fade down' | 'fly right' | 'scale' | 'slide up' | 'slide down' | 'pulse' | 'glow',
	/** Duration of the CSS transition animation in milliseconds. */
	duration?: number,
	/** Delay of the CSS transition animation in milliseconds. */
	delay?: number,
	/** Callback on each transition that changes visibility to hidden. */
	onHide?: () => void,
	/** Callback on each transition that changes visibility to shown. */
	onShow?: () => void,
	/** Callback on each transition that is complete. */
	onComplete?: () => void
};

const DEFAULTS = {
	visible: false,
	transitionOnMount: false,
	animation: 'fade',
	duration: 250,
	delay: 0
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/transition/ */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	let [delayedVisible, setDelayedVisible] = useState(false);

	// componentDidMount
	useEffect(() => {
		/* istanbul ignore else */
		if (props.delay) {
			setTimeout(() => setDelayedVisible(true), props.delay);
		}
	}, []);

	return props.animation === 'height' ? (
		<AnimateHeight
			key={props.reactKey}
			animateOpacity
			easing='ease-in-out'
			duration={props.duration}
			delay={props.delay}
			height={props.visible ? 'auto' : 0}
			onAnimationEnd={props.onComplete}
		>
			{props.children}
		</AnimateHeight>
	) : (
		<Transition
			key={props.reactKey}
			visible={props.delay ? delayedVisible : props.visible}
			transitionOnMount={props.transitionOnMount}
			unmountOnHide={props.unmountOnHide}
			animation={props.animation}
			duration={props.duration}
			delay={props.delay}
			onHide={props.onHide}
			onShow={props.onShow}
			onComplete={props.onComplete}
		>
			{props.children}
		</Transition>
	);
});

// Documentation generation support
Component.displayName = 'Transition';
Component.defaultProps = DEFAULTS;

// Attach Subcomponents
Component.Group = TransitionGroup;

export default Component;
