/**
 * @prettier
 * @flow
 */

import { useState, useRef } from 'react';
import SketchPicker from 'react-color/lib/components/sketch/Sketch';
import { Input, Label, Grid, Button } from 'liana-ui/components';

// prettier-ignore
type Props = {
	/** A color input must have an input name */
	name: string,
	/** Initial value for input. Use for uncontrolled components only. VALUES['#FF0000', '#FFF...'] */
	defaultValue?: string,
	/** Current value for input. Use for controlled components only. VALUES['#FF0000', '#FFF...'] */
	value?: string,
	/** An color input can have a placeholder text. Use intl.formatMessage() for translated strings. */
	placeholder?: string,
	/** A color input can be clearable */
	clearable?: boolean,
	/** A color input can be locked to indicate that the field is in use but can not be edited. */
	locked?: boolean,
	/** An color input can be  disabled. */
	disabled?: boolean,
	/** A color input can be different size. */
	size?: 'small' | 'large',
	/** A color input can enable alpha (opacity) channel. */
	enableAlpha?: boolean,
	/** Function called on any color input change. */
	onChange?: (
		event: SyntheticEvent<>,
		data: {
			name: string,
			value: string
		}
	) => void
};

const DEFAULTS = {
	clearable: false,
	locked: false,
	disabled: false,
	enableAlpha: false
};

// Component render conditions. True if should not render.
const EQUALS = (prevProps, nextProps) =>
	prevProps.value === nextProps.value &&
	prevProps.disabled === nextProps.disabled &&
	prevProps.locked === nextProps.locked;

/** COMPONENT BASED ON: https://casesandberg.github.io/react-color/ */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	// Variables and refs
	const inputRef = useRef(null);
	const presetColors = [
		'#4d8931',
		'#f5c400',
		'#f18e1c',
		'#e32322',
		'#6d398b',
		'#2a71b0',
		'#006f47',
		'#425b12',
		'#cb6306',
		'#892e0f',
		'#62013b',
		'#202549',
		'#03465b',
		'#263238',
		'#546e7a',
		'#78909c'
	];

	// Internal states
	let [internalValue, setInternalValue] = useState(props.defaultValue);
	const value = props.value === undefined ? internalValue : props.value;

	// Called on input change.
	const handleChange = (data: any, event: ?SyntheticEvent<>) => {
		// Set current value internally
		setInternalValue(data.hex);

		// Trigger onChange callback with formatted data
		if (typeof props.onChange === 'function') {
			props.onChange(event, handleCallbackData(data));
		}

		// Trigger Form onChange on value change
		if (inputRef) {
			inputRef.current.dispatchEvent(new Event('change', { bubbles: true }));
		}
	};

	// Handle data returned by callbacks.
	const handleCallbackData = (data: any) => ({
		name: props.name,
		value: data.hex
	});

	const handleClear = (e) => {
		handleChange({ hex: '' }, e);
	};

	const isClearButtonShown = props.clearable && !(!value || props.disabled || props.locked);

	// Field clear button
	const clearButton = isClearButtonShown ? (
		<Button
			icon={{ name: 'fa-remove', solid: true }}
			solid
			name='color-input-reset-button'
			circular
			size='extramini'
			onClick={handleClear}
		/>
	) : null;

	let input = (
		<Grid verticalAlign='middle'>
			<Grid.Column collapsing>
				<Label empty fitted circular size='massive' floated='left' color={value ? value : null} />
			</Grid.Column>
			<Grid.Column fluid>
				<div className='color-input-wrapper'>
					{clearButton}
					<Input
						ref={inputRef}
						name={props.name}
						placeholder={props.placeholder}
						value={value}
						locked={props.locked}
						disabled={props.disabled || props.locked}
						readOnly
						size={props.size}
						icon='eyedropper'
						popup={{
							content: (
								<SketchPicker
									disableAlpha={!props.enableAlpha}
									presetColors={presetColors}
									color={value === null ? '' : value}
									onChange={handleChange}
								/>
							),
							on: 'click',
							position: 'bottom left'
						}}
					/>
				</div>
			</Grid.Column>
		</Grid>
	);

	return input;
}, EQUALS);

// Documentation generation support
Component.displayName = 'ColorInput';
Component.defaultProps = DEFAULTS;

export default Component;
