/**
 * @prettier
 * @flow
 */

import { LabelGroup } from 'semantic-ui-react';
import { Label } from 'liana-ui/components/';
import type { Props as LabelProps } from 'liana-ui/components/label/Label';
import type { Props as LimitLabelProps } from 'liana-ui/components/label/LimitLabel';

// prettier-ignore
type Props = {
	/**
		A label group can have multiple label components.
		PROPS[LabelProps=/components/labels/labels/label/, LimitLabelProps=/components/labels/limit-label/]
	*/
	labels: Array<LabelProps> | LimitLabelProps,
	/** A label group can be rendered as different HTML elements. */
	as?: 'div' | 'p',
	/** A label group can have different sizes. */
	size?: 'mini' | 'tiny'
};

const DEFAULTS = {
	as: 'div',
	size: 'small'
};
// Note: Trying to load LabelGroup separately without Label will cause a cyclic dependency error
/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/label/#groups */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	if (props.labels) {
		return Array.isArray(props.labels) ? (
			<LabelGroup as={props.as} size={props.size}>
				{props.labels && Array.isArray(props.labels)
					? props.labels.map((label, index) => {
							if (label.upgradeLink || (label.limit && label.onClick)) {
								return <Label.Limit {...label} key={index} size={props.size} />;
							} else {
								return <Label {...label} key={index} size={props.size} />;
							}
					  })
					: null}
			</LabelGroup>
		) : (
			<Label {...props.labels} />
		);
	}
	return null;
});

// Documentation generation support
Component.displayName = 'Label.Group';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
