import { merge } from 'lodash';

// Legacy auth dashboard helper script
export default {
	init: (url, config = {}, autoPrefix = true) => {
		if (typeof window.Liana !== 'object') {
			window.Liana = {};
		}
		window.Liana = merge(window.Liana, config);
		let now = new Date().getTime() / 1000,
			script = document.createElement('script');
		script.src =
			(autoPrefix === false)
				? url
				: url + '/static/board/app.js?_=' + (now - (now % 30)); // Timestamp postfix
		script.id = 'dashboard-script';
		document.body.appendChild(script);
	}
}
