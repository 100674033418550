/**
 * @prettier
 * @flow
 */

import { Segment, Header, Text } from 'liana-ui/components/';

// prettier-ignore
type Props = {
	/** Segment can be a link. Opens absolute links in new browser tab and internal links via router. */
	link?: string,
	/** Segment should have a header. */
	header: string,
	/** 
	 	Segment header can have a icon. 
	 	PROPS[IconProps=/components/labels/icons/icon]
	*/
	icon?: string | IconProps | IconGroupProps,
	/** 
	 	Segment header can have a icon group. 
	 	PROPS[IconGroupProps=/components/labels/icons/icon-group]
	*/
	iconGroup?: IconGroupProps,
	/** Segment header can have labels. */
	labels:
		LabelProps
		| Array<LabelProps>,
	/** Segment content. */
	content?: React.Node,
	/** Called on click. */
	onClick?: (
		event: SyntheticEvent<>
	) => void,
	/** Adds upsell icon to the item */
	upsell?: boolean
};

const DEFAULTS = {};

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/segment/ */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	let item = (
		<Segment raised link={props.link} onClick={props.onClick}>
			{props.header && (
				<Header
					as='h3'
					text={props.header}
					icon={props.icon}
					iconGroup={props.iconGroup}
					labels={props.labels}
					upsell={props.upsell}
				/>
			)}
			<Text as='p'>{props.content}</Text>
		</Segment>
	);

	return item;
});

// Documentation generation support
Component.displayName = 'SegmentMenuItem';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
