/**
 * @prettier
 * @flow
 */

import { FormattedNumber, FormattedTime, FormattedDate } from 'react-intl';
import { Icon, Popup, Text, Grid } from 'liana-ui/components/';
import type { IntlComponent } from 'react-intl';

// prettier-ignore
type Props = {
	/** A trend must have trend/change as number. No value will show an indeterminate info icon. */
	value: number,
	/** A trend can display an icon. */
	icon?: boolean,
		/**
		Numbr description text or, react-intl component.
		PROPS[IntlComponent=/localization/]
	*/
	popup?: string | IntlComponent,
	/** A trend popoup can have a start date in ISO 8601. VALUES[YYYY-MM-DDTHH:MM:SS] */
	startDate?: string,
	/** A trend popoup can have a end date in ISO 8601. VALUES[YYYY-MM-DDTHH:MM:SS] */
	endDate?: string,
	/** A trend popoup can display only date or date and time. */
	displayTime?: boolean,
	/** A trend can have diffrent notation and formatting. */
	notation?: 'percentage' | 'compact'
};

const DEFAULTS = {
	icon: true,
	displayTime: true
};

let Component = (props: Props) => {
	let icon = 'arrow ',
		rotate = undefined,
		value = props.value,
		char = '',
		statistic = null;

	const getRange = () => {
		let range = '';
		if (props.displayTime) {
			range = (
				<>
					<FormattedTime value={props.startDate} day='numeric' month='short' year='numeric' /> &ndash;{' '}
					<FormattedTime value={props.endDate} day='numeric' month='short' year='numeric' />
				</>
			);
		} else {
			range = (
				<>
					<FormattedDate value={props.startDate} day='numeric' month='short' year='numeric' /> &ndash;{' '}
					<FormattedDate value={props.endDate} day='numeric' month='short' year='numeric' />
				</>
			);
		}
		return range;
	};

	// Catch out-of-bounds and indeterminant values
	if (isNaN(value) || typeof value !== 'number') {
		icon = 'circle info';
		statistic = '-';
	} else if (!props.notation || props.notation === 'compact') {
		switch (true) {
			case value < 0:
				icon += 'down';
				rotate = -45;
				break;
			case value > 0:
				icon += 'up';
				rotate = 45;
				break;
			case value === 0:
				icon += 'right';
				break;
		}

		statistic =
			props.notation === 'compact' ? (
				<FormattedNumber value={value} notation='compact' compactDisplay='short' />
			) : (
				<FormattedNumber value={value} />
			);
	} else if (props.notation === 'percentage') {
		value = Math.round(value);

		// Determine trend icon
		switch (true) {
			case value <= -100:
				icon += 'down';
				break;
			case value <= -5 && value > -100:
				icon += 'down';
				rotate = -45;
				break;
			case value > -5 && value < 5:
				icon += 'right';
				break;
			case value >= 5 && value < 100:
				icon += 'up';
				rotate = 45;
				break;
			case value >= 100:
				icon += 'up';
				break;
		}

		if (value > 999) {
			char = '>';
			value = 999;
		} else if (value < -999) {
			char = '<';
			value = -999;
		}

		statistic = (
			<>
				{char} <FormattedNumber value={value / 100} style='percent' />
			</>
		);
	}

	if (props.icon) {
		statistic = (
			<>
				{statistic} <Icon name={icon} rotate={rotate} />
			</>
		);
	}

	statistic = <span className={`statistic-trend nowrap${props.popup ? ' cursor-help' : ''}`}>{statistic}</span>;

	if (props.popup && props.value !== undefined) {
		props.popup = {
			wide: 'very',
			content: (
				<Grid>
					<Grid.Column width={6} verticalAlign='middle'>
						<Text as='div' singleLine>
							{props.popup}
							{props.startDate && props.endDate ? (
								<Text as='div' size='small'>
									{getRange()}
								</Text>
							) : null}
						</Text>
					</Grid.Column>
					<Grid.Column width={6} verticalAlign='middle' textAlign='right'>
						<Text as='div' size='large' bold singleLine>
							{statistic}
						</Text>
					</Grid.Column>
				</Grid>
			)
		};
	}

	return <>{Popup.attach(props.popup, statistic)}</>;
};

// Documentation generation support
Component.displayName = 'Statistic.Trend';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
