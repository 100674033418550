/* istanbul ignore file */
/**
 * @prettier
 */

// OBSOLETE COMPONENT - https://reactrouter.com/en/6.9.0/routers/picking-a-router
// Scroll restoration helper component
// https://reactrouter.com/web/guides/scroll-restoration

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver/observe
const CONFIG = {
	childList: true,
	subtree: true
};

let Component: React.AbstractComponent = React.memo(() => {
	const { pathname, hash } = useLocation();
	const findLink = (hash) => document.getElementById(hash.replace('#', ''));

	useEffect(() => {
		let observer = null,
			link = null;
		if (hash) {
			link = findLink(hash);
			if (link) {
				link.scrollIntoView(); // Element visible - Scroll to it
			} else {
				// Element not visible - Set up a Mutation Observer - https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver
				observer = new MutationObserver((mutations, instance) => {
					link = findLink(hash);
					if (link) {
						link.scrollIntoView();
						instance.disconnect();
						return true;
					}
				});
				// Start observing
				observer.observe(document.body, CONFIG);
			}
		} else {
			if (observer) {
				observer.disconnect();
				observer = null;
			}
			window.scrollTo(0, 0);
		}
	}, [pathname, hash]);

	return null;
});

Component.displayName = 'Scroll';

export default Component;
