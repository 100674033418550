/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import type { Tooltip } from 'liana-ui/definitions/component/Types';

type Props = {
	/** Content to place inside .ui.segment element */
	children?: React.Node,
	/** ID for .ui.segment element */
	id?: string,
	/** Classes for .ui.segment element */
	classes?: string,
	/** Styles for .ui.segment element */
	style?: {},
	/** Tooltip (data-content, data-position, data-delay) */
	tooltip?: Tooltip,
	/** Callback when clicking segment */
	onClick?: (event: ?SyntheticEvent<>) => mixed
};

/** COMPONENT BASED ON: https://fomantic-ui.com/elements/segment.html */
export default class Segment extends React.PureComponent<Props> {
	constructor(props: Props) {
		super(props);
	}
	static defaultProps = {
		onClick: () => {}
	};
	render() {
		let classes = classNames('ui segment', { 'popup-open': this.props.tooltip }, this.props.classes);
		return (
			<div
				className={classes}
				id={this.props.id}
				{...this.props.tooltip}
				style={this.props.style}
				onClick={this.props.onClick}
			>
				{this.props.children}
			</div>
		);
	}
}
