/**
 * @prettier
 * @flow
 */
import { ModalHeader } from 'semantic-ui-react';

// prettier-ignore
type Props = {
	/**  Primary content as children. */
	children: React.Node
};

const Component: React.AbstractComponent = React.memo((props: Props) => (
	<ModalHeader className='overflow-ellipsis'>{props.children}</ModalHeader>
));

// Documentation generation support
Component.displayName = 'ModalHeader';

export type { Props };
export default Component;
