/**
 * @prettier
 * @flow
 */

import { IntlProvider } from 'react-intl';
import { Browser } from 'liana-ui/definitions';
import { loadIntl, loadPolyfills, loadLanguageData, flatten } from './src/Loader';

type Props = {
	children: React.Node, // Routes
	language: string,
	locales: { [string]: string },
	path: string
};

let Provider: React.AbstractComponent<Props, mixed> = ({ children, language, locales, path }) => {
	if (!global.Intl || Browser.is.IE(true)) {
		loadIntl(language, locales); // Load all relevant polyfills
	}

	const i18n = loadLanguageData(language, locales, path); // Load actual language data
	loadPolyfills(language, locales); // Load all language polyfills

	if (document.documentElement.getAttribute('lang') !== language) {
		document.documentElement.setAttribute('lang', language); // Update <html> with language
	}

	return (
		<IntlProvider
			defaultLocale='en'
			key={language}
			locale={language}
			formats={i18n.formats}
			messages={flatten(i18n.messages)}
		>
			{children}
		</IntlProvider>
	);
};

Provider.defaultProps = {
	language: 'en',
	locales: {
		en: 'en-US'
	}
};

export default Provider;
