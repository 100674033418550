/**
 * @prettier
 * @flow
 */

import { Item as SUIItem } from 'semantic-ui-react';
import { Item } from 'liana-ui/components/';
import type { Props as ItemProps } from 'liana-ui/components/item/Item';

// prettier-ignore
type Props = {
	/** An item group must have card items. */
	items: Array<ItemProps>,
	/** Mapped over any data provided for items property. Should return Card object for items. */
	renderItem?: (
		data,
		index
	) => {
		header: string,
		description: string,
		link: string,
		image: string,
		subinfo: string | React.Node | Array<React.Node>,
		...
	},
	/** A paginated card list must be provided with the total amount of possible rows, without paging. Folder rows included. */
	totalRows?: number,
	/** A item group can have different layouts. */
	layout?: 'small' | 'big',
		/** 
	  	A card can limit header to maximum amount of lines followed by ellipsis. 
	 	VALUES[1 - 10]
	*/
	limitHeader?: number,
	/** 
	    A card can limit description to maximum amount of lines followed by ellipsis. 
		VALUES[1 - 10]			
	*/
	limitDescription?: number,
	/** Function called on item select. */
	onSelect?: (
		event: SyntheticEvent<>,
		data: {
			selected: boolean
		}
	) => void
};

const DEFAULTS = {
	layout: 'big'
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/views/item/#types-items */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	// Factory function for transforming data
	let items = [];
	if (props.items && props.items.length > 0) {
		if (typeof props.renderItem === 'function') {
			for (let i = 0; i < props.items.length; i++) {
				items[i] = props.renderItem(props.items[i], i);
			}
		} else {
			items = props.items;
		}
	}

	// Define ItemGroup
	let itemGroup = (
		<SUIItem.Group divided>
			{items.map((item: CardProps, index: number) => {
				return (
					<Item
						id={item.id}
						layout={props.layout}
						singleItem={false}
						limitHeader={props.limitHeader}
						limitDescription={props.limitDescription}
						onSelect={props.onSelect}
						key={`item-${index}`}
						{...item}
					/>
				);
			})}
		</SUIItem.Group>
	);

	return itemGroup;
});

// Documentation generation support
Component.displayName = 'ItemGoup';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
