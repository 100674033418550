/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { useState, useEffect, useRef } from 'react';
import { Popup } from 'semantic-ui-react';
import { Header } from 'liana-ui/components/';
import PopupContent from './src/PopupContent';
import type { IntlComponent } from 'react-intl';

// prettier-ignore
type Props = {
	/** Element to be rendered in-place where the popup is defined. */
	trigger: React.Node,
	/**
		A popup can have plain text content.
		PROPS[IntlComponent=/localization/]
	*/
	text?: string | IntlComponent,
	/**
		A popup can have a content header.
		PROPS[IntlComponent=/localization/]
	*/
	header?: string | IntlComponent,
	/** A popup can have other content than plain text. */
	content?: React.Node,
	/** A popup can be open. */
	open: boolean,
	/** A popup can stay open when hovered. Allows for interaction with popup content. */
	hoverable: boolean,
	/** A popup can remove all inside padding. */
	fitted: boolean,
	/** A popup can open on hover or click. */
	on: 'hover' | 'click',
	/** A popup can have delay before it is opened. */
	delay: number,
	/** A popup can have animation duration. */
	duration: number,
	/**
		A popup can have offset to trigger element.
		DATA[https://react.semantic-ui.com/modules/popup/#usage-offset]
	*/
	offset?: Array<number>,
	/** A popup can be wide. */
	wide: boolean | 'very',
	/** A popup can have different open position. */
	position: 'top center' | 'top left' | 'top right' | 'bottom center' | 'bottom left' | 'bottom right' | 'right center' | 'left center',
	/** A popup can be different size. */
	size: 'mini' | 'tiny' | 'small' | 'large',
	/** Stop click events from bubbling to its parent */
	stopPropagation?: boolean,
	/** You can manually specify the modal parent container */
	mountNode?: HTMLElement
};

const DEFAULTS = {
	open: false,
	hoverable: false,
	fitted: false,
	on: ['hover', 'click'],
	delay: 150,
	duration: 100,
	wide: false,
	position: 'top center',
	offset: [0, 0],
	size: 'tiny',
	stopPropagation: false
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/popup */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	// Variables and refs
	let popupRef = useRef();
	let [isOpen, setOpen] = useState(props.open); // Toggle show / hide
	let [fade, setFade] = useState(null); // Timeout handler for transition
	let [position, setPosition] = useState(props.position || 'top center');
	let setDelay = null; // Timeout handler for delay

	useEffect(() => {
		return () => {
			// Component unmount
			clearTimeout(fade);
			clearTimeout(setDelay);
		};
	}, []);

	// Note: Have to hack Popup transitions as there is no support for them at the moment
	const handleToggle = () => {
		clearTimeout(fade);
		if (typeof props.duration === 'number' && props.duration > 0) {
			setFade(setTimeout(() => setFade(null), props.duration));
		}
	};

	const handleOpen = (event) => {
		if (props.stopPropagation && typeof event.stopPropagation === 'function') {
			event.stopPropagation();
			event.preventDefault();
		}
		let windowWidth = window.innerWidth;
		let mousePosition = event.clientX;
		let open = () => {
			handleToggle();
			setOpen(true);

			// Fix buggy SUI-R popup position (https://github.com/Semantic-Org/Semantic-UI-React/issues/3771)
			if (popupRef.current) {
				let popupWidth = popupRef.current.getBoundingClientRect().width + 30 / 2;
				if (windowWidth - popupWidth < mousePosition) {
					setPosition(position.replace(/left|center/, 'right'));
				} else if (mousePosition < popupWidth) {
					setPosition(position.replace(/right|center/, 'left'));
				} else {
					setPosition(props.position);
				}
			}
		};
		if (props.on !== 'click' && typeof props.delay === 'number' && props.delay > 0) {
			setDelay = setTimeout(open, props.delay);
		} else {
			open();
		}
	};

	const handleClose = () => {
		clearTimeout(setDelay);
		if (isOpen) {
			handleToggle();
			setOpen(false);
		}
	};

	// Assign classes
	const classes = classNames('new-popup transition', {
		fade: fade,
		in: isOpen,
		out: !isOpen,
		visible: isOpen && !fade,
		fitted: props.fitted,
		'content-popup': !props.text && props.content
	});

	return (
		<Popup
			mountNode={props.mountNode}
			open={Boolean((isOpen || fade) && (props.text || props.content))}
			position={position}
			offset={props.offset}
			wide={props.wide}
			size={props.size}
			hoverable={props.hoverable}
			className={classes}
			trigger={props.trigger}
			on={props.on}
			onOpen={handleOpen}
			onClose={handleClose}
		>
			<span ref={popupRef}>
				{props.content ? (
					<>
						{props.header ? <Header as='h3' text={props.header} /> : null}
						{props.content ? <PopupContent>{props.content}</PopupContent> : null}
					</>
				) : (
					props.text
				)}
			</span>
		</Popup>
	);
});

// Documentation generation support
Component.displayName = 'Popup';
Component.defaultProps = DEFAULTS;

// Convenience function
Component.attach = (popup: string | IntlComponent | Props, component: React.Node) => {
	if (typeof popup === 'string' || React.isValidElement(popup)) {
		return <Component trigger={component} text={popup} />;
	} else if (typeof popup === 'object') {
		return <Component trigger={component} {...popup} />;
	}
	return component;
};

export type { Props };
export default Component;
