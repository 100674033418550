/**
 * @prettier
 * @flow
 */

import { FormattedMessage, useIntl } from 'react-intl';
import { Segment, Textarea, Dropdown, Button } from 'liana-ui/components/';
import Form from 'liana-ui/legacy/components/form/Form';

// prettier-ignore
type Props = {
	settings?: any,
	loading: boolean,
	modified: boolean,
	onChange?: () => mixed,
	onSubmit?: () => mixed,
	onClose?: () => mixed
 };

const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>(
	React.forwardRef((props: Props, ref: any) => {
		const intl = useIntl();
		const FEEDBACK_FORM_SETTINGS = {
			fields: {
				name: {
					identifier: 'category',
					rules: [
						{
							type: 'empty',
							prompt: intl.formatMessage({ id: 'component.support-menu.emptyField' })
						}
					]
				},
				feedback: {
					identifier: 'description',
					rules: [
						{
							type: 'empty',
							prompt: intl.formatMessage({ id: 'component.support-menu.emptyField' })
						}
					]
				}
			}
		};

		return (
			<Form ref={ref} settings={FEEDBACK_FORM_SETTINGS} onChange={props.onChange} onSubmit={props.onSubmit}>
				<Segment basic>
					<Form.Field required>
						<Form.Label text={<FormattedMessage id='component.support-menu.topic' />} />
						<Dropdown
							className='feedbackDropdown'
							name='category'
							defaultValue='general_feedback'
							options={[
								{
									text: <FormattedMessage id='component.support-menu.generalFeedback' />,
									value: 'general_feedback'
								},
								{
									text: <FormattedMessage id='component.support-menu.featureRequest' />,
									value: 'feature_request'
								},
								{ text: <FormattedMessage id='component.support-menu.issue' />, value: 'issue' },
								{ text: <FormattedMessage id='component.support-menu.other' />, value: 'other' }
							]}
							openOnFocus={false}
						/>
					</Form.Field>
					<Form.Field required>
						<Form.Label text={<FormattedMessage id='component.support-menu.message' />} />
						<Textarea name='description' />
					</Form.Field>
				</Segment>
				<Form.Actions modified={props.modified} loading={props.loading}>
					<Button
						text={<FormattedMessage id='component.support-menu.cancelFeedback' />}
						type='button'
						onClick={props.onClose}
					/>
					<Button
						text={<FormattedMessage id='component.support-menu.sendFeedback.title' />}
						type='submit'
						icon='paper plane'
						color='primary'
					/>
				</Form.Actions>
			</Form>
		);
	})
);

// Documentation generation support
Component.displayName = 'FeedbackForm';
Component.defaultProps = {
	loading: false,
	modified: false
};

export type { Props };
export default Component;
