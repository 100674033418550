/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Validate from 'liana-ui/definitions/component/Validate';
import { Menu } from 'semantic-ui-react';
import { Checkbox, Icon, Divider } from 'liana-ui/components/';
import type { IntlComponent } from 'react-intl';
import type { Props as IconProps } from 'liana-ui/components/icon/Icon';
import type { Props as CheckboxProps } from 'liana-ui/components/checkbox/Checkbox';

// prettier-ignore
type Props = {
	/**
		A menu must have options.
		PROPS[IntlComponent=/language/localisation/, CheckboxProps=/components/forms/checkbox/, IconProps=/components/labels/icons/icon/]
	*/
	options: Array<{
		text: string | IntlComponent,
		value: string,
		icon: string | IconProps | Array<string>,
		icons: Array<string> | Array<IconProps>,
		divider: boolean,
		checkbox: CheckboxProps,
		link: string,
		disabled: boolean,
		onClick: (
			event: SyntheticEvent<>,
			data: any
		) => void
	}>,
	/** A menu can take the width of its container. */
	fluid?: boolean,
	/** A menu can be compressed to have less padding. */
	compressed?: boolean,
	/** A menu can have rounded items. */
	rounded?: boolean,
	/** A menu can have different sizes. */
	size?: 'small' | 'tiny' | 'mini'
};

// Component default property values
const DEFAULTS = {
	fluid: false,
	rounded: true,
	compressed: false
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/dropdown/#types-pointing */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	// Variables and refs

	const getOptions = () => {
		let options = [];
		if (props.options && props.options.length > 0) {
			props.options.forEach((option, i) => {
				if (option) {
					switch (true) {
						case option.divider === true:
							options.push(<Divider key={`divider-${i}`} />);
							break;
						case option.header !== undefined:
							options.push(<Menu.Item header content={option.header} key={`header-${i}`} />);
							break;
						case option.checkbox !== undefined:
							options.push(
								<Menu.Item
									className='checkbox'
									key={`checkbox-${i}`}
									onClick={() => setTimeout(() => option.onClick(), 100)}
								>
									<Checkbox
										{...option.checkbox}
										size={props.size || 'small'}
										onClick={(event, data) => {
											if (typeof option.checkbox.onClick === 'function') {
												option.checkbox.onClick(event, data);
											}
										}}
									/>
								</Menu.Item>
							);
							break;
						case option.text !== undefined:
							// Get link type
							let linkType = Validate.linkType(option.link);
							options.push(
								<Menu.Item
									key={`item-${i}`}
									as={linkType === 'internal' ? Link : linkType === 'external' ? 'a' : undefined}
									to={linkType === 'internal' ? option.link : undefined}
									href={linkType === 'external' ? option.link : undefined}
									target={linkType === 'external' ? '_blank' : undefined}
									rel={linkType === 'external' ? 'noopener noreferrer' : undefined}
									content={<span className='text'>{option.text}</span>}
									icon={
										Array.isArray(option.icons) ? (
											<Icon.Group
												icons={
													typeof option.icons[0] === 'string'
														? [{ name: option.icons[0] }, { name: option.icons[1] }]
														: option.icons
												}
											/>
										) : typeof option.icon === 'string' ? (
											<Icon name={option.icon} />
										) : options.icon ? (
											<Icon {...option.icon} />
										) : undefined
									}
									disabled={option.disabled}
									onClick={option.onClick}
								/>
							);
					}
				}
			});
			return options;
		}
	};

	// Assign classes
	let classes = classNames({
		rounded: props.rounded,
		compressed: props.compressed
	});

	return (
		<Menu vertical size={props.size} fluid={props.fluid} className={classes}>
			{getOptions()}
		</Menu>
	);
});

// Documentation generation support
Component.displayName = 'Menu';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
