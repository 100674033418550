/**
 * @prettier
 * @flow
 */

import { useState } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Text, Button, Header, Accordion } from 'liana-ui/components/';

// prettier-ignore
type Props = {
	/** A hero can have a header in the middle of the container. */
	header?: string,
	/** A hero can have a sub header in the middle of the container. */
	subheader?: string,
	/** A hero can have a text in the middle of the container. */
	text?: React.Node,
	/** A hero can have a content header in the top left corner of the container. */
	contentHeader?: string,
	/** A hero can have custom content for the container as children. */
	children?: React.Node,
	/** A hero can be opened and closed. */
	open?: boolean,
	/** Function called when toggle is clicked. */
	onToggle?: (
		open: boolean
	) => void
};

const DEFAULTS = {
	open: true,
	upDown: false
};

const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	const getPopup = () => {
		return active ? <FormattedMessage id='component.hero.close' /> : <FormattedMessage id='component.hero.open' />;
	};

	const [active, setActive] = useState(props.open);
	const [popup, setPopup] = useState(getPopup());

	const handleClick = () => {
		setPopup(false);
		setActive(!active);
		if (typeof props.onToggle === 'function') {
			props.onToggle(!active);
		}
	};

	const handleAnimationEnd = () => {
		setPopup(getPopup());
	};

	const getButton = () => {
		return (
			<Button
				circular
				blurOnClick
				icon={active ? 'close' : 'chevron down'}
				size='small'
				popup={{
					text: popup,
					size: 'mini'
				}}
			/>
		);
	};

	const containerClasses = classNames('hero-container', {
		'hero-banner': props.header || props.subheader
	});

	let hero = (
		<section className='hero'>
			<Accordion fluid>
				<div className='titleWrapper'>
					{props.contentHeader ? <Header size='huge' textAlign='left' text={props.contentHeader} /> : null}
					<Accordion.Title index='hero' icon={false} active={active} onClick={handleClick}>
						{getButton()}
					</Accordion.Title>
				</div>
				<Accordion.Content
					active={active}
					easing='cubic-bezier(0.37, 0, 0.63, 1)'
					duration={400}
					onAnimationEnd={handleAnimationEnd}
				>
					<div className={containerClasses}>
						{props.header || props.subheader ? (
							<Header as='h1' textAlign='center' text={props.header} subheader={props.subheader} />
						) : null}

						{props.text ? (
							<Text as='p' textAlign='center' size='large'>
								{props.text}
							</Text>
						) : null}
						{props.children}
					</div>
				</Accordion.Content>
			</Accordion>
		</section>
	);

	return hero;
});

// Documentation generation support
Component.displayName = 'Hero';
Component.defaultProps = DEFAULTS;

export default Component;
