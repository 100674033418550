/**
 * @flow
 * @prettier
 */

import { createIntl, createIntlCache, IntlProvider } from 'react-intl';
import TestUtils from 'react-dom/test-utils';
import ReactPropTypes from 'prop-types';

// https://github.com/yahoo/react-intl/wiki/Testing-with-React-Intl

const TEST_CONTAINER_ID = 'test-container';

const INTL_DEFAULTS = {
	node: false, // DOM node to render target
	context: false, // Context object to provide
	messages: {} // Translations data
};

type Props = {
	context: any,
	children: React.Node
};

class DynamicContext extends React.Component<Props> {
	constructor(props: Props) {
		super(props);
	}
	getChildContext() {
		return this.props.context;
	}
	render() {
		return this.props.children;
	}
}

DynamicContext.childContextTypes = {};

function flatten(nestedMessages, prefix = '') {
	return Object.keys(nestedMessages).reduce((messages, key) => {
		let value = nestedMessages[key],
			prefixedKey = prefix ? `${prefix}.${key}` : key;
		if (typeof value === 'string') {
			messages[prefixedKey] = value;
		} else {
			Object.assign(messages, flatten(value, prefixedKey));
		}
		return messages;
	}, {});
}

export default {
	/* Cases:
	 * a) Load all files (default)
	 * b) Load a single file
	 * c) Load a single directory
	 */
	loadFiles(context: any) {
		var paths = context.keys();
		if (typeof process.env.TEST_FILE === 'string') {
			paths = paths.filter((path) => path.indexOf(process.env.TEST_FILE) > -1); // Filter out unmatching files
		}
		for (var i = 0; i < paths.length; i++) {
			try {
				context(paths[i]);
			} catch (err) {
				let type = err.constructor.name ? err.constructor.name : 'Error';
				console.error(`[${type}] with test "${paths[i]}" at line ${err.line}!`); // eslint-disable-line
			}
		}
	},
	getIntl() {
		// This is optional but highly recommended since it prevents memory leak
		const cache = createIntlCache();
		let config = { locale: 'en', messages: {} };
		return createIntl(config, cache);
	},
	random() {
		return Math.random().toString(36).substring(7);
	},
	render(component: React.Node, node: HTMLElement = null) {
		let data = { node: null, component: null, element: null };
		if (typeof document === 'object') {
			data.node = !node ? this.init() : node;
			TestUtils.act(() => {
				data.component = ReactDOM.render(component, data.node); // act() must return undefined
			});
			data.element = data.node.children[0];
		}
		return data;
	},
	init() {
		let node = null;
		if (typeof document === 'object') {
			node = document.body.querySelector(`#${TEST_CONTAINER_ID}`);
			if (!node) {
				node = document.createElement('div');
				node.setAttribute('id', TEST_CONTAINER_ID);
				document.body.appendChild(node);
			}
		}
		return node;
	},
	remove(node: HTMLElement) {
		if (typeof document === 'object' && document.body.contains(node)) {
			if (node.childNodes.length > 0) {
				ReactDOM.unmountComponentAtNode(node);
			}
			document.body.removeChild(node);
			node = null;
		}
		return null;
	},
	reset(node: HTMLElement, hard: boolean = true) {
		if (typeof node === 'object') {
			this.remove(node);
		}
		if (Boolean(hard) === true && typeof document === 'object') {
			//let extra = document.querySelectorAll(`body > *:not(script):not(#${TEST_CONTAINER_ID})`);
			let extra = document.querySelectorAll('body :not(script)');
			for (let i = 0; i < extra.length; i++) {
				extra[i].remove();
			}
		}
	},
	withContext(component: React.Node, context: any) {
		// eslint-disable-line
		for (const propertyName in context) {
			DynamicContext.childContextTypes[propertyName] = ReactPropTypes.any;
		}
		return <DynamicContext context={context}>{component}</DynamicContext>;
	},
	renderWithIntlNew(
		component: React.Node,
		node: HTMLElement = null,
		context?: any = null,
		messages?: { [string]: string } = {}
	) {
		// Handle instance + context
		let instance = null,
			key = node ? undefined : this.random();
		component =
			typeof component !== 'function'
				? React.cloneElement(component, { ref: (ref) => (instance = Safely.ref(ref)) })
				: React.cloneElement(component);
		let wrapper = (
			<IntlProvider
				key={key}
				locale='en'
				defaultLocale='en'
				messages={flatten(messages || {})}
				//onError={(err) => (throw err)}
			>
				{context ? this.withContext(component, context) : component}
			</IntlProvider>
		);
		// Render component & set instance
		let data = this.render(wrapper, node);
		if (typeof component !== 'function') {
			data.component = instance;
		}
		return data;
	},
	// Deprecated! Note: Only works with Class Components!
	find(tree: React.Node, className: string = '') {
		// Convenience method for findRenderedDOMComponentWithClass()
		return TestUtils.findRenderedDOMComponentWithClass(tree, className);
	},
	// Deprecated! Note: Only works with Class Components!
	scry(tree: React.Node, className: string = '') {
		// Convenience method for scryRenderedDOMComponentsWithClass()
		return TestUtils.scryRenderedDOMComponentsWithClass(tree, className);
	},
	// Deprecated! Note: Only works with Class Components!
	renderWithIntl(
		element: React.Element<'*'>,
		options: { node?: React.Node, context?: any, messages?: { [string]: string } } = {}
	) {
		options = Object.assign({}, INTL_DEFAULTS, options);
		let instance;
		element = options.node
			? element // Don't clone DOM components
			: React.cloneElement(element, {
					ref: (ref) => {
						instance = ref && typeof ref.getWrappedInstance === 'function' ? ref.getWrappedInstance() : ref;
					}
			  });
		let wrapper = (
			<IntlProvider
				locale='en'
				defaultLocale='en'
				messages={flatten(options.messages || {})}
				//onError={(err) => (throw err)}
			>
				{typeof options.context === 'object' ? this.withContext(element, options.context) : element}
			</IntlProvider>
		);
		if (options.node) {
			return ReactDOM.render(wrapper, options.node);
		} else {
			TestUtils.renderIntoDocument(wrapper);
			return instance;
		}
	}
};
