/**
 * @prettier
 * @flow
 */

import { FormattedMessage } from 'react-intl';
import { Accordion } from 'liana-ui/components';

// prettier-ignore
type Props = {
	/** Navigation data. Array of pages object. */
	navigation: Array<{
		icon: string,
		text: string,
		link: string,
		header: string,
		labels: LabelProps,
		pages: Array<{
			icon: string,
			text: string,
			link: string,
			...
		}>
	}>,
	/** Set maximum levels of accordion. */
	maxLevels: number,
	/** Navigation can disable all but the deepest links in the tree. */
	disableParentLinks?: boolean,
	/** A button can have different sizes. */
	size?: 'large',
	/** Use plaintext translations */
	raw: boolean,
	/** Function called on menu item click. */
	onClick?: (
		event: SyntheticEvent<>,
		data: {
			name: string
		}
	) => void,
	/** Function called on menu link item click only. */
	onLinkClick?: (
		event: SyntheticEvent<>,
		data: any
	) => void,
	/** Function called when toggled section is fully open. */
	onAnimationEnd?: (
		newHeight: number
	) => void
};

const DEFAULTS = {
	maxLevels: 3,
	disableParentLinks: false,
	raw: false
};

let Component = (props: Props) => {
	let getNavigation = () => {
		let navigation = [];
		props.navigation.map((item, i) => {
			navigation.push(getItem(item, i));
		});
		return navigation;
	};

	let getItem = (item: any, i: string) => {
		let panels = [];
		if (item.pages && item.pages.length > 0) {
			item.pages.map((subitem, j) => {
				panels.push(getItem(subitem, `${i}-${j}`));
			});
		}
		const translate = (key) => {
			if (!key) {
				return undefined;
			}
			return props.raw ? key : <FormattedMessage id={key} />;
		};
		return {
			key: i,
			icon: item.icon,
			header: translate(item.header),
			title: translate(item.text),
			divider: item.divider,
			labels: item.labels,
			link: item.link,
			upsell: item.upsell,
			panels: panels && panels.length > 0 ? panels : undefined
		};
	};

	let handleClick = (event: ?SyntheticEvent<>, data: any) => {
		if (typeof props.onClick === 'function') {
			props.onClick(event, data);
		}
	};

	let handleLinkClick = (event: ?SyntheticEvent<>, data: any) => {
		if (typeof props.onLinkClick === 'function') {
			props.onLinkClick(event, data);
		}
	};

	let handleAnimationEnd = (newHeight: number) => {
		if (typeof props.onAnimationEnd === 'function') {
			props.onAnimationEnd(newHeight);
		}
	};

	return props.navigation ? (
		<nav className='main-navigation'>
			<Accordion
				exclusive
				panels={getNavigation()}
				maxLevels={props.maxLevels}
				disableParentLinks={props.disableParentLinks}
				onClick={handleClick}
				onLinkClick={handleLinkClick}
				onAnimationEnd={handleAnimationEnd}
			/>
		</nav>
	) : null;
};

Component.displayName = 'Navigation';
Component.defaultProps = DEFAULTS;

export default Component;
