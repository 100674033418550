/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { GridColumn } from 'semantic-ui-react';
import { Responsive } from 'liana-ui/components/';

// prettier-ignore
type Props = {
	/** Grid column content as children. */
	children: React.Node,
	/** A grid column can have the id attribute */
	id?: string,
	/**
		A grid column can have a width.
		VALUES[1 - 12]
	*/
	width?: number,
	/**
		A column can specify a width for a mobile device.
		VALUES[1 - 12]
	*/
	mobile?: number,
	/**
		A column can specify a width for a tablet device.
		VALUES[1 - 12]
	*/
	tablet?: number,
	/**
		A column can specify a width for a computer.
		VALUES[1 - 12]
	*/
	computer?: number,
	/**
		A column can specify a width for a large screen device.
		VALUES[1 - 12]
	*/
	largescreen?: number,
	/**
		A column can specify a width for a wide screen device.
		VALUES[1 - 12]
	*/
	widescreen?: number,
	/** A column can stretch its contents to take up the entire grid or row height. */
	stretched?: boolean,
	/** A column can take up all possible grid or row width. */
	fluid?: boolean,
	/** A column can collapse to take up only width of content. */
	collapsing?: boolean,
	/** A grid column can specify that its contents should remain on a single line, and not wrap. */
	singleLine?: boolean,
	/** A grid column can break very long strings in tight spaces into multiple lines. */
	breakWord?: boolean,
	/**
		A grid column paddings can be removd around the column.
		VALUES['all' | 'horizontal' | 'vertical' | 'top' | 'right' | 'bottom' | 'left']
	*/
	removePaddings?: string | Array<string>,
	/** A grid column can specify its text alignment. */
	textAlign?: 'left' | 'center' | 'right',
	/** A grid column can specify its vertical alignment to have all its columns vertically aligned. */
	verticalAlign?: 'bottom' | 'middle' | 'top',
	/** A column can sit flush against the left or right edge of a row. */
	floated?: 'left' | 'right',
	/** A column can have different background textures. */
	background?: 'check' | 'grid',
	/** A column can have additional classes. Use for very special features only! */
	className?: string,
	/** Smallest device that component will be displayed with. */
	minDevice?: 'mobile' | 'tablet' | 'computer' | 'largescreen' | 'widescreen',
	/** Largest device that component will be displayed with. */
	maxDevice?: 'mobile' | 'tablet' | 'computer' | 'largescreen' | 'widescreen'
};

const ELEMENT_SIDES = ['top', 'right', 'bottom', 'left'];

/** COMPONENT BASED ON: https://react.semantic-ui.com/collections/grid/ */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	// Function to generate LianaUI GridColumn
	const createColumn = (props: Props) => {
		// Assign classes
		const classes = classNames(
			props.className,
			{
				collapsing: props.collapsing,
				'fluid-column': props.fluid,
				'overflow-ellipsis': props.singleLine,
				'break-word': props.breakWord,
				'background-check': props.background === 'check',
				'background-grid': props.background === 'grid'
			},
			getElementClasses(props.removePaddings)
		);

		return (
			<GridColumn
				id={props.id}
				className={classes}
				width={props.width}
				mobile={props.mobile}
				tablet={props.tablet}
				computer={props.computer}
				largeScreen={props.largescreen}
				widescreen={props.widescreen}
				stretched={props.stretched}
				textAlign={props.textAlign}
				verticalAlign={props.verticalAlign}
				floated={props.floated}
			>
				{props.children}
			</GridColumn>
		);
	};

	const getElementClasses = (data: RemoveType) => {
		let result = {},
			name = '';
		if (data) {
			if (Array.isArray(data) && data.length > 0) {
				for (let i = 0; i < ELEMENT_SIDES.length; i++) {
					name = `remove-${ELEMENT_SIDES[i]}-padding`;
					result[name] = data.includes(ELEMENT_SIDES[i]);
				}
			} else if (typeof data === 'string') {
				switch (data) {
					case 'all':
						result[`remove-paddings`] = true;
						break;
					case 'horizontal':
					case 'vertical':
						result[`remove-${data}-paddings`] = true;
						break;
					case 'top':
					case 'bottom':
					case 'right':
					case 'left':
						result[`remove-${data}-padding`] = true;
						break;
				}
			}
		}
		return result;
	};

	// Display reponsively
	let column =
		props.minDevice || props.maxDevice ? (
			<Responsive {...props}>{createColumn(props)}</Responsive>
		) : (
			createColumn(props)
		);

	return column;
});

// Documentation generation support
Component.displayName = 'Grid.Column';

export default Component;
