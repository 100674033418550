/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { Loader } from 'liana-ui/components/';
import { ModalContent } from 'semantic-ui-react';

// prettier-ignore
type Props = {
	/**  Primary content as children. */
	children: React.Node,
	/** Remove paddings around the scontent. */
	removePaddings?: boolean,
	/** A modal content can center its text alignment. */
	textAlign?: 'center',
	/** Controls whether or not the ModalContant is displayed as loading. */
	loading?: boolean,
	/** Additional classes. */
	className?: string
};

const Component: React.AbstractComponent = React.memo((props: Props) => {
	// Assign classes
	const classes = classNames(
		{
			'remove-paddings': props.removePaddings,
			'text-center': props.textAlign === 'center'
		},
		props.className
	);

	return (
		<ModalContent className={classes}>
			{props.loading ? <Loader active={props.loading} dimmer /> : null}
			{props.children}
		</ModalContent>
	);
});

// Documentation generation support
Component.displayName = 'ModalContent';

export type { Props };
export default Component;
