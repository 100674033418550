/**
 * @prettier
 * @flow
 */

// Constants like these are "living"
// Note: Do NOT save breakpoints as strings!

const Breakpoints = {
	min: 0,
	mobile: 669,
	tablet: 1025,
	computer: 1281,
	largescreen: 1601,
	widescreen: 100000
};

type Breakpoint = 'mobile' | 'tablet' | 'computer' | 'largescreen' | 'widescreen';

export type { Breakpoint };

export default Breakpoints;
