/**
 * @prettier
 * @flow
 */
import { ListIcon } from 'semantic-ui-react';

// prettier-ignore
type Props = {};

const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => <ListIcon {...props} />);

Component.displayName = 'ListIcon';

export type { Props };
export default Component;
