/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { Placeholder } from 'semantic-ui-react';

// prettier-ignore
type Props = {
	/** An image may appear at different sizes. */
	size?: 'favicon' | 'mini' | 'tiny' | 'small' | 'medium' | 'large',
	/** An image can take up the width of its container. */
	fluid?: boolean,
	/** Width/Height ratio for fluid responsive image placeholder loaders. */
	ratio?: string,
	/** Max height of responsive images */
	maxHeight?: string
};

const DEFAULTS = {
	fluid: false,
	ratio: '1/1'
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/placeholder/#content-image */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	// Assign placeholder image
	let placeholderImageClasses = classNames('ui', props.size ? props.size : '');

	// Count fluid placeholder ratio
	let placeholderPadding = '100%';
	if (props.ratio && typeof props.ratio === 'string' && props.ratio.indexOf('/') > -1) {
		placeholderPadding = (props.ratio.split('/')[1] / props.ratio.split('/')[0]) * 100 + '%';
	}

	let styles = {
		paddingTop: props.maxHeight ? props.maxHeight : placeholderPadding
	};

	return <Placeholder.Image className={placeholderImageClasses} style={styles} />;
});

// Documentation generation support
Component.displayName = 'Placeholder.Image';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
