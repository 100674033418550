/**
 * @prettier
 * @flow
 */

import { Segment } from 'semantic-ui-react';

// prettier-ignore
type Props = {
	/** Content as children. */
	children: React.Node
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/segment/#types-placeholder-inline */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	return <Segment.Inline>{props.children}</Segment.Inline>;
});

// Documentation generation support
Component.displayName = 'Segment.Inline';

export type { Props };
export default Component;
