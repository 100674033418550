/**
 * @prettier
 * @flow
 */

import { Statistic } from 'semantic-ui-react';
import type { IntlComponent } from 'react-intl';

// prettier-ignore
type Props = {
	/** An element type to render as */
	as: React.Node,
	/** Link for NavLink */
	to: string,
	/** Statistic value as number. */
	content: number | IntlComponent
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/views/statistic */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => (
	<Statistic.Value as={props.as} to={props.to}>
		{props.content}
	</Statistic.Value>
));
Component.displayName = 'StatisticValue';

export type { Props };
export default Component;
