/**
 * @prettier
 * @flow
 */

/** TODO: THIS COMPONENT IS INCOMPLETE AND IN PROTOTYPE USAGE! DO NOT USE IT IN PRODUCTION! */
import { Breadcrumb } from 'semantic-ui-react';
import Divider from './src/BreadcrumbDivider';
import Section from './src/BreadcrumbSection';
import { Icon } from 'liana-ui/components/';

// prettier-ignore
type Props = {
	/** A breadcrumb must have sections for page navigation. */
	sections: Array<{
		page: string,
		link: string
	}>,
	/** A breadcrumb can have an icon. */
	icon?: string,
	/** A breadcrumb can be different size. */
	size?: 'small',
};

const DEFAULTS = {};

/** COMPONENT BASED ON: https://react.semantic-ui.com/collections/breadcrumb/ */
let Component: React.AbstractComponent = React.memo<Props>((props: Props) => {
	// Generate main part of trail
	const getSections = (data = []) => {
		if (Array.isArray(data) && data.length > 0) {
			let trail = [];
			if (props.icon) {
				trail.push(<Icon name={props.icon} />);
			}
			for (let i = 0; i < data.length - 1; i++) {
				trail.push(<Section text={data[i].page} link={data[i].link} key={`section-${i}`} />);
				if (i < data.length - 1) {
					trail.push(<Divider key={`divider-${i}`} />);
				}
			}
			trail.push(<Section text={data[data.length - 1].page} key='section-current' />);
			return trail;
		}
		return null;
	};

	return (
		<Breadcrumb as='nav' size={props.size}>
			{getSections(props.sections)}
		</Breadcrumb>
	);
});

// Documentation generation support
Component.defaultProps = DEFAULTS;
Component.displayName = 'Breadcrumb';

export default Component;
