/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { MessageHeader } from 'semantic-ui-react';

// prettier-ignore
type Props = {
	/** Detail as children. */
	children: React.Node,
	/** A message haedr can have different sizes. */
	size?: 'large' | 'small'
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/collections/message */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	// Assign classes
	let classes = classNames('ui', props.size);

	return <MessageHeader className={classes}>{props.children}</MessageHeader>;
});

// Documentation generation support
Component.displayName = 'MessageHeader';

export type { Props };
export default Component;
