/**
 * @prettier
 * @flow
 */

import { useRef } from 'react';
import { Segment, Navigation } from 'liana-ui/components';
import Sticky from 'liana-ui/legacy/components/sticky/Sticky';

// prettier-ignore
type Props = {
	/** A side panel must have navigation data for main navigation. DATA[json/navigation/navigation.json] */
	navigation: Array<{
		text: string,
		icon: string,
		link: string,
		pages: Array<{
			text: string,
			...
		}>,
		...
	}>,
	/** A side panel can have extra tools for application. */
	tools?: React.Node,
	/** A side panel can have a different sticky context element id. */
	stickyContext: string
};

const DEFAULTS = {
	stickyContext: 'side-panel'
};

let Component: React.AbstractComponent = React.memo<Props>((props: Props) => {
	let stickyRef = useRef();

	/* istanbul ignore next */
	let handleAnimationEnd = () => {
		if (stickyRef.current) {
			stickyRef.current.refresh();
		}
	};

	let content = (
		<>
			{props.tools ? (
				<Segment basic compressed removeMargins='all'>
					{props.tools}
				</Segment>
			) : null}
			<Navigation navigation={props.navigation} onAnimationEnd={handleAnimationEnd} />
		</>
	);

	if (props.stickyContext) {
		content = (
			<Sticky context={`#${props.stickyContext}`} ref={stickyRef}>
				{content}
			</Sticky>
		);
	}

	return (
		<section className='side-panel' id={props.stickyContext}>
			{content}
		</section>
	);
});

Component.displayName = 'SidePanel';
Component.defaultProps = DEFAULTS;

export default Component;
