/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { EmptyState, Segment, Grid } from 'liana-ui/components/';
import type { Props as EmptyStateProps } from 'liana-ui/components/message/EmptyState';

// prettier-ignore
type Props = {
	/**
		An empty state group must have multiple empty states.
		PROPS[EmptyStateProps=/components/feedback/empty-state/]
	*/
	emptyStates: Array<EmptyStateProps>,
	/** A grid can preserve its vertical and horizontal gutters on first and last columns. */
	padded?: boolean | 'horizontally' | 'vertically',
	/** A grid can increase its gutters to allow for more negative space. */
	relaxed?: boolean | 'very',
	/**
		Represents column count per row in Grid.
		VALUES['equal', 1 - 12]
	*/
	columns?: string | number,
	/**
		A column can specify a width for a mobile device.
		VALUES[1 - 12]
	*/
	mobile?: number,
	/**
		A column can specify a width for a tablet device.
		VALUES[1 - 12]
	*/
	tablet?: number,
	/**
		A column can specify a width for a computer.
		VALUES[1 - 12]
	*/
	computer?: number,
	/**
		A column can specify a width for a large screen device.
		VALUES[1 - 12]
	*/
	largescreen?: number,
	/**
		A column can specify a width for a wide screen device.
		VALUES[1 - 12]
	*/
	widescreen?: number
};

const DEFAULTS = {
	mobile: 12,
	tablet: 12,
	computer: 4,
	largescreen: 4,
	widescreen: 4
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/collections/message/ */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	let emptyStates = (
		<div className='empty-states-wrapper'>
			<Grid
				stretched
				padded={props.padded}
				relaxed={props.relaxed}
				columns={props.columns}
				stackable={props.columns !== undefined}
			>
				{props.emptyStates && Array.isArray(props.emptyStates)
					? props.emptyStates.map((emptyState, index) => (
							<Grid.Column
								key={`emptyState{index}`}
								mobile={!props.columns ? props.mobile : undefined}
								tablet={!props.columns ? props.tablet : undefined}
								computer={!props.columns ? props.computer : undefined}
								largescreen={!props.columns ? props.largescreen : undefined}
								widescreen={!props.columns ? props.widescreen : undefined}
							>
								<EmptyState segment {...emptyState} />
							</Grid.Column>
					  ))
					: null}
			</Grid>
		</div>
	);

	return emptyStates;
});

// Documentation generation support
Component.displayName = 'EmptyStateGroup';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
