/**
 * @prettier
 * @flow
 */

import { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import NotificationMenu from './src/NotificationMenu';
import SupportMenu from './src/SupportMenu';
import ApplicationMenu from './src/ApplicationMenu';
import UserMenu from './src/UserMenu';
import NavigationMenu from './src/NavigationMenu';
import { Menu } from 'semantic-ui-react';
import { Text, Image, Label, Responsive, FeedbackModal } from 'liana-ui/components/';

// prettier-ignore
type Props = {
	/** A top panel must have an application logo. */
	logo?: string,
	/** A top panel must have an application name. */
	appName?: string,
	/* Depracated. Remove ones not in use anymore! */
	siteUrl?: string,
	/** Redirect to a given link instead of root when clicking on logo */
	appUrl?: string,
	/** A top panel must have user data for user menu. DATA[json/user/user.json] */
	user: {
		id: string,
		selectedOrganization: string,
		selectedSolution: string,
		name: string,
		email: string,
		image: string,
		organizations: Array<{
			name: string,
			additionalName: string,
			id: string,
			section: number | null,
			image: string
		}>,
		links: {
			userSettings: string,
			organizationSettings: string,
			logout: string,
			privacyPolicy: string,
			termsOfService: string
		},
		...
	},
	/** Navigation menu should be the same as navigation for main navigation in SidePanel on desktop. DATA[json/navigation/navigation.json] */
	navigation?: Array<{
		text: string,
		icon: string,
		link: string,
		pages: Array<{
			text: string,
			icon: string,
			link: string,
			pages: Array<{
				text: string,
				...
			}>
		}>
	}>,
	/** A top panel can have tools for mobile navigation menu. Should be the same as tools for main navigation in SidePanel on desktop. */
	tools?: React.Node,
	/** A top panel can have a notification menu. DATA[json/notifications/notifications.json] */
	notifications?: Array<{
		title: string,
		id: string,
		publishTime: string,
		viewed: boolean,
		summary: string,
		content: string,
		link: string
	}>,
	/** A top panel can have a support menu. DATA[json/support/support.json] */
	support?: {
		site: {
			active: boolean,
			link: boolean
		},
		chat: {
			active: boolean
		},
		knowledgeBase: {
			active: boolean
		},
		feedback: {
			active: boolean
		}
	},
	/** A top panel can have an application menu. DATA[json/applications/applications.json] */
	applications?: {
		organizations: Array<{
			name: string,
			id: string,
			section: number | null,
			image: string,
			links: {
				manageMenu: string,
				addSolution: string
			},
			solutions: Array<{
				name: string,
				additionalName: string,
				id: string,
				applications: Array<{
					name: string,
					id: string,
					image: string,
					url: string
				}>
			}>
		}>
	},
	/** Navigation translations will be treated as plain text instead of trying to translate them */
	useRawTranslations: boolean,
	/* Set TopPanel to  web component mode for non LianaUI apps. Affect links etc. */
	webComponent?: boolean,
	/** Function called on feedback submit. */
	onFeedback?: (data: any, done: () => void) => void,
	/** Function that enables organization change in user menu and is called on organization change. NOTICE! Should only be used in LianaAccount app. */
	onOrganizationChange?: (customerId: string) => void,
	/** Function called on clicking mobile menu links */
	onLinkClick?: () => void,
	/** Function called on logout click in user menu. */
	onLogout?: (logoutUrl: string) => void,
	/** Function called on any top panel menu open. */
	onOpenMenu?: (
		event: SyntheticEvent<>,
		data: {
			id: string
		}
	) => void,
	/** Function called on any top panel menu open. */
	onCloseMenu?: (
		event: SyntheticEvent<>,
		data: {
			id: string
		}
	) => void
};

const DEFAULTS = {
	useRawTranslations: false,
	webComponent: false,
	preventBodyScrolling: true
};

// Hack to get around broken SUIR event stack for Web Components
// https://github.com/Semantic-Org/Semantic-UI-React/issues/4184
let outsideClickListener = undefined;

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/dropdown/ and https://react.semantic-ui.com/collections/menu/ */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	const [open, setOpen] = useState(false);
	const [mounted, setMounted] = useState(false);
	const [style, setStyle] = useState();
	const [feedbackOpen, showFeedback] = useState(false);
	const ref = useRef();

	const modalMount = useRef();
	const popupMount = useRef();

	const removeClickListener = () => {
		if (outsideClickListener) {
			document.removeEventListener('click', outsideClickListener);
			outsideClickListener = undefined;
		}
	};

	useEffect(() => {
		setMounted(true);
	}, []);

	const POPUP_SETTINGS = {
		mountNode: popupMount.current,
		size: 'tiny'
	};

	const getSelectedSolution = () => {
		if (
			!props.user ||
			!props.applications ||
			!props.applications.organizations ||
			!props.user.selectedOrganization ||
			!props.user.selectedSolution
		) {
			return null;
		}

		let organization = props.applications.organizations.filter(
			(organization) => props.user.selectedOrganization === organization.id
		)[0];

		let solution = organization?.solutions.filter((solution) => props.user.selectedSolution === solution.id)[0];

		return solution;
	};

	const openFeedback = () => showFeedback(true);
	const hideFeedback = () => showFeedback(false);

	const handleOpenMenu = (event: ?SyntheticEvent<>, data: any) => {
		const list = document.querySelector('html').classList,
			isMobile = list.contains('mobile') || list.contains('tablet'),
			dropdown = ref.current.querySelector(`#${data.id}`),
			menu = ref.current.querySelector(`#${data.id.replace('Menu', 'Dropdown')}`);

		if (isMobile) {
			let content = document.getElementById('app-content');
			if (content) {
				content.style.height = window.innerHeight + 'px';
				content.style.overflow = 'hidden';
			}
			setStyle({
				width: `${document.documentElement.clientWidth}px`,
				left: `-${dropdown.getBoundingClientRect().x}px`,
				maxHeight: `${document.documentElement.scrollHeight - dropdown.getBoundingClientRect().height}px`,
				overflowY: 'auto'
			});
		} else {
			setStyle({
				width: `${document.documentElement.clientWidth - dropdown.getBoundingClientRect().x}px`,
				maxHeight: `${window.innerHeight - menu.getBoundingClientRect().top}px`,
				overflowY: 'auto'
			});
		}

		if (!outsideClickListener) {
			outsideClickListener = (event) => {
				event.stopImmediatePropagation(); // Prevent event queue / memory leak
				// Web Components: https://developer.mozilla.org/en-US/docs/Web/API/Event/composedPath
				let path = event.composedPath();
				if (!dropdown.contains(path[0])) {
					handleCloseMenu(event, data);
				}
			};
			document.addEventListener('click', outsideClickListener);
		}
		setOpen(data.id);
		if (typeof props.onOpenMenu === 'function') {
			props.onOpenMenu(event, data);
		}
	};

	const handleCloseMenu = (event: ?SyntheticEvent<>, data: any) => {
		let content = document.getElementById('app-content');
		if (content) {
			content.style.left = 'auto';
			content.style.maxHeight = 'none';
			content.style.overflow = 'unset';
		}
		if (data && typeof props.onCloseMenu === 'function') {
			props.onCloseMenu(event, data);
		}
		removeClickListener();
		setOpen(undefined);
	};

	// Triggers on successful validation
	const handleSubmitFeedbackModal = (data, done) => {
		if (typeof props.onFeedback === 'function') {
			props.onFeedback(data, () => {
				done();
				showFeedback(false);
			});
		} else {
			showFeedback(false);
		}
	};

	// Get app logo and link
	const getLogo = () => {
		let logo = null,
			url = props.appUrl || props.siteUrl,
			image = (
				<Image
					src={props.logo}
					alt={props.appName ? props.appName : 'Logo'}
					squared
					maxHeight='2.2rem'
					lazyLoad={false}
					ratio='300/89'
					style={{ margin: '1em 1.42857143em' }}
				/>
			);
		if (props.logo) {
			if (props.webComponent) {
				logo = (
					<a className='site-logo' href={url ? url : '/'}>
						{image}
					</a>
				);
			} else {
				logo = (
					<Link className='site-logo' to={url ? url : '/'}>
						{image}
					</Link>
				);
			}
		}
		return logo;
	};

	let selectedSolution = getSelectedSolution();

	// Note: Modal/popup containers need to properly mount before rendering anything relying on them
	let panel = (
		<header className='top-panel clearfix' id='top-panel' ref={ref}>
			{getLogo()}
			{props.logo && selectedSolution?.additionalName ? (
				<Responsive minDevice='tablet'>
					<div className='additional-name'>
						<Label
							text={
								<Text
									limit={40}
									popup={{
										text: selectedSolution.additionalName,
										size: 'mini',
										offset: [0, 10],
										mountNode: popupMount.current
									}}
								>
									{selectedSolution.additionalName}
								</Text>
							}
						/>
					</div>
				</Responsive>
			) : null}
			{mounted ? (
				<Menu floated='right'>
					<NotificationMenu
						open={open === 'notificationMenu'}
						notifications={props.notifications}
						style={style}
						popup={POPUP_SETTINGS}
						onOpen={handleOpenMenu}
						onClose={handleCloseMenu}
					/>
					<SupportMenu
						open={open === 'supportMenu'}
						support={props.support}
						user={props.user}
						style={style}
						popup={POPUP_SETTINGS}
						onFeedbackOpen={openFeedback}
						onOpen={handleOpenMenu}
						onClose={handleCloseMenu}
					/>
					<ApplicationMenu
						open={open === 'applicationMenu'}
						user={props.user}
						applications={props.applications}
						style={style}
						popup={POPUP_SETTINGS}
						onOpen={handleOpenMenu}
						onClose={handleCloseMenu}
					/>
					<UserMenu
						open={open === 'userMenu'}
						user={props.user}
						style={style}
						popup={POPUP_SETTINGS}
						onOpen={handleOpenMenu}
						onClose={handleCloseMenu}
						onOrganizationChange={props.onOrganizationChange}
						onLogout={props.onLogout}
					/>
					<Responsive maxDevice='tablet'>
						<NavigationMenu
							open={open === 'navigationMenu'}
							user={props.user}
							tools={props.tools}
							navigation={props.navigation}
							popup={POPUP_SETTINGS}
							style={style}
							raw={props.useRawTranslations}
							onLinkClick={props.onLinkClick}
							onOpen={handleOpenMenu}
							onClose={handleCloseMenu}
						/>
					</Responsive>
				</Menu>
			) : null}
			<div id='modal-mount' ref={modalMount} />
			<div id='popup-mount' ref={popupMount} />
			<div id='kc-bot-launcher' />
			<FeedbackModal
				open={feedbackOpen}
				onSubmit={handleSubmitFeedbackModal}
				onClose={hideFeedback}
				mountNode={modalMount.current}
			/>
		</header>
	);

	return panel;
});

// Documentation generation support
Component.displayName = 'TopPanel';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
