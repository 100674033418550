/**
 * @prettier
 * @flow
 */

import { ListList } from 'semantic-ui-react';
import ListItem from './ListItem';
import type { Props as ListItemProps } from 'liana-ui/components/list/src/ListItem';

// prettier-ignore
type Props = {
	/** Array of strings, React Intl components or prop objects for ListItem. */
	items?:
		Array<string> | 
		Array<FormattedMessage> |
		Array<ListItemProps>
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/list/ */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	let list = props.items ? (
		<ListList>
			{props.items && Array.isArray(props.items)
				? props.items.map((item, index) =>
						typeof item === 'string' || React.isValidElement(item) ? (
							<ListItem key={index} content={item} />
						) : (
							<ListItem key={index} {...item} />
						)
				  )
				: null}
		</ListList>
	) : null;

	return list;
});

Component.displayName = 'ListList';

export type { Props };
export default Component;
