/**
 * @prettier
 * @flow
 */

import { useState, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Validate from 'liana-ui/definitions/component/Validate';
import { Dropdown } from 'semantic-ui-react';
import { Checkbox, Button, Icon } from 'liana-ui/components/';
import type { IntlComponent } from 'react-intl';
import type { Props as IconProps } from 'liana-ui/components/icon/Icon';
import type { Props as CheckboxProps } from 'liana-ui/components/checkbox/Checkbox';

// prettier-ignore
type Props = {
	/**
		A context menu must have option.
		PROPS[IntlComponent=/language/localisation/, CheckboxProps=/components/forms/checkbox/, IconProps=/components/labels/icons/icon/]
	*/
	options: Array<{
		text: string | IntlComponent,
		value: string,
		icon: string | IconProps | Array<string>,
		icons: Array<string> | Array<IconProps>,
		divider: boolean,
		checkbox: CheckboxProps,
		link: string,
		onClick: (
			event: SyntheticEvent<>,
			data: any
		) => void
	}>,
	/**
		A context menu button can have different icon.
		PROPS[IconProps=/components/labels/icons/icon/]
	*/
	icon?: string | IconProps,
	/**
		A context menu button can have different icon group.
		PROPS[IconGroupProps=/components/labels/icons/icon-group/]
	*/
	iconGroup?: Array<IconProps>,
		/**
		A button can have text.
		PROPS[IntlComponent=/localization/]
	*/
	text?: string | IntlComponent,
	/** A context menu can have primary color. */
	color?: 'primary',
	/**  A content menu can be aligned to the left or right of its container. */
	floated?: 'left' | 'right',
	/** A content menu can have no empty space around it. */
	fitted?: boolean,
	/** A context menu button can be pronounced by having no borders. */
	basic?: boolean,
	/** A content menu can show it is currently unnecessary to be interacted with. */
	off?: boolean,
	/** A context can show it is currently unable to be interacted with. */
	disabled?: boolean,
	/** A content menu can stay open on menu item clicks to allow selecting multiple items. */
	keepOpen?: boolean,
	/** A context menu can be formatted so that its menu is pointing. */
	pointing?: 'top' | 'bottom',
	/** A content menu can be forced to open to the left or to the right. */
	direction?: 'left' | 'right',
	/** A button can have different sizes. */
	size?: 'small' | 'tiny' | 'mini',
	/** Smallest device that component will be displayed with. */
	minDevice?: 'mobile' | 'tablet' | 'computer' | 'largescreen' | 'widescreen',
	/** Largest device that component will be displayed with. */
	maxDevice?: 'mobile' | 'tablet' | 'computer' | 'largescreen' | 'widescreen',
	/** Hide content on touch devices */
	hideTouch?: boolean,
	/**
		Popup text or, react-intl coomponent or object of properties for Popup component.
		PROPS[IntlComponent=/language/localisation/, PopupProps=/components/modals/popup/]
	*/
	popup?: string | IntlComponent | PopupProps,
	/** Function called on menu open. */
	onOpen?: (
		event: SyntheticEvent<>
	) => void,
	/** Function called on menu close. */
	onClose?: (
		event: SyntheticEvent<>
	) => void
};

// Component default property values
const DEFAULTS = {
	icon: 'fa-ellipsis',
	fitted: false,
	off: false,
	disabled: false,
	pointing: 'top',
	keepOpen: false,
	hideTouch: false,
	popup: <FormattedMessage id='component.context-menu.actions' />
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/dropdown/#types-pointing */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	// Variables and refs
	let dropdownRef = useRef();
	let [direction, setDirection] = useState();
	let [open, setOpen] = useState();

	const handleOpen = (event) => {
		// Open menu
		setOpen(true);

		// Fix non existent SUI-R dropdown direction position (https://github.com/Semantic-Org/Semantic-UI-React/issues/3771)
		if (window && window.innerWidth && event && event.clientX) {
			let windowWidth = window.innerWidth;
			let mousePosition = event.clientX;
			if (mousePosition > windowWidth / 2) {
				setDirection('left');
			}
		}

		// Trigger onClick callback funtion
		if (typeof props.onOpen === 'function') {
			props.onOpen(event);
		}
	};

	const handleClose = () => {
		// Wait for dropdown tp close
		setTimeout(() => setOpen(false), 100);

		// Trigger onClick callback funtion
		if (typeof props.onClose === 'function') {
			props.onClose(event);
		}
	};

	const getOptions = () => {
		let options = [];
		if (props.options && props.options.length > 0) {
			props.options.forEach((option, i) => {
				if (option) {
					switch (true) {
						case option.divider === true:
							options.push(<Dropdown.Divider key={`divider-${i}`} />);
							break;
						case option.header !== undefined:
							options.push(<Dropdown.Header content={option.header} key={`header-${i}`} />);
							break;
						case option.checkbox !== undefined:
							options.push(
								<Dropdown.Item className='checkbox' key={`checkbox-${i}`}>
									<Checkbox {...option.checkbox} size={props.size || 'small'} />
								</Dropdown.Item>
							);
							break;
						case option.text !== undefined:
							// Get link type
							let linkType = Validate.linkType(option.link);
							options.push(
								<Dropdown.Item
									key={`item-${i}`}
									as={linkType === 'internal' ? Link : linkType === 'external' ? 'a' : undefined}
									to={linkType === 'internal' ? option.link : undefined}
									href={linkType === 'external' ? option.link : undefined}
									target={linkType === 'external' ? '_blank' : undefined}
									rel={linkType === 'external' ? 'noopener noreferrer' : undefined}
									text={option.text}
									icon={
										Array.isArray(option.icons) ? (
											<Icon.Group
												icons={
													typeof option.icons[0] === 'string'
														? [{ name: option.icons[0] }, { name: option.icons[1] }]
														: option.icons
												}
											/>
										) : typeof option.icon === 'string' ? (
											<Icon name={option.icon} />
										) : (
											<Icon {...option.icon} />
										)
									}
									disabled={option.disabled}
									onClick={option.onClick}
								/>
							);
					}
				}
			});
			return options;
		}
	};

	let trigger = (
		<Button
			className='context-menu'
			icon={!props.iconGroup ? props.icon : undefined}
			iconGroup={props.iconGroup}
			focused={open}
			text={props.text}
			circular
			color={props.color}
			basic={props.basic}
			disabled={props.disabled}
			fitted={props.fitted}
			floated={props.floated}
			off={props.off}
			size={props.size}
			minDevice={props.minDevice}
			maxDevice={props.maxDevice}
			hideTouch={props.hideTouch}
			popup={!open || props.off ? props.popup : undefined}
		/>
	);

	return (
		<Dropdown
			key='context-menu'
			ref={dropdownRef}
			trigger={trigger}
			pointing={props.pointing}
			icon={false}
			disabled={props.disabled}
			direction={direction || props.direction}
			size={props.size}
			open={open}
			onOpen={handleOpen}
			onClose={handleClose}
		>
			{!props.off && !props.disabled ? (
				<Dropdown.Menu
					onClick={
						props.keepOpen
							? (event) => {
									event.stopPropagation();
							  }
							: undefined
					}
				>
					{getOptions()}
				</Dropdown.Menu>
			) : null}
		</Dropdown>
	);
});

// Documentation generation support
Component.displayName = 'ContextMenu';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
