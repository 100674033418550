export default {
	getResponse: (data) => {
		console.info('Mock request for reponse status initiated.');
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				console.info('Mock request for reponse status complete.');
				return resolve(data);
			}, 1000);
		});
	},
	saveFormData: (data) => {
		console.info('Mock request for saving form initiated.');
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				console.info('Mock request for saving form complete.');
				return resolve(data);
			}, 1500);
		});
	},
	validateAndSaveFormData: (data) => {
		console.info('Mock request with backend form validation initiated.', data);
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				console.info('Mock request with backend form validation complete');
				// Fake backend validation - Return error if phone number is too short
				if (data && data.hasOwnProperty('phone-inputInput')) {
					if (data['phone-inputInput'].length < 8) {
						console.info('Phone number is too short. Return error.');
						data.status = 422;
						data.errors = [
							{
								'phone-input': 'component.prefill-input.errors.invalid_phone_number'
							}
						];
					} else if (data['phone-inputInput'].length >= 8) {
						data.status = 200;
					} else {
						data.status = 500;
					}
				} else {
					data.status = 500;
				}
				return resolve(data);
			}, 1500);
		});
	}
};
