/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { Text } from 'liana-ui/components/';
import type { Tooltip } from 'liana-ui/definitions/component/Types';

type Props = {
	/** Content placed inside .field element */
	children: React.Node,
	/** Info content placed under the field */
	info?: React.Node,
	/** Guide content placed under the field */
	guide?: React.Node,
	/** Classes for .field element */
	classes?: string,
	/** Id for .field element */
	id?: string,
	/** A field cane appear be required */
	required?: boolean,
	/** A field can be disabled */
	disabled?: boolean,
	/** A field can be in error state and display additional error message */
	error?: boolean | string | React.Node,
	/** A field can be hidden */
	hidden?: boolean,
	/** Fomantic UI grid witdh (one, two, three, four, five etc.) */
	width?: string,
	/** Wrap field/fields in a visual segment */
	wrap?: boolean,
	/** Displayes inputs horizontally */
	inline?: boolean,
	/** Displays inputs vertivcally */
	grouped?: boolean,
	/** Move above/below fields closer together */
	combined?: boolean,
	/** Tooltip (data-content, data-html, data-position, data-delay, data-hoverable, data-on) */
	tooltip?: Tooltip
};

/** COMPONENT BASED ON: https://fomantic-ui.com/collections/form.html */
export default class FormField extends React.PureComponent<Props> {
	constructor(props: Props) {
		super(props);
	}
	render() {
		const classes = classNames(
			this.props.width,
			{
				'wide field': this.props.width,
				field: !this.props.width,
				inline: this.props.inline,
				grouped: this.props.grouped,
				combined: this.props.combined,
				required: this.props.required,
				error: this.props.error,
				hidden: this.props.hidden,
				'field-disabled': this.props.disabled,
				'ui segment raised compressed': this.props.wrap,
				'popup-open': this.props.tooltip
			},
			this.props.classes
		);

		const infoClasses = classNames('field-info', {
			off: this.props.disabled
		});

		const guideClasses = classNames('ui pointing top left label field-guide-prompt', {
			off: this.props.disabled
		});

		return (
			<div className={classes} id={this.props.id} {...this.props.tooltip}>
				{this.props.children}
				{typeof this.props.error === 'string' || React.isValidElement(this.props.error) ? (
					<div className='ui basic red pointing prompt label transition visible'>{this.props.error}</div>
				) : null}

				{this.props.info ? (
					<div className={infoClasses}>
						<Text size='small'>{this.props.info}</Text>
					</div>
				) : null}
				{this.props.guide ? <div className={guideClasses}>{this.props.guide}</div> : null}
			</div>
		);
	}
}
