/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Sidebar, Divider, Button, Header, Segment } from 'liana-ui/components/';
import { type Props as LabelProps } from 'liana-ui/components/Label';

import isNumber from 'lodash/isNumber';

// prettier-ignore
type Props = {
	/** Content as children. */
	children: React.Node,
	/** Controls whether or not the sidebar is visible on the page. */
	visible?: boolean,
	/** Clear label props */
	clearLabelProps?: LabelProps,
	/** Modified filters in panel. */
	modifiedFilters?: number,
	/** Boolean that indicates if the form was modified */
	isFormModified: boolean,
	/** A panel or modal can have different widths. */
	width?: 'thin' | 'wide' | 'very wide' | 'fullscreen',
	/** Called when all filters are cleared at once. */
	onClear?: (
		event: SyntheticEvent<>
	) => void,
	/** Called before a sidebar begins to animate out. */
	onHide?: (
		event: SyntheticEvent<>
	) => void,
	/** Called on apply filters and clear filters. */
	onSubmit?: (
		event: SyntheticEvent<>
	) => void
};

const DEFAULTS = {
	visible: false
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/sidebar/ */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	const [isBottomScrolled, setIsBottomScrolled] = useState(false);
	const [filtersFooterState, setFiltersFooterState] = useState({ active: false, slideIn: false, slideOut: false });
	const handleHide = (event) => {
		if (typeof props.onHide === 'function') {
			props.onHide(event);
		}
	};
	const handleOnClearButtonClick = () => {
		// Slides out the filters footer when the clear button is clicked
		// This is done for better performance when the form is cleared
		if (!isBottomScrolled) {
			setFiltersFooterState({ active: false, slideIn: false, slideOut: true });
		}

		// Triggers the onClear callback
		if (typeof props.onClear === 'function') {
			props.onClear();
		}
	};

	let clearLabel = {
		...props.clearLabelProps,
		onClick: handleOnClearButtonClick
	};

	const handleClick = () => {
		if (typeof props.onSubmit === 'function') {
			props.onSubmit();
		}

		handleHide();
	};

	useEffect(() => {
		// Slides in or out the filters footer depeding on the form state
		if (!isBottomScrolled) {
			if (props.isFormModified && (!filtersFooterState.active || filtersFooterState.slideOut)) {
				// If the form is modified and the filters footer is not active or slided out, slide it in
				setFiltersFooterState({ active: true, slideIn: true, slideOut: false });
			} else if (!props.isFormModified && (filtersFooterState.active || filtersFooterState.slideIn)) {
				// If the form is not modified and the filters footer is active or slided in, slide it out
				setFiltersFooterState({ active: false, slideIn: false, slideOut: true });
			}
		}
	}, [props.isFormModified]);

	useEffect(() => {
		const sidebar = document.querySelector('.sidebar.overlay');
		// Updates the state if the sidebar is scrolled to/from the bottom
		const setIsScrolledToBottom = () => {
			if (Math.abs(sidebar.scrollHeight - sidebar.scrollTop - sidebar.clientHeight) < 1) {
				if (!isBottomScrolled) {
					setIsBottomScrolled(true);
				}
			} else {
				if (isBottomScrolled) {
					setIsBottomScrolled(false);
				}
			}
		};

		// Triggers initial isBottomScrolled state setup on useeffect mount
		setIsScrolledToBottom();

		const resizeListener = window.addEventListener('resize', setIsScrolledToBottom);
		const scrollListener = sidebar.addEventListener('scroll', setIsScrolledToBottom);

		return () => {
			window.removeEventListener('resize', resizeListener);
			sidebar.removeEventListener('scroll', scrollListener);
		};
	}, [props.visible, isBottomScrolled]);

	return (
		<Sidebar visible={props.visible} padded={false} width={props.width} onHide={handleHide}>
			<Segment basic removeMargins='bottom'>
				<Header
					text={<FormattedMessage id='component.filter-sidebar.filters' />}
					size='large'
					dividing
					labels={[clearLabel]}
					floatedContent={<Button icon='close' size='mini' fitted circular onClick={handleHide} />}
				/>
				{props.children}
			</Segment>
			<div
				className={classNames('sidebar-footer', {
					sticky: filtersFooterState.active,
					shadows: filtersFooterState.active && !isBottomScrolled,
					'show-animated': filtersFooterState.slideIn,
					'hide-animated': filtersFooterState.slideOut
				})}
			>
				<Segment basic textAlign='center' removeMargins='all' removePaddings={['top', 'horizontal']}>
					<Divider removeMargins='top' />
					<Button
						text={
							<FormattedMessage
								id={`component.filter-sidebar.${
									isNumber(props?.modifiedFilters) ? 'applyFilters' : 'close'
								}`}
								values={{ amount: props.modifiedFilters }}
							/>
						}
						fitted
						size='small'
						color='primary'
						off={!props.isFormModified}
						onClick={handleClick}
					/>
				</Segment>
			</div>
		</Sidebar>
	);
});

// Documentation generation support
Component.displayName = 'FilterSidebar';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
