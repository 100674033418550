/**
 * @prettier
 * @flow
 */

import { isEqual } from 'lodash';
import { useRef, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import Validate from 'liana-ui/definitions/component/Validate';
import { Search } from 'semantic-ui-react';
import SearchResult from './src/SearchResult';
import { Button, Popup } from 'liana-ui/components';
import type { IntlComponent } from 'react-intl';
import type { Props as PopupProps } from 'liana-ui/components/popup/Popup';

type callbackType = (
	event: SyntheticEvent<>,
	data: {
		name: string,
		value: string,
		minCharacters: number
	}
) => void;

// prettier-ignore
type Props = {
	/** A search can have an input name */
	name?: string,
	/** Initial value for input. Use for uncontrolled components only. */
	defaultValue?: string,
	/** Current value for input. Use for controlled components only. */
	value?: string,
	/** An search can have a placeholder text. Use intl.formatMessage() for translated strings. */
	placeholder?: string,
	/**
		A search can have search results.
		DATA[json/search/autocomplete-with-links.json]
	*/
	results: Array<{
		title: string,
		icon: string,
		description: string,
		link: string,
		showMoreLink: boolean,
		onClick: (
			event: SyntheticEvent<>,
			data: any
		) => void
	}>,
	/**
		A search can have a custom message to display when there are no results.
		PROPS[IntlComponent=/localization/]
	*/
	noResultsMessage?: string | IntlComponent,
	/** A search can be loading when updating results. */
	loading?: boolean,
	/** An input can be locked to indicate that the field is in use but can not be edited. */
	locked?: boolean,
	/** A search can be disabled. */
	disabled?: boolean,
	/** A search can take on the size of its container. */
	fluid?: boolean,
	/** A search can delay onSearchChangeDelay callback. */
	delay?: number,
	/** A search can have maximum amount of results to display. */
	maxResults?: number,
	/** A search can have minimum characters required to query and show results. */
	minCharacters?: number,
	/** A search can have a 'Show more' link if results amount is bigger than maxResults. */
	showMoreLink?: string,
	/** A search can have a Url to submit the search query to. */
	submitUrl?: string,
	/** A search can be different size. */
	size?: 'small' | 'large',
	/** Number that will be shown on 'Show all results' button */
	total?: number,
	/**
		Popup text or, react-intl coomponent or object of properties for Popup component.
		PROPS[IntlComponent=/language/localisation/, PopupProps=/components/modals/popup/]
	*/
	popup?: string | IntlComponent | PopupProps,
	/** Function called on search focus. */
	onFocus?: callbackType,
	/** Function called on search submit. */
	onSubmit?: callbackType,
	/** Function called on search input change. */
	onSearchChange?: callbackType,
	/** Function called on search input change after delay. Use delay property to control time. */
	onSearchChangeDelay?: callbackType,
	/** Function called on search resukt select. */
	onResultSelect?: callbackType
};

// Component default property values
const DEFAULTS = {
	delay: 400,
	minCharacters: 1,
	fluid: false,
	loading: false,
	disabled: false,
	locked: false,
	results: false,
	noResultsMessage: <FormattedMessage id='component.search-input.noResults' />
};

// Component render conditions. True if should not render.
const EQUALS = (prevProps, nextProps) =>
	prevProps.value === nextProps.value &&
	prevProps.loading === nextProps.loading &&
	prevProps.disabled === nextProps.disabled &&
	prevProps.locked === nextProps.locked &&
	isEqual(prevProps.results, nextProps.results);

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/search/ */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>(
	React.forwardRef((props: Props, ref: any) => {
		const intl = useIntl();
		const navigate = useNavigate();
		let timeoutRef = useRef();

		// Variables and refs
		let searchWrapperRef = useRef();
		let showMoreLink = {
			showMoreLink: true,
			title: '',
			description: '',
			icon: 'arrow right',
			link: ''
		};

		// Internal states
		let [internalValue, setInternalValue] = useState(props.defaultValue);
		const value = props.value === undefined ? internalValue : props.value;

		// Function called on clear button click
		const handleClear = () => {
			handleChange(null, handleCallbackData({ value: '' }));
			searchWrapperRef.current.querySelector('input').focus();
		};

		// Function called on input focus.
		const handleFocus = (event: ?SyntheticEvent<>) => {
			if (typeof props.onFocus === 'function') {
				props.onFocus(event, handleCallbackData({ value: event.target.value }));
			}
		};

		// Function called on input change.
		const handleChange = (event: ?SyntheticEvent<>, data: any) => {
			// Set current value internally
			setInternalValue(data.value);

			// Trigger onChange callback with formatted data
			if (typeof props.onSearchChange === 'function') {
				props.onSearchChange(event, handleCallbackData(data));
			}

			// Function called on input change with set delay.
			if (typeof props.onSearchChangeDelay === 'function') {
				if (timeoutRef.current) {
					clearTimeout(timeoutRef.current);
				}
				if (data.value) {
					timeoutRef.current = setTimeout(() => {
						props.onSearchChangeDelay(event, handleCallbackData(data));
					}, props.delay);
				} else {
					props.onSearchChangeDelay(event, handleCallbackData(data));
				}
			}
		};

		// Called result select.
		const handleSelect = (event: ?SyntheticEvent<>, data: any) => {
			// Set result title as selected result
			data.value = data.result.title;

			if (data.result.link) {
				removeValue();
				if (event.type !== 'click') {
					redirect(data.result.link);
				}
			} else {
				// Set current value internally
				setInternalValue(data.value);

				// Trigger Form onChange to animate submit button into view etc.
				if (typeof event === 'object' && event.target && typeof event.target.dispatchEvent === 'function') {
					event.target.dispatchEvent(new Event('change', { bubbles: true }));
				}
			}

			if (typeof props.onResultSelect === 'function') {
				props.onResultSelect(event, handleCallbackData(data));
			}
		};

		// Called result click.
		const handleClick = (event: ?SyntheticEvent<>, data: any) => {
			if (data.link) {
				event.preventDefault();
				redirect(data.link);
			}
		};

		// Called field submit.
		const handleSubmit = (event: ?SyntheticEvent<>) => {
			// Submit on button click and keyboard Enter
			if (props.submitUrl && (event.type === 'click' || (event.type === 'keypress' && event.key === 'Enter'))) {
				event.preventDefault();
				event.stopPropagation();
				if (typeof props.onSubmit === 'function') {
					props.onSubmit(event, handleCallbackData({ value: event.target.value }));
				}
				navigate(`${props.submitUrl}?q=${value ? encodeURIComponent(value) : ''}`);
				removeValue();
			}
		};

		// Handle data returned by callbacks.
		const handleCallbackData = (data: any) => {
			return {
				name: props.name,
				value: data.value,
				minCharacters: props.minCharacters
			};
		};

		// Remove input value
		const removeValue = () => {
			handleChange(null, { value: '' });
			searchWrapperRef.current.querySelector('input').blur();
		};

		// Redirect to link
		const redirect = (link) => {
			// Check link type
			let linkType = Validate.linkType(link);

			// Trigger internal link
			if (linkType) {
				if (linkType === 'external') {
					window.open(link, '_blank').focus();
				}
				if (linkType === 'internal') {
					navigate(link);
				}
				if (linkType === 'anchor') {
					Safely.scroll(link, () => {
						navigate(`${window.location.pathname}${link}`);
					});
				}
			}
		};

		const options = props?.results || [];

		// Limit results
		const limitedOptions =
			options && options.length > 0 && props.maxResults ? options.slice(0, props.maxResults) : options;

		// Add "Show More" -link as last item if more results than maxResults to list
		if (
			props.showMoreLink &&
			(options.length > props.maxResults || (props.total && props.total > options.length))
		) {
			let count = props.total || options.length;
			showMoreLink.title = intl.formatMessage({ id: 'component.search-input.viewAllResults' }, { count: count });
			showMoreLink.link = `${props.showMoreLink}?q=${value}`;
			if (limitedOptions.length > 0) {
				limitedOptions.push(showMoreLink);
			}
		}

		// Format options
		limitedOptions.forEach((option, index) => {
			if (option.link) {
				limitedOptions[index] = {
					...option,
					as: 'a',
					href: option.link
				};
			}
		});

		// Set loading
		let loading = props.loading && !props.disabled && !props.locked && value && value.length >= props.minCharacters;

		// Set placeholder
		let placeholder = props.placeholder
			? props.placeholder
			: intl.formatMessage({ id: 'component.search-input.placeholder' });

		// Assign classes
		let wrapperClasses = classNames('search-wrapper', {
			fluid: props.fluid,
			'has-button': props.submitUrl
		});

		let classes = classNames({
			locked: props.locked
		});

		// Field clear button
		const clearButton = (
			<Button
				icon='close'
				circular
				size='extramini'
				onClick={handleClear}
				hidden={!value || props.disabled || props.locked}
			/>
		);

		// Field submit button
		const submitButton = (
			<Button
				type='submit'
				icon='search'
				ariaLabel='Search'
				circular={false}
				loading={loading}
				disabled={props.disabled || props.locked}
				size={props.size}
				onClick={handleSubmit}
			/>
		);

		// Search field
		let search = (
			<div className={wrapperClasses} ref={searchWrapperRef}>
				<div>{clearButton}</div>
				<Search
					ref={ref}
					name={props.name}
					data-default={props.defaultValue}
					value={value}
					placeholder={placeholder}
					className={classes}
					minCharacters={props.minCharacters}
					fluid={props.fluid}
					input={{
						fluid: props.fluid,
						action: props.submitUrl ? submitButton : null,
						iconPosition: props.submitUrl ? undefined : 'left'
					}}
					icon={props.submitUrl ? null : 'search'}
					loading={loading}
					size={props.size}
					disabled={props.disabled || props.locked}
					results={limitedOptions ? limitedOptions : undefined}
					noResultsMessage={props.noResultsMessage}
					resultRenderer={(rendererProps) => (
						<SearchResult
							title={rendererProps.title}
							description={rendererProps.description}
							icon={rendererProps.icon}
							link={rendererProps.link}
							showMoreLink={rendererProps.showMoreLink}
							onClick={handleClick}
						/>
					)}
					showNoResults={props.results !== false && !props.loading}
					onFocus={handleFocus}
					onSearchChange={handleChange}
					onResultSelect={handleSelect}
					onKeyPress={handleSubmit}
				/>
			</div>
		);

		// Attach popup
		return Popup.attach(props.popup, search);
	}, EQUALS)
);

// Documentation generation support
Component.displayName = 'Search';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
