/**
 * @prettier
 * @flow
 */

import { useState, useRef, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Dropdown } from 'semantic-ui-react';
import Form from 'liana-ui/legacy/components/form/Form';
import {
	Text,
	Icon,
	List,
	Divider,
	Header,
	Message,
	Segment,
	Image,
	Popup,
	Dropdown as DropdownSelection
} from 'liana-ui/components/';
import type { Props as PopupType } from 'liana-ui/components/popup/Popup';

// prettier-ignore
type Props = {
	/** If is open */
	open: boolean,
	/** If preview mode */
	preview: boolean,
	/** User menu data. User object: DATA[json/user/user.json] */
	user: {
		id: string,
		selectedOrganization: string,
		selectedSolution: string,
		name: string,
		email: string,
		organizations: Array<{ name: string, ... }>,
		...
	},
	/** Application menu data. Array of applications object: DATA[json/applications/applications.json] */
	applications?: {
		organizations: Array<{ name: string, ... }>,
		links: { manageMenu: string, addSolution: boolean }
	},
	/** Position, with and heigh of dropdown menu */
	style: { [key: string]: string },
	/** Object of properties for Popup */
	popup?: PopupType,
	/** Called on menu open. */
	onOpen?: (
		event: SyntheticEvent<>,
		data: {
			id: string
		}
	) => void,
	/** Called on menu close. */
	onClose?: (
		event: SyntheticEvent<>,
		data: {
			id: string
		}
	) => void
};

const DEFAULTS = {
	open: false
};

const ID = 'application';

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/dropdown/ and https://react.semantic-ui.com/collections/menu/ */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	// Render nothing if empty
	if (!props.user || !props.applications) {
		return null;
	}

	const popupMount = useRef();

	const isMobile =
		document.querySelector('html').classList.contains('mobile') ||
		document.querySelector('html').classList.contains('tablet');

	const getOrganizationID = () => {
		let organizationID = undefined;
		if (props.preview) {
			organizationID = props.applications.organizations[0].id;
		} else {
			organizationID = props.user?.selectedOrganization;
			// If organization not selected pick first
			if (!organizationID && props.user.organizations && props.user.organizations.length > 0) {
				organizationID = props.user.organizations[0].id;
			}
			// TODO: Why are these duplicated?
			if (!organizationID && props.applications.organizations && props.applications.organizations.length > 0) {
				organizationID = props.applications.organizations[0].id;
			}
		}
		// Note: If organizationID is still null here, everything will break
		return organizationID;
	};

	const [openSelection, setOpenSelection] = useState(false);
	const [selectedOrganization, setSelectedOrganization] = useState(getOrganizationID());

	useEffect(() => {
		// Reset organization back to default when menu is closed
		let org = getOrganizationID();
		if (org !== selectedOrganization) {
			setSelectedOrganization(org);
		}
	}, [props.open]);

	const getOrganizations = () => {
		// Only one organization present, no point in rendering selection
		if (!props.user.organizations || props.user.organizations.length < 2) {
			return null;
		}

		let organizations = [];
		let previousSection = null;
		props.user.organizations.map((organization, i) => {
			if (i > 0 && organization.section && organization.section !== previousSection) {
				organizations.push({ divider: true });
			}
			organizations.push({
				text: organization.name,
				className: 'text-hyphenate',
				image: <Image src={organization.image} squared avatar='building' size='favicon' />,
				value: organization.id
			});
			previousSection = organization.section;
		});

		return (
			<>
				<Segment basic compressed removeMargins='all'>
					<Form>
						<Form.Field>
							<Text as='p' size='small' bold>
								<FormattedMessage id='component.application-menu.displaySolutions' />:
							</Text>
							<DropdownSelection
								open={openSelection}
								name='name'
								search
								scrolling
								size='small'
								value={selectedOrganization}
								options={organizations}
								onClick={() => setOpenSelection(true)}
								onBlur={() => setOpenSelection(false)}
								onChange={handleChangeOrganization}
							/>
						</Form.Field>
					</Form>
				</Segment>
			</>
		);
	};

	const handleChangeOrganization = (event: ?SyntheticEvent<>, data: any) => {
		setSelectedOrganization(data.value);
		setOpenSelection(false);
	};

	const getSolutions = () => {
		if (!props.applications || !props.applications.organizations) {
			return null;
		}

		let menu = [];
		let solutions = [];
		solutions = props.applications.organizations.filter(
			(organization) => selectedOrganization === organization.id
		)[0];

		if (solutions) {
			solutions.solutions.forEach((solution, index) => {
				let header = (
					<Header
						as='h5'
						text={solution.name}
						subheader={
							solution.additionalName ? (
								<Text limit={40} popup={{ size: 'mini', mountNode: popupMount.current }}>
									{solution.additionalName}
								</Text>
							) : undefined
						}
						key={index}
					/>
				);

				let applications = getApplications(
					Array.isArray(solution.applications) ? solution.applications : [],
					props.user?.selectedSolution === solution.id && solution.applications.length === 1 // Currently used to display active application for single app solutions. Should be changed as soon as API returns actual selectedApplication data.
				);
				menu.push(
					<>
						{index > 0 || props.user.organizations.length > 1 ? <Divider /> : null}
						{header} {applications}
					</>
				);
			});
		} else {
			menu.push(
				<Segment basic compressed removeMargins='all'>
					<Message
						error
						layout='big'
						size='mini'
						vertical={true}
						header='This account has an organization misconfiguration'
						content={
							<>
								Please{' '}
								<a href='https://support.lianatech.com/hc/en-us/requests/new' target='_blank'>
									submit a bug report
								</a>{' '}
								and we will fix it as soon as possible.
							</>
						}
					/>
				</Segment>
			);
		}

		return menu;
	};

	const getApplications = (applications: Array<any>, active: boolean) => {
		let items = [];
		applications.forEach((application) => {
			items.push(
				<Dropdown.Item
					className='application-option'
					as='a'
					active={active}
					href={application.url}
					target={application.target}
				>
					<Header
						text={application.name}
						image={{ src: application.image, squared: true, avatar: 'fa-globe' }}
						size='tiny'
					/>
				</Dropdown.Item>
			);
		});

		return items;
	};

	const getLinks = () => {
		if (!props.applications.organizations) {
			return null;
		}

		let links = [];
		let organization = props.applications.organizations.filter(
			(organization) => selectedOrganization === organization.id
		)[0];

		if (organization && organization.links && (organization.links.manageMenu || organization.links.addSolution)) {
			if (organization.links.manageMenu) {
				links.push({
					content: <FormattedMessage id='component.application-menu.manageMenu' />,
					icon: 'configure',
					link: organization.links.manageMenu,
					linkTarget: '_self',
					onClick: (event) => props.onClose(event)
				});
			}
			if (organization.links.addSolution) {
				links.push({
					content: <FormattedMessage id='component.application-menu.addSolution' />,
					icon: 'add',
					link: organization.links.addSolution,
					linkTarget: '_self',
					onClick: (event) => props.onClose(event)
				});
			}
			return (
				<>
					<Divider />
					<Segment basic compressed removeMargins='all'>
						<List size='tiny' items={links} />
					</Segment>
				</>
			);
		}
		return null;
	};

	const handleOpen = (event: ?SyntheticEvent<>, data: any) => {
		if (typeof props.onOpen === 'function') {
			props.onOpen(event, handleCallbackData(data));
		}
	};

	const handleClose = (event: ?SyntheticEvent<>, data: any) => {
		if (typeof props.onClose === 'function') {
			props.onClose(event, handleCallbackData(data));
		}
	};

	// Handle data returned by onClose callback.
	const handleCallbackData = (data: any) => ({
		id: data.id
	});

	let menu = props.preview ? (
		<div className={`${ID}Dropdown`}>{getSolutions()}</div>
	) : (
		<Dropdown
			id={`${ID}Menu`}
			tabIndex={false}
			closeOnBlur={false}
			open={props.open}
			item
			multiple
			icon={
				<Icon
					name={isMobile && props.open ? 'fa-close' : 'fa-grid'}
					size={isMobile && props.open ? undefined : 'large'}
					circular={isMobile && props.open ? true : false}
				/>
			}
			onOpen={handleOpen}
			onClose={handleClose}
		>
			<Dropdown.Menu
				id={`${ID}Dropdown`}
				className={`${ID}Dropdown`}
				style={props.style}
				onClick={(event) => event.stopPropagation()}
			>
				{props.open ? (
					<>
						{getOrganizations()}
						{getSolutions()}
						{getLinks()}
					</>
				) : null}
			</Dropdown.Menu>
		</Dropdown>
	);

	// Attach popup
	menu =
		!props.open && !props.preview
			? Popup.attach({ text: <FormattedMessage id='component.application-menu.name' />, ...props.popup }, menu)
			: menu;

	menu = (
		<>
			{menu}
			<div id='popup-mount' ref={popupMount} />
		</>
	);

	return menu;
});

// Documentation generation support
Component.displayName = 'ApplicationMenu';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
