/**
 * @prettier
 * @flow
 */

import { Breadcrumb } from 'semantic-ui-react';

/** COMPONENT BASED ON: https://react.semantic-ui.com/collections/breadcrumb/ */
let Component: React.AbstractComponent = React.memo(() => <Breadcrumb.Divider icon='fa-chevron-right' />);

// Documentation generation support
Component.displayName = 'BreadcrumbDivider';

export default Component;
