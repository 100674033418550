/**
 * @prettier
 * @flow
 */

import { useRef, useState, useEffect, useLayoutEffect } from 'react';
import classNames from 'classnames';
import Validate from 'liana-ui/definitions/component/Validate';
import ResizeSensor from 'css-element-queries/src/ResizeSensor';
import { StickyTableHeader } from 'vh-sticky-table-header';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Table } from 'semantic-ui-react';
import Form from 'liana-ui/legacy/components/form/Form';
import Chart from 'liana-ui/legacy/components/chart/Chart';
import {
	Text,
	Icon,
	Divider,
	Segment,
	Message,
	EmptyState,
	ActionHeader,
	Grid,
	ChartInfo,
	Image,
	Checkbox,
	Label,
	Header,
	Button,
	ContextMenuDetached,
	Breadcrumb
} from 'liana-ui/components/';
import TableRow from './src/TableRow';
import TableHeaderCell from './src/TableHeaderCell';
import TableBodyCell from './src/TableBodyCell';
import TableFooterCell from './src/TableFooterCell';
import TableUtils from './src/TableUtils';
import type { Props as ActionHeaderProps } from 'liana-ui/components/header/ActionHeader';
import type { Props as BreadcrumbProps } from 'liana-ui/components/breadcrumb/Breadcrumb';
import type { Props as HeaderProps } from 'liana-ui/components/header/Header';
import type { Props as LimitLabelProps } from 'liana-ui/components/label/LimitLabel';
import type { Props as ContextMenuDetachedProps } from 'liana-ui/components/menu/ContextMenuDetached';
import type { Props as PopupProps } from 'liana-ui/components/popup/Popup';
import type { Props as ChartProps } from 'liana-ui/legacy/components/chart/Chart';
import type { Props as ChartInfoProps } from 'liana-ui/components/chart/ChartInfo';

// prettier-ignore
type Props = {
	/** An action table must be provided with the total amount of possible rows, without paging. Folder rows included. */
	totalRows: number,
	/**
		An action table must have it's columns defined so whole columns can be cotnrolled.
		DATA[json/action-table/columns.json]
	*/
	columns: Array<{
		name: string,
		width: number,
		collapsing: boolean,
		minDevice: string,
		textAlign: string,
		singleLine: boolean,
		breakWord: boolean,
		noWrap: boolean
	}>,
	/**
		An action table may have an action header with various buttons and filters.
		PROPS[ActionHeaderProps=/components/containers/action-header/]
	*/
	actionHeader?: ActionHeaderProps,
	/**
		An action table can have a header row.
		DATA[json/action-table/header-row.json]
		PROPS[IntlComponent=/language/localisation/, PopupProps=/components/modals/popup/]
	*/
	headerRow?: Array<{
		content: any,
		textAlign: string,
		width: string,
		collapsing: boolean,
		popup: string | IntlComponent | PopupProps
	}>,
	/**
	 	An action table can have body rows. ID:s (also added ones) must always be unique.
		DATA[json/action-table/body-rows.json]
		PROPS[IntlComponent=/language/localisation/, HeaderProps=/components/texts/header/, ContextMenuDetachedProps=/components/menus/context-menu/#detached, PopupProps=/components/modals/popup/]
	*/
	bodyRows: Array<{
		id: number | string,
		active: boolean,
		activable: boolean,
		selectable: boolean,
		folder: boolean,
		folderItems: number,
		cells: Array<{
			content: any,
			header: HeaderProps,
			link: string,
			state: {
				text: string,
				color: string
			},
			contextMenu: ContextMenuDetachedProps | React.Node,
			textAlign: string,
			verticalAlign: string,
			width: string,
			collapsing: boolean,
			success: boolean,
			warning: boolean,
			error: boolean,
			colSpan: number,
			rowSpan: number,
			singleLine: boolean,
			breakWord: boolean,
			noWrap: boolean,
			popup: string | IntlComponent | PopupProps,
			onClick?: (
				event: SyntheticEvent<>
			) => void
		}> | any
	}>,
	/** Mapped over any data provided for bodyRows property. Should return body row object for body rows. */
	renderBodyRow?: (
		data,
		index
	) => {
		id: number | string,
		active: boolean,
		activable: boolean,
		selectable: boolean,
		folder: boolean,
		folderItems: number,
		cells: Array<{
			content: any,
			...
		}>
	},
	/**
		An action table can have a footer row.
		DATA[json/action-table/footer-row.json]
		PROPS[IntlComponent=/language/localisation/, PopupProps=/components/modals/popup/]
	*/
	footerRow?: Array<{
		content: any,
		textAlign: string,
		width: string,
		collapsing: boolean,
		popup: string | IntlComponent | PopupProps
	}>,
	/** An action table can be in loading state. */
	loading?: boolean,
	/** An action table can be formatted to display complex structured data. */
	structured?: boolean,
	/** An action table can have its rows change background on hover. */
	hoverable?: boolean,
	/** An action table can stack columns on mobile. */
	stackable?: boolean,
	/** An action table can appear as a segment. */
	segment?: boolean,
	/**
		An action table can have a breadcrumb to navigate folders.
		PROPS[BreadcrumbProps=/components/menus/breadcrumb/]
	*/
	breadcrumb?: BreadcrumbProps,
	/**
		An action table can have a chart and chart info between header and table.
		PROPS[ChartProps=/components/statistics/charts/, ChartInfoProps=/components/statistics/charts/filter]
	*/
	chart?: {
		chart: ChartProps,
		chartInfo: ChartInfoProps
	} | React.Node,
	/**
		An action table can have any extra items or content between header and table.
	*/
	extra?: any,
	/** The visible totalRows amount in the header can be increased by the amount of extra items if there are some. */
	extraAmount?: number,
	/** 
	  	An action table can have it's items limited and show a limit label. 
	 	PROPS[LimitLabelProps=/components/labels/limit-label/]
	*/
	limit?: LimitLabelProps,
	/** An action table can allow a user to sort contents by clicking on a table header. */
	sort?: {
		sortColumns: Array<string>,
		sortedColumn: string,
		sortedDirection: 'asc' | 'desc',
		onSort: (
			event: SyntheticEvent<>,
			data: {
				column: string,
				direction: string
			}
		) => void
	},
	/** An action table can allow a user to select rows. */
	select?: {
		enableSelectAll: boolean,
		selectedRows: Array<string>,
		onSelect: (
			event: SyntheticEvent<>,
			data: {
				name: string,
				value: string | number,
				checked: boolean,
				selected: Array<string>
			}
		) => void
	},
	/** An action table can allow a user to select rows. PROPS[PopupProps=/components/modals/popup/]  */
	activate?: {
		enableActivateAll: boolean,
		activateAllOn: boolean,
		popup: PopupProps,
		onActive: (
			event: SyntheticEvent<>,
			data: {
				name: string,
				value: string | number,
				checked: boolean
			}
		) => void
	},
	/** An action table can allow to paginate rows. */
	paginate?: {
		currentPage: number,
		paginateAmount: number,
		loadingMore: boolean,
		onLoadMore: (
			event: SyntheticEvent<>,
			data: {
				currentPage: number
			}
		) => void
	},
	/** An action table must have an empty state. PROPS[...EmptyStateProps=/components/feedback/empty-state/empty-state]  */
	emptyState: {
		displayEmptyState: boolean,
		...EmptyStateProps
	},
	/** An action table can override some default translation keys. */
	translations?: {
		totalItemsLabel: string,
		noResults: string
	},
	/** An action table can be smaller in size. */
	size?: 'small',
	/** An action table should show an animation (glow) to added rows. Must match the row IDs of the rows to be animated also by type. */
	addedRows?: Array<number | string>,
	/** An action table should show an animation (fly away) on deleted rows. Must match the row IDs of the rows to be animated also by type. */
	deletedRows?: Array<number | string>,
	/** Callback on when an delete animation (fly away) is complete. Must match the row IDs of the rows to be animated also by type. Call API to remove the item from database and and re-render table. Remember also to clear deletedRows in this callback. */
	onAfterDeleteRows?: () => void
};

const DEFAULTS = {
	loading: false,
	structured: false,
	hoverable: true,
	stackable: false,
	segment: true,
	emptyState: {
		displayEmptyState: false,
		image: `${process.env.baseUrl}img/empty-states/empty-default.png`,
		header: <FormattedMessage id='component.action-table.emptyStateHeader' />,
		content: <FormattedMessage id='component.action-table.emptyStateContent' />
	},
	translations: {
		totalItemsLabel: 'component.action-table.totalItemsLabel',
		noResults: 'component.action-table.noResults'
	}
};

let addedRows = [],
	selectedRows = [];

/** COMPONENT BASED ON: https://react.semantic-ui.com/collections/table/ */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	// Variables, states and refs
	const scrollWrapperRef = useRef();
	const tableRef = useRef();
	const tableCloneRef = useRef();
	let [randomTableID] = useState(`actiontable-${Date.now()}-${Math.round(Math.random() * 9999)}`);
	let [stickyHeight, setStickyHeight] = useState();
	let [horizontalScrollActive, setHorizontalScrollActive] = useState(true);
	let [leftScrollActive, setLeftScrollActive] = useState(false);
	let [rightScrollActive, setRightScrollActive] = useState(true);

	// Merge translations
	let translations = Object.assign({}, DEFAULTS.translations, props.translations);

	// Merge empty state
	let emptyState = Object.assign({}, DEFAULTS.emptyState, props.emptyState);

	// Internal variables and states
	selectedRows = props.select && props.select.selectedRows ? props.select.selectedRows : [];

	// Set and update sticky table header
	useLayoutEffect(() => {
		if (!document.querySelector('html').classList.contains('mobile') && tableRef.current && tableCloneRef.current) {
			// Initialize the sticky header.
			const sticky = new StickyTableHeader(tableRef.current, tableCloneRef.current, {
				max: stickyHeight ? stickyHeight : 0
			});
			// Make sure to destory the sticky header once the main table is unmounted.
			return () => sticky.destroy();
		}
	});

	// Set horizontal sticky and scrolling
	useEffect(() => {
		if (scrollWrapperRef.current && tableRef.current) {
			setHorizontalSticky();

			new ResizeSensor(scrollWrapperRef.current, function () {
				setHorizontalSticky();
			});

			new ResizeSensor(tableRef.current, function () {
				setHorizontalSticky();
			});

			scrollWrapperRef.current.addEventListener('scroll', function () {
				if (scrollWrapperRef.current.scrollLeft > 0) {
					setLeftScrollActive(true);
				} else {
					setLeftScrollActive(false);
				}
				if (
					scrollWrapperRef.current.scrollLeft + scrollWrapperRef.current.clientWidth <
					scrollWrapperRef.current.scrollWidth
				) {
					setRightScrollActive(true);
				} else {
					setRightScrollActive(false);
				}
			});
		}
	}, []);

	// Clear sensors on unmount
	useEffect(
		() => () => {
			ResizeSensor.detach(scrollWrapperRef.current);
			ResizeSensor.detach(tableRef.current);
			scrollWrapperRef.current.addEventListener('scroll');
		},
		[]
	);

	const setHorizontalSticky = () => {
		const scrollWrapperWidth = scrollWrapperRef.current.getBoundingClientRect().width,
			tableWidth = tableRef.current.getBoundingClientRect().width;
		if (tableWidth > scrollWrapperWidth) {
			setHorizontalScrollActive(true);
			TableUtils.enableHorizontalDrag(scrollWrapperRef.current);
		} else {
			setHorizontalScrollActive(false);
			TableUtils.disableHorizontalDrag(scrollWrapperRef.current);
		}
	};

	// Factory function for transforming data
	let bodyRows = [];
	if (props.bodyRows && props.bodyRows.length > 0) {
		if (typeof props.renderBodyRow === 'function') {
			for (let i = 0; i < props.bodyRows.length; i++) {
				bodyRows[i] = props.renderBodyRow(props.bodyRows[i], i);
			}
		} else {
			bodyRows = props.bodyRows;
		}
	}
	const renderHeaderRow = () => {
		// Check if exists
		if (!props.headerRow || bodyRows.length === 0) {
			return null;
		}

		let cells = [];

		if (props.select) {
			cells.push(
				<TableHeaderCell
					key='tableheadercell-select'
					content={
						props.select.enableSelectAll ? (
							<Checkbox
								fitted
								name='select-all'
								checked={
									props.select && Array.isArray(props.select.selectedRows)
										? bodyRows.filter((row) => row.selectable !== false).length ===
												props.select.selectedRows.length &&
										  bodyRows.filter((row) => row.selectable !== false).length > 0
											? true
											: false
										: undefined
								}
								indeterminate={
									props.select && Array.isArray(props.select.selectedRows)
										? props.select.selectedRows.length > 0 &&
										  bodyRows.filter((row) => row.selectable !== false).length >
												props.select.selectedRows.length
											? true
											: false
										: undefined
								}
								value='all'
								disabled={
									props.totalRows == 0 ||
									bodyRows.filter((row) => row.selectable !== false).length === 0
								}
								size={props.size}
								onChange={handleSelect}
							/>
						) : null
					}
					collapsing
					textAlign='center'
				/>
			);
		}

		if (props.activate) {
			cells.push(
				<TableHeaderCell
					key='tableheadercell-active'
					content={
						props.activate && props.activate.enableActivateAll ? (
							<Checkbox
								checked={props.activate.activateAllOn}
								fitted
								toggle
								name='active'
								value='all'
								disabled={props.totalRows === 0}
								size={props.size}
								onChange={handleActive}
								popup={props.activate.popup}
							/>
						) : null
					}
					minDevice='tablet'
					collapsing
					textAlign='center'
				/>
			);
		}

		props.headerRow.map((cell, cellIndex) => {
			let sortable = undefined,
				sortedColumn = undefined,
				sortedDirection = undefined;

			if (Array.isArray(props.columns) && props.columns.length > 0) {
				cell = { ...props.columns[cellIndex], ...cell };
				if (props.sort) {
					(sortable = Array.isArray(props.sort.sortColumns)
						? props.sort.sortColumns.indexOf(cell.name) !== -1
						: undefined),
						(sortedColumn = props.sort.sortedColumn),
						(sortedDirection = props.sort.sortedDirection);
				}
			}

			// Set header and context menu columns sticky
			let sticky = classNames({
				'sticky-left': bodyRows[0].cells[cellIndex]?.header && horizontalScrollActive,
				'sticky-right': bodyRows[0].cells[cellIndex]?.contextMenu && horizontalScrollActive,
				'sticky-left-shadow': bodyRows[0].cells[cellIndex]?.header && leftScrollActive,
				'sticky-right-shadow': bodyRows[0].cells[cellIndex]?.contextMenu && rightScrollActive
			});

			cells.push(
				<TableHeaderCell
					key={`tableheadercell-${cellIndex}`}
					content={cell.content}
					colSpan={cell.colSpan}
					collapsing={cell.collapsing}
					width={cell.width}
					textAlign={cell.textAlign}
					verticalAlign={cell.verticalAlign}
					sorted={cell.name === sortedColumn ? sortedDirection : undefined}
					sortable={sortable}
					minDevice={cell.minDevice}
					popup={cell.popup}
					sticky={sticky}
					onClick={
						sortable
							? (event) => {
									handleSort(event, {
										column: cell.name,
										direction: cell.name === sortedColumn ? sortedDirection : undefined
									});
							  }
							: undefined
					}
				/>
			);
		});

		return (
			<Table.Header key='tableheaderrow'>
				<Table.Row>{cells}</Table.Row>
			</Table.Header>
		);
	};

	const renderBodyRows = (bodyRows) => {
		// Check if exists
		if (!bodyRows || !Array.isArray(bodyRows)) {
			return null;
		}

		let rows = [];
		let fullColspan =
			(Array.isArray(props.columns) ? props.columns.length : 0) +
			(props.select ? 1 : 0) +
			(props.activate ? 1 : 0);
		if (bodyRows.length > 0) {
			bodyRows.map((row) => {
				let cells = [];

				if (props.select) {
					cells.push(
						<TableBodyCell
							ignoreOff
							key={`tablebodycell-${row.folder ? 'folder' : 'item'}-${row.id}-select`}
							content={
								row.selectable !== false ? (
									<Checkbox
										fitted
										name='selected'
										value={row.id}
										checked={
											props.select && Array.isArray(props.select.selectedRows)
												? props.select.selectedRows.indexOf(row.id) !== -1
													? true
													: false
												: undefined
										}
										size={props.size}
										onChange={handleSelect}
									/>
								) : null
							}
							collapsing
							textAlign='center'
						/>
					);
				}

				if (props.activate) {
					cells.push(
						<TableBodyCell
							ignoreOff
							key={`tablebodycell-${row.folder ? 'folder' : 'item'}-${row.id}-active`}
							content={
								row.activable !== false ? (
									<Checkbox
										fitted
										toggle
										name='active'
										value={row.id}
										checked={row.active}
										size={props.size}
										onChange={handleActive}
										popup={props.activate.popup}
									/>
								) : null
							}
							collapsing
							minDevice='tablet'
							textAlign='center'
						/>
					);
				}

				if (Array.isArray(row.cells)) {
					row.cells.map((cell, cellIndex) => {
						let sortedColumn = undefined,
							sortedDirection = undefined;

						if (Array.isArray(props.columns) && props.columns.length > 0) {
							cell = { ...props.columns[cellIndex], ...cell };
							if (props.sort) {
								sortedColumn = props.sort.sortedColumn;
								sortedDirection = props.sort.sortedDirection;
							}
						}

						// Set header and context menu columns sticky
						let sticky = classNames({
							'header-cell': cell.header,
							'contextmenu-cell': cell.contextMenu,
							'sticky-left': cell.header && horizontalScrollActive,
							'sticky-right': cell.contextMenu && horizontalScrollActive,
							'sticky-left-shadow': cell.header && leftScrollActive,
							'sticky-right-shadow': cell.contextMenu && rightScrollActive
						});

						cells.push(
							<TableBodyCell
								key={`tablebodycell-${row.folder ? 'folder' : 'item'}-${row.id}-${cellIndex}`}
								ignoreOff={cell.contextMenu}
								content={getBodyCellContent(row, cell, cellIndex)}
								colSpan={cell.colSpan}
								collapsing={cell.collapsing}
								width={cell.width}
								textAlign={cell.contextMenu ? 'right' : cell.textAlign}
								verticalAlign={cell.verticalAlign}
								selectable={cell.link || typeof cell.onClick === 'function'}
								sorted={cell.name && sortedColumn && cell.name === sortedColumn && sortedDirection}
								singleLine={!cell.header ? cell.singleLine : false}
								breakWord={!cell.header ? cell.breakWord : false}
								noWrap={!cell.header ? cell.noWrap : false}
								minDevice={cell.minDevice}
								popup={cell.popup}
								sticky={sticky}
							/>
						);
					});
				}

				rows.push(
					<TableRow
						added={
							Array.isArray(props.addedRows) &&
							props.addedRows.indexOf(row.id) !== -1 &&
							addedRows.indexOf(row.id) === -1
						}
						deleted={Array.isArray(props.deletedRows) && props.deletedRows.indexOf(row.id) !== -1}
						active={
							props.select &&
							Array.isArray(props.select.selectedRows) &&
							props.select.selectedRows.indexOf(row.id) !== -1
						}
						off={row.active === false}
						reactKey={`tablebodyrow-${row.id}`}
						onAfterDeleteRows={props.onAfterDeleteRows}
					>
						{cells}
					</TableRow>
				);

				// Disable added row glow animation after it has completed
				if (Array.isArray(props.addedRows) && props.addedRows.indexOf(row.id) !== -1) {
					setTimeout(() => {
						addedRows.push(row.id);
					}, 1000);
				}
			});
		} else {
			rows.push(
				<TableRow key='tablebodyrow-empty'>
					<TableBodyCell
						content={
							<Segment basic compressed='very'>
								<Message
									info
									content={<FormattedMessage id={translations.noResults} />}
									icon='fa-info-circle'
								/>
							</Segment>
						}
						colSpan={fullColspan}
						key='tablebodycell-empty'
					/>
				</TableRow>
			);
		}
		return <Table.Body>{rows}</Table.Body>;
	};

	const renderFooterRow = () => {
		// Check if exists
		if (!props.footerRow || props.totalRows === 0) {
			return null;
		}

		let cells = [];

		if (props.select) {
			cells.push(<TableFooterCell key='tablefootercell-select' collapsing textAlign='center' />);
		}

		if (props.activate) {
			cells.push(
				<TableFooterCell key='tablefootercell-active' collapsing minDevice='tablet' textAlign='center' />
			);
		}

		props.footerRow.map((cell, cellIndex) => {
			let sortedColumn = undefined;

			if (Array.isArray(props.columns) && props.columns.length > 0) {
				cell = { ...props.columns[cellIndex], ...cell };
				if (props.sort) {
					sortedColumn = props.sort.sortedColumn;
				}
			}

			// Set header and context menu columns sticky
			let sticky = classNames({
				'sticky-left': bodyRows[0].cells[cellIndex].header && horizontalScrollActive,
				'sticky-right': bodyRows[0].cells[cellIndex].contextMenu && horizontalScrollActive,
				'sticky-left-shadow': bodyRows[0].cells[cellIndex].header && leftScrollActive,
				'sticky-right-shadow': bodyRows[0].cells[cellIndex].contextMenu && rightScrollActive
			});

			cells.push(
				<TableFooterCell
					key={`tablefootercell-${cellIndex}`}
					content={cell.content}
					colSpan={cell.colSpan}
					collapsing={cell.collapsing}
					width={cell.width}
					textAlign={cell.textAlign}
					verticalAlign={cell.verticalAlign}
					sorted={cell.name && sortedColumn && cell.name === sortedColumn}
					minDevice={cell.minDevice}
					popup={cell.popup}
					sticky={sticky}
				/>
			);
		});

		return (
			<Table.Footer key='tablefooterrow'>
				<Table.Row>{cells}</Table.Row>
			</Table.Footer>
		);
	};

	const getBodyCellContent = (row, cell, cellIndex) => {
		let content = undefined,
			subheader = cell.subheader ? [cell.subheader] : [];

		// Get link type
		let linkType = Validate.linkType(cell.link);

		if (typeof cell.header === 'object') {
			if (cell.header.subheader) {
				subheader.push(
					<Text as='div' className={cell.singleLine || cell.header.singleLine ? 'text-lines-1' : undefined}>
						{cell.header.subheader}
					</Text>
				);
			}
			if (cell.state && cell.state.color && cell.state.text) {
				subheader.push(
					<Label
						textual={true}
						size='tiny'
						text={cell.state.text}
						icon={{
							color: cell.state.color,
							name: 'fa-circle',
							solid: true
						}}
					/>
				);
			}

			content = (
				<Grid
					columns={2}
					compact
					verticalAlign='middle'
					singleRow
					breakWord={cell.breakWord}
					singleLine={cell.singleLine}
				>
					{row.folder ? (
						<Grid.Column collapsing>
							<Icon name='fa-folder' size='big' number={row.folderItems} />
						</Grid.Column>
					) : null}
					{cell.header.image !== undefined ? (
						<Grid.Column collapsing>
							{typeof cell.header.image === 'object' ? (
								<Image
									src={cell.header.image?.src}
									size={props.size !== 'small' ? 'mini' : undefined}
									squared={cell.header.image?.squared}
									avatar={cell.header.image?.avatar ? cell.header.image?.avatar : true}
								/>
							) : (
								<Image
									src={cell.header.image}
									size={props.size !== 'small' ? 'mini' : undefined}
									avatar
								/>
							)}
						</Grid.Column>
					) : null}
					{cell.header.icon !== undefined ? (
						<Grid.Column collapsing>
							{typeof cell.header.icon === 'object' ? (
								<Icon
									name={cell.header.icon?.name}
									size={props.size}
									circular={cell.header.icon?.circular}
									squared={cell.header.icon?.squared}
								/>
							) : (
								<Icon name={cell.header.icon} size={props.size !== 'small' ? 'mini' : undefined} />
							)}
						</Grid.Column>
					) : null}
					{cell.header.icons !== undefined ? (
						<Grid.Column collapsing>
							{typeof cell.header.icons === 'object' ? (
								<Icon.Group icons={cell.header.icons} size={props.size} />
							) : null}
						</Grid.Column>
					) : null}
					<Grid.Column fluid verticalAlign={!subheader ? 'middle' : undefined}>
						<Header
							{...cell.header}
							image={false}
							text={
								linkType !== 'external' ? (
									cell.header.text
								) : (
									<>
										{cell.header.text} <Icon name='fa-arrow-up-right-from-square' size='small' />
									</>
								)
							}
							subheader={subheader.length > 0 ? subheader : undefined}
							size='small'
							singleLine={cell.singleLine || cell.header.singleLine}
							icon={undefined}
						/>
					</Grid.Column>
				</Grid>
			);
		} else if (cell.contextMenu) {
			if (React.isValidElement(cell.contextMenu)) {
				content = cell.contextMenu;
			} else if (typeof cell.contextMenu === 'object') {
				let options = [];

				// If does not have separate menu option expect the object to be menu options
				cell.contextMenu.options = cell.contextMenu.options ? cell.contextMenu.options : cell.contextMenu;

				// Automatically add a active toggle in the context menu for mobile
				if (props.activate && row.activable !== false) {
					if (cell.contextMenu.options[0].header) {
						options.push(cell.contextMenu.options[0]);
						cell.contextMenu.options.shift();
					}
					options.push({
						checkbox: {
							toggle: true,
							label: <FormattedMessage id='component.action-table.active' />,
							name: 'active',
							value: row.id,
							checked: row.active,
							size: row.size,
							onChange: handleActive
						}
					});
					options.push({ divider: true });
				}
				options = options.concat(cell.contextMenu.options);
				content = <ContextMenuDetached options={options} size={props.size} direction='left' />;
			}
		} else {
			if (props.stackable && Array.isArray(props.headerRow) && props.headerRow.length > 0) {
				content = (
					<Form.Field>
						<Text as='label' size='small' maxDevice='mobile'>
							{props.headerRow[cellIndex].content}:
						</Text>
						{cell.content}
					</Form.Field>
				);
			} else {
				content = cell.content;
			}
		}

		if (typeof cell.onClick === 'function') {
			content = (
				<a href={cell.link ? cell.link : '#'} onClick={() => handleClick(event, cell)}>
					{content}
				</a>
			);
		} else if (cell.link) {
			if (linkType === 'external') {
				content = (
					<a href={cell.link} target='_blank' rel='noopener noreferrer'>
						{content}
					</a>
				);
			} else {
				content = <Link to={cell.link}>{content}</Link>;
			}
		}

		return content;
	};

	const handleClick = (event, cell) => {
		event.preventDefault();
		if (typeof cell.onClick === 'function') {
			cell.onClick(event, cell);
		}
	};

	const handleSort = (event, data) => {
		// Trigger onSort callback funtion
		if (typeof props.sort.onSort === 'function') {
			props.sort.onSort(event, {
				...data,
				column: data.direction !== 'descending' ? data.column : undefined,
				direction: !data.direction ? 'asc' : data.direction === 'asc' ? 'desc' : undefined
			});
		}
	};

	const handleActive = (event, data) => {
		if (typeof props.activate.onActive === 'function') {
			props.activate.onActive(event, data);
		}
	};

	const handleSelect = (event, data) => {
		if (data.value === 'all') {
			selectedRows = data.checked
				? bodyRows
						.filter(function (row) {
							return row.selectable !== false ? true : false;
						})
						.map(function (row) {
							return row.id;
						})
				: [];
		} else if (data.value === 'clear') {
			selectedRows = [];
		} else {
			if (data.checked) {
				selectedRows = [...selectedRows, ...[data.value]];
			} else {
				selectedRows.splice(selectedRows.indexOf(data.value), 1);
			}
		}

		// Trigger onSelect callback funtion
		if (typeof props.select.onSelect === 'function') {
			props.select.onSelect(event, { ...data, selected: selectedRows });
		}
	};

	const handleLoadMore = (event) => {
		// Trigger onLoadMore callback funtion
		if (typeof props.paginate.onLoadMore === 'function') {
			props.paginate.onLoadMore(event, {
				currentPage: props.paginate.currentPage + 1
			});
		}
	};

	const getHeaderLabels = (event) => {
		let labels = [],
			deepItems = countItems('deep');

		if (props.extraAmount) {
			deepItems = deepItems + props.extraAmount;
		}

		if (props.limit?.limit) {
			// Use props.translations.totalItemsLabel for limit label if props.limit.tranlations.amount not provided
			if (!props.limit.translations?.amount) {
				props.limit = { ...props.limit, translations: { amount: translations.totalItemsLabel } };
			}
			labels.push({ ...props.limit, amount: deepItems });
		} else {
			labels.push({
				text: <FormattedMessage id={translations.totalItemsLabel} values={{ amount: deepItems }} />
			});
		}

		if (props.actionHeader?.header?.labels) {
			labels = Array.isArray(props.actionHeader?.header?.labels)
				? [...labels, ...props.actionHeader?.header?.labels]
				: [...labels, ...[props.actionHeader?.header?.labels]];
		}

		let clearLabel = {};
		if (props.select) {
			clearLabel = {
				visible: selectedRows.length > 0,
				text: (
					<>
						<FormattedMessage id='component.action-table.clear' /> ({selectedRows.length})
					</>
				),
				circular: true,
				color: 'purple',
				icon: 'fa-remove',
				onClick: (event) => handleSelect(event, { value: 'clear' })
			};
			labels.push(clearLabel);
		}

		return labels;
	};

	const countItems = (mode) => {
		let totalItems = 0;
		totalItems = props.totalRows;

		if (mode === 'deep') {
			let folders = bodyRows.filter((row) => row.folder);
			folders.map((folder) => {
				totalItems = totalItems + folder.folderItems;
			});
			totalItems = totalItems - folders.length;
		}

		return totalItems;
	};

	const countColumns = () => {
		let columns = undefined,
			columnNames = {
				1: 'one',
				2: 'two',
				3: 'three',
				4: 'four',
				5: 'five',
				6: 'six',
				7: 'seven',
				8: 'eight',
				9: 'nine',
				10: 'ten',
				11: 'eleven',
				12: 'twelve',
				13: 'thirteen',
				14: 'fourteen',
				15: 'fifteen',
				16: 'sixteen'
			};
		if (Array.isArray(props.headerRow) && props.headerRow.length > 0) {
			columns = props.headerRow.length;
			if (props.select) {
				columns++;
			}
			if (props.activate) {
				columns++;
			}
		}
		return columnNames[columns];
	};

	const getTableClasses = () => {
		// Assign classes
		const columns = countColumns();
		const classes = classNames('ui table', {
			[`${columns} column`]: columns,
			unstackable: props.stackable === false ? true : false,
			selectable: bodyRows.length > 0 && props.hoverable,
			structured: props.structured,
			sortable: props.sort && props.totalRows > 0,
			[props.size]: props.size
		});
		return classes;
	};

	const getTable = (bodyRows) => {
		let table = (
			<table ref={tableRef} className={getTableClasses()}>
				{renderHeaderRow()}
				{renderBodyRows(bodyRows)}
				{renderFooterRow()}
			</table>
		);
		return table;
	};

	let totalItems = countItems(),
		currentPage = props.paginate ? props.paginate.currentPage : undefined,
		paginateAmount = props.paginate ? props.paginate.paginateAmount : undefined,
		nextMin = currentPage * paginateAmount,
		max = (currentPage + 1) * paginateAmount,
		nextMax = max > totalItems ? totalItems : max,
		showMore = totalItems > nextMin;

	// Assign classes
	const tableWrapperClasses = classNames('actiontable-table-wrapper', {
		'has-header': props.totalRows || props.actionHeader || props.breadcrumb || props.chart || props.extra,
		'has-button': showMore
	});

	// Define ActionTable
	let actionTable = props.emptyState.displayEmptyState ? (
		<Segment raised padded='very'>
			<EmptyState {...emptyState} />
		</Segment>
	) : (
		<>
			<Segment
				basic={!props.segment}
				raised={props.segment}
				removeMargins={!props.segment ? 'all' : undefined}
				removePaddings='all'
				className='actiontable-segment'
			>
				<div className='actiontable-wrapper' id={randomTableID}>
					{props.actionHeader || !isNaN(props.totalRows) ? (
						<ActionHeader
							{...props.actionHeader}
							sticky={!document.querySelector('html').classList.contains('mobile')}
							bottomBorder
							header={{
								...props.actionHeader?.header,
								labels: getHeaderLabels()
							}}
							size={props.size}
							scrollTo={`#${randomTableID}`}
							onSizeChange={(element) => setStickyHeight(element.offsetHeight)}
						/>
					) : null}
					{props.breadcrumb ? (
						<>
							<Breadcrumb icon='fa-folder' size='small' {...props.breadcrumb} />
							<Divider removeMargins='all' />
						</>
					) : null}
					{React.isValidElement(props.chart) ? (
						<>
							<Segment basic compressed removeMargins='all'>
								{props.chart}
							</Segment>
							<Divider removeMargins='all' />
						</>
					) : props.chart?.chart ? (
						<>
							<Segment basic compressed removeMargins='all'>
								{props.chart.chartInfo ? (
									<Grid stackable stretched>
										<Grid.Column width={9}>
											<Chart isAnimated isBoxed {...props.chart.chart} />
										</Grid.Column>
										<Grid.Column width={3}>
											<ChartInfo {...props.chart.chartInfo} />
										</Grid.Column>
									</Grid>
								) : (
									<Chart isAnimated isBoxed {...props.chart.chart} />
								)}
							</Segment>
							<Divider removeMargins='all' />
						</>
					) : null}
					{props.extra ? (
						<>
							<Segment basic compressed removeMargins='all'>
								{props.extra}
							</Segment>
							<Divider removeMargins='all' />
						</>
					) : null}
					<Segment
						basic
						removePaddings='all'
						removeMargins='all'
						className={tableWrapperClasses}
						loading={props.loading ? 'scrolling' : undefined}
					>
						<div
							className={`actiontable-table-scroll-wrapper${horizontalScrollActive ? ' scrollable' : ''}`}
							ref={scrollWrapperRef}
						>
							{getTable(bodyRows)}
						</div>
					</Segment>
					{props.headerRow && bodyRows.length > 0 ? (
						<div className={classNames('actiontable-table-clone-wrapper', tableWrapperClasses)}>
							<table className={getTableClasses()} ref={tableCloneRef} />
						</div>
					) : null}
					{showMore ? (
						<Segment
							basic
							compressed
							textAlign='center'
							removeMargins='all'
							className='actiontable-pagination-wrapper'
						>
							<Button
								text={
									<FormattedMessage
										id='component.action-table.showMore'
										values={{ min: nextMin, max: nextMax, total: totalItems }}
									/>
								}
								circular
								icon={{ name: 'fa-chevron-down', solid: true }}
								loading={props.paginate && props.paginate.loadingMore}
								onClick={handleLoadMore}
							/>
						</Segment>
					) : null}
				</div>
			</Segment>
		</>
	);

	return actionTable;
});

// Documentation generation support
Component.displayName = 'ActionTable';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
