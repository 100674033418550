/**
 * @prettier
 * @flow
 */
import { useState, useEffect } from 'react';
const MeContext = React.createContext(); // DEFAULT

type Props = {
	children: React.Node,
	value: any
};

const DEFAULTS = {
	language: 'en',
	country: 'FI',
	organizations: []
};

const MeContextProvider = (props: Props) => {
	let [state, setState] = useState(props.value || DEFAULTS);
	useEffect(() => {
		if (props.value) {
			setState(props.value);
		}
	}, [props.value]);
	return <MeContext.Provider value={{ ...state, setState }}>{props.children}</MeContext.Provider>;
};

const MeContextConsumer = MeContext.Consumer;

export { MeContextProvider, MeContextConsumer };

export default MeContext;
