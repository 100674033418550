/**
 * @prettier
 * @flow
 */

import { useState, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'liana-ui/components/';
import FeedbackForm from './src/FeedbackForm';

// prettier-ignore
type Props = {
	open?: boolean,
	mountNode?: HTMLElement,
	onSubmit?: (values: any, done: () => mixed) => void,
	onClose?: () => void,
	onComplete?: () => void // Passed as second param for onSubmit
};

const DEFAULTS = {
	open: false
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/modal/ */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	let form = useRef();
	let [modified, setModified] = useState(false);
	let [loading, setLoading] = useState(false);

	const reset = () => {
		setLoading(false);
		setModified(false);
	};

	// Turn off loading if the modal is closed prematurely
	useEffect(() => {
		if (props.open === false && (loading === true || modified === true)) {
			reset();
		}
	}, [props.open]);

	const handleChange = () => setModified(true);
	const handleSubmit = () => {
		if (form.current.validate()) {
			setLoading(true);
			if (typeof props.onSubmit === 'function') {
				props.onSubmit(form.current.values(), handleComplete);
			}
		}
	};
	const handleClose = () => {
		reset();
		if (typeof props.onClose === 'function') {
			props.onClose();
		}
	};
	const handleComplete = () => {
		reset();
		if (typeof props.onComplete === 'function') {
			props.onComplete();
		}
	};

	return (
		<Modal
			open={props.open}
			mountNode={props.mountNode}
			type='form'
			size='tiny'
			header={<FormattedMessage id='component.support-menu.sendFeedback.title' />}
			onClose={handleClose}
			content={
				<FeedbackForm
					ref={form}
					modified={modified}
					loading={loading}
					onChange={handleChange}
					onSubmit={handleSubmit}
					onClose={handleClose}
				/>
			}
		/>
	);
});

// Documentation generation support
Component.displayName = 'FeedbackModal';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
