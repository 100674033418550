/**
 * @prettier
 * @flow
 */

import { TransitionGroup } from 'semantic-ui-react';

// prettier-ignore
type Props = {
	/** Primary content. */
	children: React.Node,
	/** An element type to render as (string or function). */
	as: string | (() => void) | React.Fragment,
	/** Named animation event to used. Must be defined in CSS. Default: 'fade' */
	animation?: 'fade' | 'fade up' | 'fade down' | 'fly right' | 'scale' | 'slide up' | 'slide down' | 'pulse' | 'glow',
	/** Duration of the CSS transition animation in milliseconds. Default: 500 */
	duration?: number
};

const DEFAULTS = {
	animation: 'fade',
	duration: 500
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/transition/#types-group */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	const output = (
		<TransitionGroup as={props.as} animation={props.animation} duration={props.duration}>
			{props.children}
		</TransitionGroup>
	);
	return output;
});

// Documentation generation support
Component.displayName = 'Transition.Group';
Component.defaultProps = DEFAULTS;

export default Component;
