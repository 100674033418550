/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { Dimmer } from 'semantic-ui-react';

// prettier-ignore
type Props = {
	/** Content as children. */
	children: React.Node,
	/** A loader can be active or visible. */
	active?: boolean,
	/** A dimmer can be formatted to be fixed to the page. */
	page?: boolean,
	/** Dimmers can have their colors inverted. */
	inverted?: boolean
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/dimmer/ */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	return !props.page ? (
		<Dimmer active={props.active} page={props.page} inverted={props.inverted}>
			{props.children}
		</Dimmer>
	) : (
		// Dimmer 'page' breaks LianaUI Modals for some reason. Probably because Modals are legacy and not compatible with new SUI-R compoments.
		// When Modals are refactored to use SUI-R try to use Dimmer component only here
		<div
			className={classNames('ui dimmer', {
				active: props.active,
				page: props.page
			})}
		>
			<div className='content'>{props.children}</div>
		</div>
	);
});

// Documentation generation support
Component.displayName = 'Dimmer';

export type { Props };
export default Component;
