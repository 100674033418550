/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { useState } from 'react';
import { Image } from 'semantic-ui-react';
import { Icon, Placeholder, Popup, Responsive } from 'liana-ui/components';
import type { IntlComponent } from 'react-intl';
import type { Props as PopupProps } from 'liana-ui/components/popup/Popup';

// prettier-ignore
type Props = {
	/** An image must have an relative or absolute url. */
	src: string,
	/**
		An image must have an alternative text.
		PROPS[IntlComponent=/localization/]
	*/
	alt: string | IntlComponent,
	/**
		An image can be formatted to appear as an circular user avatar. Use string to provide an fallback icon for non user avatars.
		VALUES[false | true | 'building' | 'globe' | ...]
	*/
	avatar?: boolean | string,
	/** An image can appear circular. */
	circular?: boolean,
	/** An image can appear squared. */
	squared?: boolean,
	/** An image can appear inline. */
	inline?: boolean,
	/** An image can be formatted to appear on dark backgrounds. */
	inverted?: boolean,
	/** An image can take up the width of its container. */
	fluid?: boolean,
	/** An image can be fitted without space to left or right of image. */
	fitted?: boolean,
	/** An image can be lazyloaded with a skeleton element. 'empty' will not show skeleton but empty space. */
	lazyLoad?: boolean | 'empty',
	/** Max height of responsive images */
	maxHeight?: string,
	/** Width/Height ratio for fluid responsive image placeholder loaders. */
	ratio?: string,
	/** An image can appear centered in a content block. */
	centered?: boolean,
	/** An image can sit to the left or right of other content. */
	floated?: 'left' | 'right',
	/** An image can be vertivcally aligned. */
	verticalAlign?: 'bottom' | 'middle' | 'top',
	/** An image can have different sizes. */
	size?: 'favicon' | 'mini' | 'tiny' | 'small' | 'medium' | 'large',
	/**
		Popup text or, react-intl coomponent or object of properties for Popup component.
		PROPS[IntlComponent=/language/localisation/, PopupProps=/components/modals/popup/]
	*/
	/** Smallest device that component will be displayed with. */
	minDevice?: 'mobile' | 'tablet' | 'computer' | 'largescreen' | 'widescreen',
	/** Largest device that component will be displayed with. */
	maxDevice?: 'mobile' | 'tablet' | 'computer' | 'largescreen' | 'widescreen',
	/**
		Popup text or, react-intl coomponent or object of properties for Popup component.
		PROPS[IntlComponent=/language/localisation/, PopupProps=/components/modals/popup/]
	*/
	popup?: string | IntlComponent | PopupProps,
	/** Function called on click. */
	onClick?: () => void,
	/** Function called when image has finished loading. */
	onLoad?: () => void
};

const DEFAULTS = {
	avatar: false,
	circular: false,
	squared: false,
	inline: false,
	inverted: false,
	fluid: false,
	lazyLoad: true,
	centered: false,
	ratio: '1000/1000'
};

// Component render conditions. True if should not render.
const EQUALS = (prevProps, nextProps) =>
	prevProps.src === nextProps.src && prevProps.alt === nextProps.alt && prevProps.inverted === nextProps.inverted;

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/image */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	// Variables and refs
	let [isVisible, setVisible] = useState(props.lazyLoad ? false : true);
	let [isLoading, setLoading] = useState(props.lazyLoad ? true : false);
	let [hasError, setError] = useState(false);

	const handleClick = () => {
		if (typeof props.onClick === 'function') {
			props.onClick();
		}
	};

	// Function to generate LianaUI Image
	const createImage = (props: Props, classes, styles) => {
		let image = (
			<Image
				src={props.src}
				alt={props.alt ? props.alt : props.avatar ? 'Avatar' : 'Image'}
				avatar={props.avatar}
				rounded={!props.squared}
				circular={props.circular || (props.avatar && !props.squared)}
				inline={props.inline}
				fluid={props.fluid}
				centered={props.centered}
				floated={props.floated}
				verticalAlign={props.verticalAlign}
				size={props.size}
				className={classes}
				style={styles}
				onClick={handleClick}
				onError={() => (props.lazyLoad === true ? setError(true) : {})}
				onLoad={() => {
					if (props.lazyLoad) {
						if (typeof props.onLoad === 'function') {
							props.onLoad();
						}
						setLoading(false);
					}
				}}
			/>
		);

		// Attach popup
		return Popup.attach(props.popup, image);
	};

	// Function to generate LianaUI Button
	const createComponent = (props: Props) => {
		let placeholderClasses = classNames({
			image: !props.fluid,
			avatar: props.avatar,
			empty: props.lazyLoad === 'empty',
			circular: props.circular || (props.avatar && !props.squared),
			rounded: !props.squared,
			floated: props.floated,
			right: props.floated === 'right',
			left: props.floated === 'left',
			'remove-margins': props.fitted,
			[props.size]: props.size
		});

		let iconClasses = classNames({
			'cursor-pointer': props.onClick
		});

		let classes = classNames({
			hidden: isLoading && props.lazyLoad,
			'remove-margins': props.fitted,
			'cursor-pointer': props.onClick
		});

		let styles = {
			maxHeight: props.maxHeight ? props.maxHeight : undefined,
			...props.style
		};

		let component =
			props.avatar && !props.src ? (
				<Icon
					avatar
					className={iconClasses}
					name={props.avatar === true ? 'fa-user' : props.avatar}
					circular={props.circular || (props.avatar && !props.squared)}
					squared={props.squared}
					inverted={props.inverted}
					fitted={props.fitted}
					size={props.size === 'medium' ? 'medium-size' : props.size}
					popup={props.popup}
					onClick={handleClick}
				/>
			) : (
				<>
					{isLoading && props.lazyLoad && props.src ? (
						<Placeholder
							className={placeholderClasses}
							inverted={props.inverted}
							error={hasError}
							onTopVisible={() => setVisible(true)}
						>
							<Placeholder.Image
								fluid={props.fluid}
								ratio={props.ratio}
								maxHeight={props.maxHeight}
								size={props.size}
								avatar={props.avatar}
							/>
						</Placeholder>
					) : null}
					{isVisible && (!hasError || !props.lazyLoad) ? createImage(props, classes, styles) : null}
				</>
			);

		return component;
	};

	// Display reponsively
	let component =
		props.minDevice || props.maxDevice || props.hideTouch ? (
			<Responsive {...props}>{createComponent(props)}</Responsive>
		) : (
			createComponent(props)
		);

	return component;
}, EQUALS);

// Documentation generation support
Component.displayName = 'Image';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
