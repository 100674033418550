/** instanbul ignore file
 * @prettier
 * @flow
 */

// Error Boundary for webpack chunk load errors with @loadable/component
// Note: Error Boundaries can only be made as Class components
// https://reactjs.org/docs/error-boundaries.html#introducing-error-boundaries
// https://raphael-leger.medium.com/react-webpack-chunkloaderror-loading-chunk-x-failed-ac385bd110e0
// https://loadable-components.com/docs/error-boundaries

import { FormattedMessage } from 'react-intl';
import { localStorage } from 'liana-ui/definitions';
import { Transition } from 'liana-ui/components';

// prettier-ignore
type Props = {
	/** Main content */
	children: React.Node,
	/** Content if chunks fail to load */
	fallbackContent: React.Node
};

type State = {
	hasError: boolean,
	hasRefresh: 1 | 0
};

const REFRESH = 5000; // Milliseconds

// Note: Only way to set hasRefresh is through localStorage
class Component extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		let hasRefresh = JSON.parse(localStorage.getExpiringItem('chunk-load-refresh'));
		this.state = {
			hasError: false,
			hasRefresh: hasRefresh && hasRefresh.hasOwnProperty('value') ? hasRefresh.value : 0
		};

		if (!this.state.hasRefresh) {
			localStorage.setExpiringItem('chunk-load-refresh', 0, 0); // Does not expire
		}
	}

	// Update state so the next render will show the fallback UI: { error, info }
	static getDerivedStateFromError() {
		return { hasError: true };
	}

	// Error { message, name, type, request }
	// Note: componentDidCatch possibly deprecated in the future
	// https://reactjs.org/docs/react-component.html#componentdidcatch
	componentDidCatch(error: any) {
		if (error.name === 'ChunkLoadError' && !this.state.hasRefresh) {
			localStorage.setExpiringItem('chunk-load-refresh', 1, REFRESH); // Expires in 5 seconds
			window.location.reload();
		}
	}

	render() {
		return this.state.hasError ? (
			<div
				style={{
					height: '100%',
					background: '#f6f7f9'
				}}
			>
				<table
					height='100%'
					align='center'
					style={{
						maxWidth: '400px'
					}}
				>
					<tr>
						<td align='center' valign='middle'>
							<Transition transitionOnMount visible duration={500} delay={500}>
								<div id='message' className='ui raised segment fade visible transition'>
									<div className='ui red small message vertical icon'>
										<i aria-hidden='true' className='red fa-triangle-exclamation icon'></i>

										<div className='header ui large'>
											<FormattedMessage id='component.app-chunk-error.header' />
										</div>
										<p>
											<FormattedMessage id='component.app-chunk-error.content' />{' '}
											<FormattedMessage id='component.app-chunk-error.contact' />{' '}
											<a href='mailto:support@lianatech.com'>support@lianatech.com</a>
										</p>
									</div>
									<button
										className='ui button primary circular fluid'
										onClick={() => location.reload()}
									>
										<i className='icon fa-refresh'></i>
										<FormattedMessage id='component.app-chunk-error.tryAgain' />
									</button>
								</div>
							</Transition>
						</td>
					</tr>
				</table>
			</div>
		) : (
			this.props.children
		);
	}
}

export default Component;
