/**
 * @prettier
 * @flow
 */

import { List } from 'semantic-ui-react';
import ListItem from './src/ListItem';
import type { IntlComponent } from 'react-intl';
import type { Props as IconProps } from 'liana-ui/components/icon/Icon';
import type { Props as ImageProps } from 'liana-ui/components/image/Image';
import type { Props as PopupProps } from 'liana-ui/components/popup/Popup';

// prettier-ignore
type Props = {
	/**
		A list must have items as array of strings, rect-intl components or props object for items.
		PROPS[IconProps=/components/labels/icons/icon/, ImageProps=/components/texts/image/, PopupProps=/components/modals/popup/, IntlComponent=/language/localisation/]
	*/
	items: Array<string | IntlComponent | {
			header: string | IntlComponent,
			link: string,
			icon: string | IconProps,
			image: string | ImageProps,
			content: string | React.Node,
			floatedContent: string | React.Node,
			items: Array<{
				header: string | IntlComponent,
				content: string | React.Node,
				...
			}>,
			popup: string | IntlComponent | PopupProps,
			onClick: (
				event: SyntheticEvent<>,
				data: any
			) => void
		}
	>,
	/* A list can have content as children as alternative to items property. */
	children?: React.Node,
	/** A list can mark items with a bullet. */
	bulleted?: boolean,
	/** A list can be ordered numerically. */
	ordered?: boolean,
	/** A list can show divisions between content. */
	divided?: boolean,
	/** A list can divide its items into cells. */
	celled?: boolean,
	/** A list can relax its padding to provide more negative space. */
	relaxed?: boolean | 'very',
	/** A list can be formatted to have items appear horizontally. */
	horizontal?: boolean,
	/** A list can be floated left or right. */
	floated?: 'left' | 'right',
	/** A list can vary in size. */
	size?: 'mini' | 'small'
};

const DEFAULTS = {
	bulleted: false,
	ordered: false,
	divided: false,
	celled: false,
	relaxed: false,
	horizontal: false
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/list/ */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	const getItems = () => {
		return props.items && Array.isArray(props.items)
			? props.items.map((item, index) =>
					typeof item === 'string' || React.isValidElement(item) ? (
						<ListItem key={index} content={item} />
					) : (
						<ListItem key={index} {...item} />
					)
			  )
			: null;
	};

	let list =
		props.items || props.children ? (
			<List
				bulleted={props.bulleted}
				ordered={props.ordered}
				divided={props.divided}
				celled={props.celled && !props.divided}
				relaxed={props.relaxed}
				horizontal={props.horizontal}
				floated={props.floated}
				size={props.size}
			>
				{props.items ? getItems() : props.children}
			</List>
		) : null;

	return list;
});

// Documentation generation support
Component.displayName = 'List';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
