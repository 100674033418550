/**
 * @prettier
 * @flow
 */

import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb } from 'semantic-ui-react';

// prettier-ignore
type Props = {
	/** Section text */
	text: string | FormattedMessage,
	/** Render as an `a` tag instead of a `div` and triggers React router. */
	link?: string,
	/** Style as the currently active section. */
	active: boolean
};

const DEFAULTS = {
	text: '',
	active: false
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/collections/breadcrumb/ */
let Component: React.AbstractComponent = React.memo<Props>((props: Props) => {
	const navigate = useNavigate();
	let text = null;

	if (React.isValidElement(props.text) && props.text.props?.id) {
		text = <FormattedMessage id={props.text.props.id} values={props.text.props.values} />;
	} else if (typeof props.text === 'string' && props.text.length > 0) {
		text = props.text;
	}

	let handleClick = props.link
		? (event) => {
				event.preventDefault();
				navigate(props.link);
		  }
		: null;

	let section = (
		<Breadcrumb.Section
			href={props.link ? props.link : undefined}
			className='overflow-ellipsis'
			onClick={handleClick}
		>
			<span className='content'>{text}</span>
		</Breadcrumb.Section>
	);
	return section;
});

// Documentation generation support
Component.displayName = 'BreadcrumbSection';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
