/**
 * @prettier
 * @flow
 */

import { useCallback } from 'react';
import { Step as SUIRStep } from 'semantic-ui-react';
import Step from './src/Step';
import type { IntlComponent } from 'react-intl';

// prettier-ignore
type Props = {
	/**
		A steps must have series of activities.
		PROPS[IntlComponent=/localization/]
	*/
	steps: Array<{
		id: string,
		title: string | IntlComponent,
		description: string | IntlComponent,
		active: boolean,
		completed?: boolean,
		onClick?: (
			event: SyntheticEvent<>,
			data: {
				id: string,
				active: boolean,
				completed: boolean
			}
		) => void
	}>
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/step/#groups-ordered */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	return (
		<SUIRStep.Group size='small' ordered fluid widths={props.steps ? props.steps.length : null}>
			{props.steps ? props.steps.map((step, index) => <Step key={`step-${index}`} {...step} />) : null}
		</SUIRStep.Group>
	);
});

Component.displayName = 'Steps';

export default Component;
