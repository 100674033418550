/**
 * @prettier
 * @flow
 */

import { useState, Fragment } from 'react';
import { NavLink } from 'liana-ui/components';
import { AccordionAccordion } from 'semantic-ui-react';
import type { AccordionPanelType } from 'semantic-ui-react';
import AccordionTitle from './AccordionTitle';
import AccordionContent from './AccordionContent';

// prettier-ignore
type Props = {
	/** Index of the currently active panel. When exclusive is false, activeIndex should be an array of Strings. */
	activeIndex?: string | Array<string>,
	/** Index of the currently active panel. When exclusive is false, defaultActiveIndex should be an array of Strings. */
	defaultActiveIndex?: string | Array<string>,
	/** Array of props for AccordionPanel. Example: [{key: 'panel1', title: 'Panel title', content: 'Panel content', panels: Array<AccordionPanelType> }] */
	panels: Array<AccordionPanelType>,
	/** Only allow one panel open at a time. */
	exclusive?: boolean,
	/** Parent index.  */
	parentIndex?: number,
	/** Nested level.  */
	level?: number,
	/** Set maximum levels of accordion. */
	maxLevels: number,
	/** Accordion title can vary in size. */
	size?: 'large',
	/** Called on link click only. */
	onLinkClick?: (
		event: SyntheticEvent<>
	) => void,
	// Do not print out to docs. For testing purposes only
	onAnimationEnd?: (
		newHeight: number
	) => void
};

const DEFAULTS = {
	exclusive: false,
	level: 1
};

const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	const [activeIndexes, setActiveIndexes] = useState(() => {
		let defaultActiveIndexesState = [];
		if (props.exclusive) {
			defaultActiveIndexesState[0] = props.activeIndex
				? props.activeIndex
				: props.defaultActiveIndex
				? props.defaultActiveIndex
				: null;
		} else {
			defaultActiveIndexesState = props.activeIndex
				? props.activeIndex
				: props.defaultActiveIndex
				? props.defaultActiveIndex
				: [];
		}
		return defaultActiveIndexesState;
	});

	const handleClick = (event, data) => {
		if (props.exclusive) {
			setActiveIndexes(data.active ? [] : [data.index]);
		} else {
			setActiveIndexes(
				data.active
					? (activeIndexes) => activeIndexes.filter((activeIndex) => activeIndex !== data.index)
					: [...activeIndexes, data.index]
			);
		}
	};

	const getClasses = (panel: object) => {
		let classes = '';
		if (props.maxLevels && props.level + 1 === props.maxLevels) {
			classes = 'hide-icon';
		} else if (
			((!panel.panels || panel.panels.length < 1) && !panel.content) ||
			props.level + 1 === props.maxLevels
		) {
			classes = 'hide-icon';
		}
		return classes;
	};

	let accordionAccordion = props.panels ? (
		<AccordionAccordion exclusive={props.exclusive} className={props.size}>
			{props.panels.map((panel, index) => {
				index = (props.parentIndex + '.' + (index + 1)).toString();
				return (
					<Fragment key={index}>
						<AccordionTitle
							index={index}
							as={panel.link ? NavLink : undefined}
							to={panel.link ? panel.link : undefined}
							disableLink={panel.panels && panel.panels.length > 0 && props.level + 1 < props.maxLevels}
							active={activeIndexes.includes(index)}
							className={getClasses(panel)}
							labels={panel.labels}
							upsell={panel.upsell}
							onClick={handleClick}
							onLinkClick={props.onLinkClick}
						>
							{panel.title}
						</AccordionTitle>
						<AccordionContent
							parentIndex={index}
							defaultActiveIndex={activeIndexes}
							active={activeIndexes.includes(index)}
							panels={panel.panels}
							level={props.level + 1}
							maxLevels={props.maxLevels}
							onLinkClick={props.onLinkClick}
							onAnimationEnd={props.onAnimationEnd}
						>
							{panel.content}
						</AccordionContent>
					</Fragment>
				);
			})}
		</AccordionAccordion>
	) : null;

	return accordionAccordion;
});

Component.defaultProps = DEFAULTS;
Component.displayName = 'Accordion.Accordion';

export default Component;
