/**
 * @prettier
 * @flow
 */

import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Responsive } from 'liana-ui/components/';
import FilterSidebar from './src/FilterSidebar';
import FilterModal from './src/FilterModal';

import isNumber from 'lodash/isNumber';

// prettier-ignore
type Props = {
	/** A filter can be a side panel or a modal. */
	type: 'panel' | 'modal',
	/**
		A button can have text.
		PROPS[IntlComponent=/language/localisation/]
	*/
	text?: string | IntlComponent,
	/** A filter must have content. */
	filters: React.Node,
	/** A filter must know if the form is modified. */
	isFormModified: boolean,
	/** A filter must know the amount of modified filters. */
	modifiedFilters: number,
	/** A filter panel can be visible. */
	visible?: boolean,
	/** A filter button can have no empty space around it. */
	fitted?: boolean,
	/**  A filter button can be aligned to the left or right of its container. */
	floated?: 'left' | 'right',
	/** A filter button can have different sizes. */
	size?: 'mini' | 'tiny' | 'small',
	/** A filter panel or modal can have different widths. */
	width?: 'thin' | 'wide' | 'very wide' | 'fullscreen',
	/** Function called when 'Clear all filters' is clicked. */
	onClear?: (
		event: SyntheticEvent<>
	) => void,
	/** Function called when 'Apply filters' is clicked. */
	onSubmit?: (
		event: SyntheticEvent<>
	) => void,
	/** Function called when modified filters number is changed */
	onModifiedFiltersChange?: (
		helpers: {
			activeFiltersNumber: number,
			setActiveFiltersNumber: (activeFiltersNumber: number) => void,
			filtersOpen: boolean,
			setFiltersOpen: (filtersOpen: boolean) => void
		}
	) => void,
};

const DEFAULTS = {
	type: 'panel',
	text: <FormattedMessage id='component.filter-sidebar.filters' />,
	activeFilters: 0,
	visible: false,
	fitted: false,
	isFormModified: false
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/sidebar/ */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	let [filtersOpen, setFiltersOpen] = useState(props.visible);
	const [activeFiltersNumber, setActiveFiltersNumber] = useState(props.modifiedFilters);

	const openFilters = () => setFiltersOpen(true);
	const closeFilters = () => setFiltersOpen(false);
	const handleSubmit = () => {
		if (typeof props.onSubmit === 'function') {
			props.onSubmit();
			if (props.modifiedFilters !== activeFiltersNumber) {
				setActiveFiltersNumber(props.modifiedFilters);
			}
		}
	};

	useEffect(() => {
		if (typeof props.onModifiedFiltersChange === 'function') {
			props.onModifiedFiltersChange({
				activeFiltersNumber,
				setActiveFiltersNumber,
				filtersOpen,
				setFiltersOpen
			});
		}
	}, [props.modifiedFilters]);

	const handleClear = () => {
		if (typeof props.onClear === 'function') {
			setActiveFiltersNumber(0);
			props.onClear();
		}
	};

	let clearLabel = {
		visible: !!props.modifiedFilters,
		text: (
			<>
				<FormattedMessage id='component.action-table.clear' /> ({props.modifiedFilters || activeFiltersNumber})
			</>
		),
		color: 'purple',
		circular: true,
		icon: 'fa-remove',
		onClick: handleClear
	};
	// Make sure that the form is modified or there are active filters
	const isFormModified = props.isFormModified && (props.modifiedFilters || activeFiltersNumber);

	return (
		<>
			<Responsive minDevice='tablet'>
				<Button
					icon='fa-sliders'
					text={props.text}
					focused={filtersOpen}
					notification={activeFiltersNumber}
					fitted={props.fitted}
					floated={props.floated}
					size={props.size}
					onClick={openFilters}
				/>
			</Responsive>

			<Responsive maxDevice='mobile'>
				<Button
					icon='fa-sliders'
					notification={activeFiltersNumber}
					fitted={props.fitted}
					floated={props.floated}
					size={props.size}
					onClick={openFilters}
				/>
			</Responsive>

			{props.type === 'panel' ? (
				<FilterSidebar
					width={props.width}
					visible={filtersOpen}
					modifiedFilters={props.modifiedFilters}
					clearLabelProps={clearLabel}
					isFormModified={isFormModified}
					onClear={handleClear}
					onHide={closeFilters}
					onSubmit={handleSubmit}
				>
					{props.filters}
				</FilterSidebar>
			) : (
				<FilterModal
					open={filtersOpen}
					size={props.width}
					modifiedFilters={props.modifiedFilters}
					clearLabelProps={clearLabel}
					header={props.text}
					content={props.filters}
					actions={[
						<Button
							key='cancel'
							text={<FormattedMessage id='component.modal.cancel' />}
							type='cancel'
							onClick={closeFilters}
						/>,
						<Button
							key='clear'
							color='primary'
							text={
								<FormattedMessage
									id={`component.filter-sidebar.${
										isNumber(props?.modifiedFilters) ? 'applyFilters' : 'close'
									}`}
									values={{ amount: props.modifiedFilters }}
								/>
							}
							type='submit'
							off={!isFormModified}
							onClick={() => {
								handleSubmit();
								setFiltersOpen(false);
							}}
						/>
					]}
					onClear={handleClear}
					onClose={closeFilters}
				/>
			)}
		</>
	);
});

// Documentation generation support
Component.displayName = 'Filter';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
