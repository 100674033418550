/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import EmptyStateGroup from './EmptyStateGroup';
import { Segment, Image, Header, Button } from 'liana-ui/components/';
import type { IntlComponent } from 'react-intl';
import type { Props as ButtonProps } from 'liana-ui/components/button/Button';

// prettier-ignore
type Props = {
	/**
		An empty state must have an image.
		DATA[https://www.figma.com/file/VnqiJJznuDBiqEen7wgqMX/Illustrations?type=design&node-id=1890-6959&t=HRjyAMWxUduyrTwy-0]
	 */
	image: string,
	/**
		An empty state must have a header.
		PROPS[IntlComponent=/localization/]
	*/
	header: string | IntlComponent,
	/** An empty state must have content. */
	content: React.Node,
	/** 
	  	An empty state can have buttons.
		PROPS[ButtonProps=/components/buttons/button/]
	*/
	buttons?: ButtonProps | Array<ButtonProps>,
	/** An empty state can be in a segment. */
	segment?: boolean,
	/** An segment empty state can be highlighted. */
	highlighted?: boolean
};

const DEFAULTS = {
	segment: false,
	highlighted: false
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/collections/message/ */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	// Assign classes
	const classes = classNames('empty-state text-center', {
		highlighted: props.highlighted
	});

	let message = (
		<Segment
			streched
			basic={!props.segment && !props.highlighted}
			raised={props.segment || props.highlighted}
			removePaddings={!props.segment && !props.highlighted ? 'all' : undefined}
			size={props.highlighted ? 'large' : undefined}
			className={classes}
		>
			<div className='empty-state-container'>
				<div className='empty-state-content'>
					{props.image ? <Image src={props.image} size='small' circular /> : null}
					{props.header ? <Header as='h2' text={props.header} /> : null}
					{props.content ? <p>{props.content}</p> : null}
				</div>
			</div>
			{props.buttons ? (
				<div className='empty-state-buttons'>
					{Array.isArray(props.buttons) ? (
						props.buttons.map((button, index) => (
							<Button key={index} fitted={props.buttons.length === 1} {...button} />
						))
					) : props.buttons ? (
						<Button fitted={true} {...props.buttons} />
					) : null}
				</div>
			) : null}
		</Segment>
	);

	return message;
});

// Documentation generation support
Component.displayName = 'EmptyState';
Component.defaultProps = DEFAULTS;

// Attach Subcomponents
Component.Group = EmptyStateGroup;

export type { Props };
export default Component;
