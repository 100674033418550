/**
 * @prettier
 * @flow
 */

import { isEqual } from 'lodash';
import { merge } from 'lodash';
import { injectIntl } from 'react-intl';
import Highcharts from 'highcharts';
import ChartSettings from './ChartSettings';
import Segment from 'liana-ui/legacy/components/segment/Segment';
import 'fomantic-ui/definitions/behaviors/visibility';

require('highcharts/modules/no-data-to-display')(Highcharts);
require('highcharts/modules/exporting')(Highcharts);

type Export = {
	url: string,
	title: string,
	filename: string
};

// prettier-ignore
type Props = {
	intl: Intl,
	/**
		A chart must have data.
		DATA[json/chart/1-series/7-days.json]
	*/
	data: {
		series: Array<{
			type: string,
			name: string,
			unit: string,
			yAxis: number,
			data: Array<{
				id: number,
				series_id: number,
				y: number
			}>
		}>,
		xAxis: {
			plotLines: Array<{
				color: string,
				value: string,
				label: {
					text: string
				}
			}>,
			categories: Array<string>
		},
		yAxis: Array<{
			title: {
				text: string
			}
		}>
	},
	/** A chart can be different type. */
	type: 'line' | 'area' | 'sparkline' | 'column' | 'bar' | 'pie',
	/** A chart can have a title. */
	title?: string,
	/**
		A chart can have different predefined themes or a custom theme.
		VALUES['liana' | 'rating' | {colors: ['#FF0000', '#FFF000', ...]}]
	*/
	theme?: string | { colors: Array<string> },
	/** A chart can have a secondary segment around it. */
	isBoxed?: boolean,
	/** A chart can animate when it becomens visible on screen. */
	isAnimated?: boolean,
	/** A chart can display a legend for series. Defaut true for pie, donut, halfdonut and false for other chart types. */
	displayLegend?: boolean,
	/** A chart can have compact numbers (100K etc). Receives settings object, for example props.intl.formatNumber(val, props.compactNumbers) */
	compactNumbers?: object,
	/** A chart can have fixed height. */
	height?: number,
	/** A chart can have fixed width. */
	width?: number,
	/** A chart can show a text if the chart can not be drawn with current data. */
	noDataText?: string,
	/** A chart marker can have a custom tooltip factory function. */
	tooltip?: () => mixed,
	/** A chart can have a timezone. Default is this.context.user.get('timezone') or this.context.user.timezone.name */
	timezone?: string,
	/** A chart can have a timezone offset. Default is this.context.user.get('timezone_offset') or this.context.user.timezone.offset */
	timezoneOffset?: string | number,
	/** 
	 	A chart can override automatic start date/time. It is used for correct start date/time labeling. 
	 	VALUES['YYYY-MM-DD HH:MM:SS+HH' | 'YYYY-MM-DDTHH:MM:SS+HH:MM']
	*/
	startDate?: string,
	/** 
	 	A chart can override automatic end date/time. It is used for correct end date/time labeling. 
		VALUES['YYYY-MM-DD HH:MM:SS+HH' | 'YYYY-MM-DDTHH:MM:SS+HH:MM']	
	*/
	endDate?: string,
	/** A chart can override automatic label formatting and force specific labels. */
	range?: 'hours' | 'days' | 'weeks' | 'months' | 'years',
	/** A chart override automatic incomplete ranges (dotted lined, opaque bars etc.) and show all ranges as complete */
	showAllIntervalsComplete?: boolean,
	/** A chart can have optional chart tools */
	tools?: React.Node,
	/** A chart can hava a different output format. */
	output?: 'json' | 'image',
	/** Export options for chart.  {url: <HNES export server url>, title: <Chart title for exported image>, filename: <filename for exported image>} */
	export?: {
		url: string,
		title: string,
		filename: string
	},
	/** Highcharts settings object that can overrule all props above. DATA[https://api.highcharts.com/highcharts/] */
	settings?: {},
	/** Function called on marker click. */
	onMarkerClick?: (mixed) => mixed,
	/** Function called on marker hover. */
	onMarkerHover?: (mixed) => mixed,
	/** Function called when chart is mounted. */
	onMount?: () => mixed,
	/** Function called when chart has leaded. */
	onLoad?: () => mixed
};

/** COMPONENT BASED ON: https://www.highcharts.com/ */
class Chart extends React.Component<Props> {
	_chart: Highcharts;
	_wrapper: { current: React.ElementRef<'div'> | null };
	_image: { current: React.ElementRef<'img'> | null };
	_settings: ChartSettings;

	thousandsSeparator: string;
	decimalSeparator: string;

	constructor(props: Props) {
		super(props);
		this._chart = null;
		this._wrapper = React.createRef();
		this._image = React.createRef();
	}

	static defaultProps = {
		type: 'line',
		theme: 'liana',
		height: 285,
		isAnimated: false,
		compactNumbers: false,
		isBoxed: false,
		settings: {},
		onMarkerClick: null,
		onMarkerHover: null,
		onMount: null,
		onLoad: null
	};

	static contextTypes = {
		user: () => {}
	};

	async componentDidMount() {
		await this._initHighcharts();
		// $(this._wrapper.current).visibility('is on screen') === true
		// ^ This check makes no sense for _handleAnimation?
		if (
			this.props.isAnimated &&
			!document.querySelector('html').classList.contains('mobile') &&
			!document.querySelector('html').classList.contains('tablet')
		) {
			this._handleAnimation();
		} else {
			this._initComponent();
		}
		if (typeof this.props.onMount === 'function') {
			this.props.onMount();
		}
	}

	componentDidUpdate() {
		this._initComponent();
	}

	componentWillUnmount() {
		this._destroyChart();
	}

	shouldComponentUpdate(nextProps): boolean {
		if (
			!isEqual(this.props.data, nextProps.data) ||
			!isEqual(this.props.settings, nextProps.settings) ||
			this.props.intl.locale !== nextProps.intl.locale ||
			this.props.type !== nextProps.type ||
			this.props.height !== nextProps.height ||
			this.props.startDate !== nextProps.startDate ||
			this.props.endDate !== nextProps.endDate ||
			this.props.range !== nextProps.range ||
			this.props.showAllIntervalsComplete !== nextProps.showAllIntervalsComplete
		) {
			return true;
		}
		return false;
	}

	//  Massage prop data
	_getProps = () => {
		const props = Object.assign({}, this.props);

		// Uses either this.context.user.get('timezone') (Immutable) or this.context.user.timezone.name (Non immutable) as default
		if (!this.props.timezone && this.context.user) {
			props.timezone =
				typeof this.context.user.get === 'function' && this.context.user.get('timezone')
					? this.context.user.get('timezone')
					: this.context.user.timezone && this.context.user.timezone.name
					? this.context.user.timezone.name
					: undefined;
		} else {
			props.timezone = this.props.timezone;
		}

		// Uses either this.context.user.get('timezone_offset') (Immutable) or this.context.user.timezone.offset (Non immutable) as default
		if (!this.props.timezoneOffset && this.context.user) {
			props.timezoneOffset =
				typeof this.context.user.get === 'function' && this.context.user.get('timezone_offset')
					? this.context.user.get('timezone_offset')
					: this.context.user.timezone && this.context.user.timezone.offset
					? this.context.user.timezone.offset
					: undefined;
		} else {
			props.timezoneOffset = this.props.timezoneOffset;
		}

		if (typeof this.props.onLoad === 'function') {
			props.settings = Object.assign(
				{
					chart: {
						events: {
							load: this.props.onLoad
						}
					}
				},
				props.settings
			);
		}
		return props;
	};

	// Initialize Highcharts
	async _initHighcharts() {
		// Set language specific number formating
		const sample = this.props.intl.formatNumber(1234.45);
		this.thousandsSeparator = sample.charAt(1);
		this.decimalSeparator = sample.charAt(5);
		Highcharts.setOptions({
			lang: {
				thousandsSep: this.thousandsSeparator,
				decimalPoint: this.decimalSeparator,
				numericSymbols: false
			}
		});
	}

	// Initialize Component & output mode
	_initComponent = () => {
		this._settings = new ChartSettings(this._getProps());
		const data = this._settings.generate();

		if (this.props.output !== 'json') {
			if (this.props.output === 'image') {
				// POST parameter for Highcharts export server
				const object = {
					options: JSON.stringify(data),
					type: this.props.export.type, // Possible values are image/png, image/jpeg, application/pdf and image/svg+xml.
					async: true
				};

				// Ajax request
				const image = this._image.current;
				const url = this.props.export.url;
				$.ajax({
					type: 'post',
					url,
					data: object,
					success(data) {
						// Update "src" attribute with received image URL
						$(image).attr('src', url + data);
					}
				});
			} else {
				// Create Highchart
				this._chart = Highcharts.chart(this._wrapper.current, data);

				// Reflow chart when printing: https://github.com/highcharts/highcharts/issues/2284
				if (window && window.matchMedia) {
					const $chart = this._chart;
					window.matchMedia('print').addListener(function reflow() {
						if (Object.keys($chart).length > 0) {
							$chart.reflow();
						}
					});
				}
			}
		} else {
			document.write(JSON.stringify(data));
		}
	};

	_handleAnimation = () => {
		const _component = this,
			$wrapper = $(this._wrapper.current); // Convenience
		$wrapper.visibility({
			offset: 50,
			onTopVisible() {
				if (!_component._chart) {
					_component._initComponent();
				}
			},
			onBottomVisible() {
				if (!_component._chart) {
					_component._initComponent();
				}
			}
		});
	};

	_destroyChart = () => {
		if (this._chart) {
			this._chart.destroy();
			this._chart = null;
		}
	};

	// Export chart as image. exportOption: https://api.highcharts.com/highcharts/exporting
	exportChartImage = (exportingOptions = {}) => {
		if (this._chart) {
			this._chart.exportChart(merge({}, this.props.export, exportingOptions));
		}
	};

	getChartSvg = () => {
		let svg;
		if (this._chart) {
			svg = this._chart.getChartHTML();
			svg = this._chart.sanitizeSVG(svg);
		}
		return svg;
	};

	redraw = () => {
		if (this._chart) {
			this._chart.redraw();
		}
	};

	reflow = () => {
		if (this._chart) {
			this._chart.reflow();
		}
	};

	get settings() {
		return this._settings.generate();
	}

	get ref() {
		if (this._image.current && this.props.output === 'image') {
			return this._image.current;
		}
		return this._wrapper.current ? this._wrapper.current : null;
	}

	render() {
		if (this.props.output !== 'json') {
			if (this.props.output === 'image') {
				return <img ref={this._image} />;
			} else {
				return (
					<div
						className={
							ChartSettings.isBoxed(this.props) ? 'ui segment very compressed remove-margins' : undefined
						}
					>
						{this.props.tools ? (
							<Segment
								classes={
									ChartSettings.isBoxed(this.props)
										? 'basic right aligned chart-tools remove-bottom-padding remove-bottom-margin'
										: 'basic right aligned chart-tools remove-paddings'
								}
							>
								{this.props.tools}
							</Segment>
						) : null}
						<div
							ref={this._wrapper}
							className='highcharts-wrapper'
							style={{ height: this.props.height + 'px' }}
						/>
					</div>
				);
			}
		}
		return null;
	}
}

let Wrapper = injectIntl(Chart, { forwardRef: true });
Wrapper.displayName = 'Chart';
export default Wrapper;
