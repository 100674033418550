/**
 * @prettier
 * @flow
 */

import { useState } from 'react';
import TooltipSlider from './src/SliderTooltip';
import classNames from 'classnames';
import { Input, Popup } from 'liana-ui/components/';
import type { IntlComponent } from 'react-intl';
import type { Props as PopupProps } from 'liana-ui/components/popup/Popup';

// prettier-ignore
type Props = {
	/** A number slider must have an input name */
	name: string,
	/** Initial value for number slider. Use for uncontrolled components only. */
	defaultValue?: number,
	/** Current value for number slider. Use for controlled components only. */
	value?: number,
	/** A number slider can have minimum value. */
	min?: number,
	/** A number slider can have maximum value. */
	max?: number,
	/** A number slider can have steps between slider value points. */
	step?: number,
	/** A number slider can have different unit. VALUES['%', '€', '$', '...'] */
	unit?: string,
	/** A number slider can have dots to reprecent steps when step if greater than 1. */
	dots?: string,
	/** A number slider can be disabled. */
	disabled?: boolean,
	/** A number slider can be locked. */
	locked?: boolean,
	/** An input can be different size. */
	size?: 'small' | 'large',
	/**
		Popup text or, react-intl coomponent or object of properties for Popup component.
		PROPS[IntlComponent=/language/localisation/, PopupProps=/components/modals/popup/]
 	*/
	popup?: string | IntlComponent | PopupProps,
	/** Function called when slider drag is started. */
	onChange?: (
		data: {
			name: string,
			value: string
		}
	) => void
};

const DEFAULTS = {
	defaultValue: 50,
	min: 0,
	max: 100,
	step: 1,
	disabled: false,
	locked: false
};

/** COMPONENT BASED ON: https://slider-react-component.vercel.app */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	// Internal states
	let [internalValue, setInternalValue] = useState(props.defaultValue);
	let value = props.value === undefined ? internalValue : props.value;

	const handleChange = (value: number) => {
		if (typeof props.onChange === 'function') {
			props.onChange({
				name: props.name,
				value: value
			});
		}
		setInternalValue(value);
	};

	let classes = classNames(
		'number-slider-wrapper',
		{
			locked: props.locked
		},
		props.size
	);

	let containerClasses = classNames('number-slider-container', {
		disabled: props.disabled
	});

	let slider = (
		<div className={classes}>
			<Input
				name={props.name}
				value={value}
				type='number'
				min={props.min}
				max={props.max}
				locked={props.locked}
				disabled={props.disabled}
				onChange={(event, data) => handleChange(data.value)}
			/>

			<div className={containerClasses}>
				<div className='number-slider-amount-min'>
					{props.min}
					{props.unit}
				</div>
				<TooltipSlider
					value={value}
					step={props.step}
					min={props.min}
					max={props.max}
					unit={props.unit}
					dots={props.dots}
					disabled={props.disabled || props.locked}
					onChange={handleChange}
				/>
				<div className='number-slider-amount-max'>
					{props.max}
					{props.unit}
				</div>
			</div>
		</div>
	);

	// Attach popup
	return Popup.attach(props.popup, slider);
});

// Documentation generation support
Component.displayName = 'NumberSlider';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
