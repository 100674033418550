/* istanbul ignore file */
/**
 * @prettier
 */

// Note: This is just an example Trail context. Contexts can be implemented in many ways.
import { useState } from 'react';

const TrailContext = React.createContext(); // DEFAULT

type Props = {
	children: React.Node
};

const TrailContextProvider = (props: Props) => {
	const DEFAULTS = { sections: null, tools: null };
	let [trail, updateTrail] = useState(DEFAULTS);

	const setTitle = (title) => (document.title = title);
	const clear = () => updateTrail(DEFAULTS);

	const setTrail = (nextSections, nextTools) =>
		updateTrail({ sections: nextSections || null, tools: nextTools || null });

	const setSections = (nextSections) => updateTrail((prev) => ({ ...prev, sections: nextSections }));

	const setTools = (nextTools) => updateTrail((prev) => ({ ...prev, tools: nextTools }));

	return (
		<TrailContext.Provider
			value={{ sections: trail.sections, tools: trail.tools, clear, setTools, setSections, setTrail, setTitle }}
		>
			{props.children}
		</TrailContext.Provider>
	);
};

const TrailContextConsumer = TrailContext.Consumer;

export { TrailContextProvider, TrailContextConsumer };

export default TrailContext;
