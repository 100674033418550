/**
 * @prettier
 */

// React Router 6 utilities
// https://github.com/remix-run/react-router/issues/9262
// https://gist.github.com/MarksCode/64e438c82b0b2a1161e01c88ca0d0355
import { useEffect, useContext, useCallback, forwardRef } from 'react';
import { useNavigate, useParams, useLocation, UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

// Custom confirmation windows
const useConfirm = (confirmExit, when = true) => {
	const { navigator } = useContext(NavigationContext);
	useEffect(() => {
		if (!when) {
			return;
		}
		const push = navigator.push;
		navigator.push = (...args) => {
			const result = confirmExit(args[0]); // args[0] = location
			if (result !== false) {
				push(...args);
			}
		};
		return () => (navigator.push = push);
	}, [navigator, confirmExit, when]);
};

// Browser builtin confirmation windows
const usePrompt = (message, when = true) => {
	useEffect(() => {
		if (when) {
			window.onbeforeunload = () => message;
		}
		return () => (window.onbeforeunload = null);
	}, [message, when]);

	const confirmExit = useCallback(() => window.confirm(message), [message]);
	useConfirm(confirmExit, when);
};

const withRouter = (Component) =>
	forwardRef((props, ref) => (props) => (
		<Component ref={ref} navigate={useNavigate()} params={useParams()} location={useLocation()} {...props} />
	));

// block={useBlocker}
const withPrompt = (Component) => forwardRef((props, ref) => <Component ref={ref} {...props} />);

export { withRouter, withPrompt, useConfirm, usePrompt };
