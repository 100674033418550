/**
 * @prettier
 * @flow
 */

import { StepContent } from 'semantic-ui-react';

// prettier-ignore
type Props = {
	/** Detail as children. */
	children: React.Node
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/step/#groups-ordered */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	return <StepContent>{props.children}</StepContent>;
});

Component.displayName = 'StepContent';

export default Component;
