/**
 * @prettier
 * @flow
 */

import { FormattedMessage } from 'react-intl';
import type { IntlComponent } from 'react-intl';
import { Loader } from 'liana-ui/components/';

// prettier-ignore
type Props = {
	/** Content as children. */
	children?: React.Node,
	/** Page can be loading. */
	loading?: boolean,
	/** Page loading text. */
	loadingText?: string | IntlComponent
};

const DEFAULTS = {
	loading: false,
	loadingText: <FormattedMessage id='component.loader.loading' />
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/icon */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	return (
		<main className='main-column' id='main-column'>
			{props.loading ? <Loader active centerInEmptySpace text={props.loadingText} /> : props.children}
		</main>
	);
});

// Documentation generation support
Component.displayName = 'Page';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
