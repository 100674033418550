/**
 * @prettier
 * @flow
 */

import { Statistic } from 'semantic-ui-react';

// prettier-ignore
type Props = {
	/** Statistic label as text. */
	content: FormattedMessage | string
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/views/statistic */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => (
	<Statistic.Label>{props.content}</Statistic.Label>
));
Component.displayName = 'StatisticLabel';

export type { Props };
export default Component;
