/**
 * @prettier
 * @flow
 */

const CENTER_OFFSET = 80;

export default {
	setIcon(icon: HTMLElement, top: number) {
		if (icon) {
			// Icon check needed for modal
			icon.style.top = typeof top === 'number' ? `${top}px` : '';
		}
	},
	getPadding(container: HTMLElement, useDimmer: boolean = false) {
		let paddingTop = window.getComputedStyle(container, null).getPropertyValue('padding-top');
		let paddingBottom = window.getComputedStyle(container, null).getPropertyValue('padding-bottom');
		return useDimmer ? 0 : parseFloat(paddingTop) + parseFloat(paddingBottom);
	},
	isTopVisible: (el: HTMLElement) => el.getBoundingClientRect().top >= 0,
	isBottomVisible: (el: HTMLElement) => el.getBoundingClientRect().bottom <= window.innerHeight,
	isFullyVisible(el: HTMLElement) {
		let top = el.getBoundingClientRect().top,
			bottom = el.getBoundingClientRect().bottom;
		return top >= 0 && bottom <= window.innerHeight;
	},
	centerWhenBottomAndTopNotVisible(container: HTMLElement, icon: HTMLElement, containerPadding: number = 0) {
		let height = window.innerHeight - container.getBoundingClientRect().top * 2 - containerPadding;
		if (height > 0) {
			this.setIcon(icon, height / 2);
		}
	},
	centerWhenBottomNotVisible(container: HTMLElement, icon: HTMLElement, containerPadding: number = 0) {
		let height = window.innerHeight - container.getBoundingClientRect().top - containerPadding;
		let iconPadding = icon && icon.offsetHeight > CENTER_OFFSET ? icon.offsetHeight : CENTER_OFFSET;

		if (height > 0 && height > iconPadding) {
			this.setIcon(icon, height / 2);
		}
	},
	centerWhenTopNotVisible(container: HTMLElement, icon: HTMLElement, containerPadding: number = 0) {
		let height = container.clientHeight - container.getBoundingClientRect().top - containerPadding;
		let iconPadding = icon && icon.offsetHeight > CENTER_OFFSET ? icon.offsetHeight : CENTER_OFFSET;

		if (height > 0 && container.getBoundingClientRect().bottom > iconPadding) {
			this.setIcon(icon, height / 2);
		}
	},
	center(container: HTMLElement, icon: HTMLElement, useDimmer: boolean = false) {
		if (this.isFullyVisible(container)) {
			this.setIcon(icon, false);
			return; // Early exit
		}
		let padding = this.getPadding(container, useDimmer);

		if (this.isBottomVisible(container) === false && this.isTopVisible(container) === false) {
			this.centerWhenBottomAndTopNotVisible(container, icon, padding);
		} else if (this.isBottomVisible(container) === false) {
			this.centerWhenBottomNotVisible(container, icon, padding);
		} else if (this.isTopVisible(container) === false) {
			this.centerWhenTopNotVisible(container, icon, padding);
		}
	}
};
